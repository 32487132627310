import React from 'react'
import { css } from 'emotion'
import {Col, Tab, Tabs, Alert, Button} from 'react-bootstrap'
import logo from 'app/universal/assets/images/logo-moulin-traiteur-companion.svg'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PhoneLoginForm from './PhoneLoginForm';
import styles from './styles';
import TutoPwaModal from 'app/companion/components/TutoPwaModal';
import {Helmet} from "react-helmet";

const Login = (props) => {

  const {
    handleSubmit,
    isLoading
  } = props

  const deployVer = process.env.REACT_APP_VERSION;
  const curVersion = localStorage.getItem('version');

  return (
    <>
      <Helmet>
        <title>Connexion</title>
      </Helmet>
      {curVersion === 'undefined' || curVersion === null || curVersion !== deployVer && <Alert variant="warning" className="mb-0 text-center">Nouvelle version disponible<br/><Button onClick={ () => window.location.reload()}>Mettre à jour</Button></Alert>}
      <div className={css(styles.login)}>
        <Col xs={12} className={css(styles.block)}>
          <div className={css(styles.logoImg)}>
            <img className={css(styles.img)} src={logo} alt="Le Moulin - Commande en ligne" />
          </div>
          <Tabs
            defaultActiveKey="deliveryMan"
            id="connection-tabs"
            className="mb-3"
            fill
          >
            <Tab eventKey="admin" title="Admin">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className={css(styles.label)}>Identifiant*</label>
                  <input type="text" name="username" className={css(styles.input)} required/>
                </div>
                <div className="form-group">
                  <label className={css(styles.label)}>Mot de passe*</label>
                  <input type="password" name="password" className={css(styles.input)} required/>
                </div>
                
                <button type="submit" className={css(styles.button)} disabled={isLoading}>{isLoading ? <FontAwesomeIcon icon={faSpinner} pulse/> : "Se connecter"}</button>
              </form>
            </Tab>
            <Tab eventKey="deliveryMan" title="Livreur">
              <TutoPwaModal />
              <PhoneLoginForm handleSubmit={handleSubmit} isLoading={isLoading}/>
            </Tab>
          </Tabs>
        </Col>
      </div>
    </>

  )

}

export default Login
