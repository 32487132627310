import axios from "axios";
import moment from "moment";

const baseUrl           = process.env.REACT_APP_API_URL;
const appEnv            = process.env.REACT_APP_ENV;
const basciClientID     = process.env.REACT_APP_BASIC_ID;
const basicClientSecret = process.env.REACT_APP_BASIC_SECRET;
const tokenUrl          = baseUrl + "/api/login/bo";

const leMoulinApi = {
  request: {
    tender: {
      gets: filters => {
        return axios.get(
          process.env.REACT_APP_API_URL +
            `/api/pro/tenders.json`
        );
      },
    },
    logger: {
      post: data => {
        return axios.post(
          process.env.REACT_APP_API_URL + "/api/loggers.json",
          data
        );
      }
    },
    roadmap: {
      get: id => {
        return axios.get(
          process.env.REACT_APP_API_URL + "/api/pro/roadmaps/" + id + ".json"
        );
      },
      gets: filters => {
        const thisDate = moment(filters.date).format("YYYY-MM-DD");
        return axios.get(
          process.env.REACT_APP_API_URL +
            `/api/pro/roadmaps.json?filters[date]=` +
            thisDate
        );
      },
      post: data => {
        return axios.post(
          process.env.REACT_APP_API_URL + "/api/pro/roadmaps.json",
          data
        );
      },
      put: (id, data) => {
        return axios.put(
          process.env.REACT_APP_API_URL + "/api/pro/roadmaps/" + id + ".json",
          data
        );
      },
      delete: id => {
        return axios.delete(
          process.env.REACT_APP_API_URL + "/api/pro/roadmaps/" + id + ".json"
        );
      },
      deliveries: {
        add: (roadmapId, data) => {
          return axios.post(
            process.env.REACT_APP_API_URL +
              "/api/pro/roadmaps/" +
              roadmapId +
              "/deliveries.json",
            data
          );
        },
        remove: (roadmapId, id) => {
          return axios.delete(
            process.env.REACT_APP_API_URL +
              "/api/pro/roadmaps/" +
              roadmapId +
              "/deliveries/" +
              id +
              ".json"
          );
        }
      }
    },
    delivery: {
      get: id => {},
      gets: filters => {
        const thisDate = moment(filters.date).format("YYYY-MM-DD");
        return axios.get(
          process.env.REACT_APP_API_URL +
            `/api/pro/deliveries.json?filters[deliveryDate]=` +
            thisDate +
            "&order_by[arrivedAtStart]=ASC&no_attributed=1"
        );
      },
      post: data => {},
      put: (id, data) => {
        return axios.put(
          process.env.REACT_APP_API_URL + "/api/pro/deliveries/" + id + ".json",
          data
        );
      },
      delete: id => {}
    },
    recovery: {
      get: id => {},
      gets: filters => {
        const thisDate = moment(filters.date).format("YYYY-MM-DD");
        return axios.get(
          process.env.REACT_APP_API_URL +
            `/api/pro/deliveries/recoveries.json?filters[deliveryDate]=` +
            thisDate +
            "&no_attributed=1"
        );
      },
      post: data => {},
      put: (id, data) => {},
      delete: id => {}
    },
    order: {
      // gets: filters => {
      //   return axios.get(
      //     process.env.REACT_APP_API_URL + "/api/pro/orders.json",
      //     { params: { filters: filters } }
      //   );
      // },
      gets: (data) => {
        return axios.get(process.env.REACT_APP_API_URL+'/api/pro/orders.json', {params:data})
      },
      missingFieldsCustomerNotification: (orderId, orderType) => {
        return axios.post(process.env.REACT_APP_API_URL+'/api/pro/orders/missings/fields/customers/notifications.json', {orderId: orderId, orderType: orderType})
      },
      mealtray: {
        put: (id, data) => {
          return axios.put(process.env.REACT_APP_API_URL+'/api/pro/orders/'+id+'/mealtray.json', data)
        },
      },
      caterer: {
        put: (id, data) => {
          return axios.put(process.env.REACT_APP_API_URL+'/api/pro/orders/'+id+'/caterer.json', data)
        },
      },
      breakfast: {
        put: (id, data) => {
          return axios.put(process.env.REACT_APP_API_URL+'/api/pro/orders/'+id+'/breakfast.json', data)
        },
      },
      v2: {
        put: (id, data) => {
          return axios.put(process.env.REACT_APP_API_URL+'/api/pro/orders/'+id+'/v2.json', data)
        },
      },
      compositionSheets: {
        gets: (filters) => {
          return axios.get(process.env.REACT_APP_API_URL+'/api/pro/orders/compositionsheets.json', {params:{filters:filters}})
        }
      },
      compositionSheetsV2: {
        gets: (filters) => {
          return axios.get(process.env.REACT_APP_API_URL+'/api/pro/orders/v2/compositionsheets.json', {params:{filters:filters}})
        }
      },
      anticipation: {
        gets: (filters) => {
          return axios.get(process.env.REACT_APP_API_URL+'/api/pro/orders/anticipation.json', {params:{filters:filters}})
        }
      },
    },
    mealtray: {
      put: (id, data) => {
        return axios.put(process.env.REACT_APP_API_URL+'/api/pro/orders/'+id+'/mealtray.json', data)
      },
    },
    caterer: {
      put: (id, data) => {
        return axios.put(process.env.REACT_APP_API_URL+'/api/pro/orders/'+id+'/caterer.json', data)
      },
    },
    breakfast: {
      put: (id, data) => {
        return axios.put(process.env.REACT_APP_API_URL+'/api/pro/orders/'+id+'/breakfast.json', data)
      },
    },
    v2: {
      put: (id, data) => {
        return axios.put(process.env.REACT_APP_API_URL+'/api/pro/orders/'+id+'/v2.json', data)
      },
    },
    grouped: {
      status: (id, data) => {
        return axios.put(process.env.REACT_APP_API_URL+'/api/pro/orders/grouped/'+id+'/status.json', data)
      },
      put: (id, data) => {
        return axios.put(process.env.REACT_APP_API_URL+'/api/pro/orders/'+id+'/grouped.json', data)
      },
    },
    deliveryMan: {
      get: id => {},
      gets: filters => {
        return axios.get(
          process.env.REACT_APP_API_URL +
            "/api/pro/deliverymen.json?order_by[firstname]=ASC"
        );
      },
      post: data => {},
      put: (id, data) => {},
      delete: id => {},
      // Envoie des roadmaps sur workchat
      sendRoadmapsOnWorkchat: (deliveryDate, deliveryManId) => {
        return axios.post(
          process.env.REACT_APP_API_URL +
            "/api/pro/deliverymen/sendroadmapsonworkchats.json",
            {deliveryDate: deliveryDate, deliveryManId: deliveryManId}
        );
      },
      // roadmap recup par token
      roadmaps: {
        getByToken: (token) => {
          return axios.post(process.env.REACT_APP_API_URL+'/api/pro/deliverymen/noauth/roadmapstokens.json',
            {public_token: token}
          )
        }
      },
      sendLoginSms: (phone) => {
        return axios.post(
          process.env.REACT_APP_API_URL + "/api/pro/deliverymen/public/send-sms.json",
          { phone: phone }
        );
      },
    },
    product: {
      get: (id) => {
        return axios.get(process.env.REACT_APP_API_URL+'/api/products/'+id+'.json')
      },
      gets: (filters) => {
        return axios.get(process.env.REACT_APP_API_URL+'/api/products.json?limit=100', {params:{filters:filters}})
      }
    },
    // Pour l'exemple
    sample: {
      get: id => {},
      gets: filters => {},
      post: data => {},
      put: (id, data) => {},
      delete: id => {}
    }
  },
  getToken: async (username, password) => {

    // const token = Buffer.from(`${usernameBasic}:${passwordBasic}`, 'utf8').toString('base64');
    let axiosInstance = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    });
    // Créez une instance Axios avec l'en-tête Authorization
    if (appEnv === 'staging') {
      const basicAuthToken = btoa(`${basciClientID}:${basicClientSecret}`);
      axiosInstance = axios.create({
        headers: {
          'Authorization': `Basic ${basicAuthToken}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      });
    }
    const data = {
      username: username,
      password: password
    }
    try {
      // Envoyez la requête pour obtenir les credentials OAuth
      const response = await axiosInstance.post(tokenUrl, data);
      return {...response.data};
    } catch (error) {
      console.error('Error fetching token:', error.response ? error.response.data : error.message);
      throw error;
    }
  }
};

export default leMoulinApi;
