import React from 'react'
import { useDispatch } from "react-redux";
import { css } from 'emotion'
import { Link, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faArrowLeft, faCircleNotch, faSync } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, Dropdown, Button, Alert } from "react-bootstrap";
import {
  revokeToken,
} from "state/common/actions";
import useConnectedUser from "app/universal/hook/useConnectedUser";

const deployVer = process.env.REACT_APP_VERSION;
const curVersion = localStorage.getItem('version');

const Layout = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const {backButton, isCompanionLoading} = props;
    const { connectedUser } = useConnectedUser();
    const logout = () => {
        dispatch(revokeToken()).then(() => {
            window.localStorage.removeItem('token');
            window.localStorage.removeItem('refresh_token');
            window.location.href = '/';
        });
    };
    const boUrl = `${process.env.REACT_APP_API_URL}/e28XmZh3/`;
    return (
      !props.public ?
        <div id="outer-container">
        {curVersion === 'undefined' || curVersion === null || curVersion !== deployVer && <Alert variant="warning" className="mb-0 text-center">Nouvelle version disponible<br/><Button onClick={ () => window.location.reload()}>Mettre à jour</Button></Alert>}

          <header className={css(style.header)}>
            <Row className="align-items-center py-2 px-4" noGutters>
                <Col className="text-left">
                    {backButton &&
                        <Link to={{
                            pathname: `/companion`, search: location.search
                        }} className={css(style.link)}><FontAwesomeIcon icon={faArrowLeft} /></Link>
                    }
                </Col>
                <Col className="text-center">
                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.62693 27.3099C5.69725 27.2256 5.79678 27.171 5.90563 27.157C6.01448 27.1429 6.12462 27.1705 6.21402 27.2341L7.47658 28.4967C7.53746 28.587 7.56335 28.6965 7.54941 28.8045C7.53547 28.9126 7.48264 29.0119 7.40082 29.0838L1.94657 34.538C1.87464 34.6199 1.77537 34.6727 1.66731 34.6866C1.55926 34.7006 1.44983 34.6747 1.35948 34.6138L0.0969274 33.3512C0.0351051 33.2623 0.00861343 33.1536 0.022619 33.0462C0.0366245 32.9388 0.0901229 32.8405 0.172681 32.7705L5.62693 27.3099Z" fill="#010101"/>
                        <path d="M7.38818 5.72017C7.4712 5.79132 7.52489 5.89072 7.53888 5.99916C7.55288 6.1076 7.52617 6.21737 7.46393 6.30726L6.20137 7.56982C6.11222 7.6328 6.00291 7.66055 5.89451 7.64773C5.7861 7.63491 5.68628 7.58242 5.61428 7.50038L0.153722 2.00194C0.0711638 1.93187 0.0176654 1.83356 0.00365991 1.72619C-0.0103456 1.61881 0.0161461 1.51007 0.0779684 1.42117L1.34053 0.158609C1.42968 0.0956287 1.53899 0.0678752 1.64739 0.0806968C1.75579 0.0935185 1.85561 0.146008 1.92761 0.22805L7.38818 5.72017Z" fill="#010101"/>
                        <path d="M28.9148 7.54455C28.8428 7.62637 28.7436 7.67919 28.6355 7.69313C28.5275 7.70707 28.418 7.68118 28.3277 7.6203L27.0651 6.35774C27.0033 6.26884 26.9768 6.1601 26.9908 6.05272C27.0048 5.94534 27.0583 5.84704 27.1409 5.77697L32.7593 0.152271C32.8312 0.0704489 32.9305 0.0176284 33.0385 0.00368631C33.1466 -0.0102558 33.256 0.0156352 33.3464 0.076518L34.6089 1.33908C34.6726 1.42848 34.7001 1.53862 34.6861 1.64747C34.672 1.75631 34.6174 1.85585 34.5332 1.92616L28.9148 7.54455Z" fill="#010101"/>
                        <path d="M27.1535 28.9828C27.071 28.9127 27.0175 28.8144 27.0035 28.707C26.9895 28.5996 27.0159 28.4909 27.0778 28.402L28.3403 27.1394C28.4295 27.0765 28.5388 27.0487 28.6472 27.0615C28.7556 27.0744 28.8554 27.1268 28.9274 27.2089L34.5332 32.8651C34.6174 32.9355 34.6721 33.035 34.6861 33.1438C34.7001 33.2527 34.6726 33.3628 34.6089 33.4522L33.3464 34.7148C33.2565 34.777 33.1467 34.8037 33.0383 34.7897C32.9298 34.7758 32.8304 34.7221 32.7593 34.639L27.1535 28.9828Z" fill="#010101"/>
                        <path d="M24.7736 23.6169L22.8798 12.7273V12.6958L22.6273 11.2628L22.5452 10.8019C22.5357 10.7199 22.495 10.6447 22.4315 10.5918C22.3681 10.5389 22.2867 10.5125 22.2043 10.5179H21.8192C21.7388 10.5062 21.6568 10.5139 21.58 10.5402C21.5031 10.5666 21.4337 10.6108 21.3773 10.6694L17.3561 14.6843L13.3349 10.6694C13.2798 10.6109 13.2115 10.5666 13.1356 10.5403C13.0598 10.5139 12.9787 10.5062 12.8993 10.5179H12.5205C12.4381 10.5125 12.3567 10.5389 12.2933 10.5918C12.2299 10.6447 12.1892 10.7199 12.1796 10.8019L12.1039 11.2502L11.845 12.7021L9.86913 23.6043C9.85784 23.6589 9.85963 23.7154 9.87437 23.7692C9.8891 23.8231 9.91636 23.8726 9.95392 23.9139C9.99148 23.9551 10.0383 23.9869 10.0905 24.0066C10.1426 24.0263 10.1988 24.0334 10.2542 24.0272H11.965C12.0451 24.0237 12.1218 23.994 12.1834 23.9427C12.2449 23.8914 12.288 23.8213 12.3059 23.7432L13.6947 14.6212L15.7905 16.7107L15.9862 16.9064L17.0405 17.9606C17.0724 17.9882 17.1095 18.0092 17.1496 18.0222C17.1897 18.0352 17.232 18.04 17.274 18.0364H17.3245C17.4093 18.0423 17.493 18.0152 17.5581 17.9606L18.6376 16.8812L18.808 16.7107L20.866 14.6591L21.4026 18.0932L22.2738 23.7747C22.2883 23.8355 22.3179 23.8916 22.3598 23.938C22.4017 23.9843 22.4546 24.0193 22.5136 24.0399C22.5573 24.0492 22.6025 24.0492 22.6462 24.0399H24.3759C24.4308 24.0451 24.4861 24.0364 24.5367 24.0147C24.5873 23.9929 24.6316 23.9588 24.6656 23.9154C24.6996 23.872 24.7221 23.8208 24.731 23.7664C24.74 23.712 24.7351 23.6563 24.7168 23.6043" fill="#010101"/>
                    </svg>
                </Col>
                <Col className="text-right">
                    <Button variant="outline-dark" href="#/" onClick={ () => window.location.reload()}><FontAwesomeIcon icon={faSync} /></Button>
                    <Dropdown style={{display: "inline-block", marginLeft: "10px"}}>
                        <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
                            <FontAwesomeIcon icon={faCog} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {(connectedUser.roles.includes("ROLE_DEV") || 
                            connectedUser.roles.includes("ROLE_ADMIN_PRO")) && (
                                <Dropdown.Item href={boUrl}>Retour au BO</Dropdown.Item>
                            )}
                            <Dropdown.Divider />
                            <Dropdown.Item href="#/" onClick={ () => logout()}>Se déconnecter</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
          </header>
          <main id="page-wrap" className="pb-5">

            {isCompanionLoading && 
                <div className={css(style.loader)}>
                    <FontAwesomeIcon icon={faCircleNotch} size="4x" spin />
                </div> 
            }
            {props.children}
          </main>
        </div>
      :
        <>{props.children}</>
    )

}

const style = {
    header: {
        background: '#689D71',
    },
    link: {
        color: "black"
    },
    loader:{
        backgroundColor: "rgba(255,255,255,0.9)",
        zIndex: 9999,
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        transition: "300ms",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}

export default Layout
