import React, { useState, useEffect } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import LeMoulinApi from "app/universal/utils/LeMoulinApi";
import FakeDelivery from "app/deliveryTool/components/delivery/FakeDelivery";
import AnticipationBoisson from "app/orderTool/pages/anticipation/AnticipationBoisson";
import AnticipationSucree from "app/orderTool/pages/anticipation/AnticipationSucree";
import AnticipationGalette from "app/orderTool/pages/anticipation/AnticipationGalette";
import { css, injectGlobal } from "emotion";
import moment from "moment";
import Helmet from "react-helmet";

/**
 * Gère les tableaux des anticipations
 * Concerne les commandes de boissons et de pièces sucrées
 * Dans boissons il y a des produits PR et Cocktail pour le tag Café et Thé sinon que du PDJ
 */
const Anticipation = (props) => {

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Parametre pour la requete API (date & status)
  const queryString = require("query-string");

  const prm = queryString.parse(window.location.search);

  const [data, setData] = useState({ ordersInfo: {}, tags: [], errors: [], ordersInfoBoisson: {}, ordersInfoSucree: {}, ordersInfoGalette: {} });
  const [loading, setLoading] = useState(false);

  const [showBoisson, setShowBoisson] = useState(true);
  const [showSucree, setShowSucree] = useState(true);
  const [showGalette, setShowGalette] = useState(true);

  const date = new Date(prm.startDate);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await LeMoulinApi.request.order.anticipation.gets(
        {
          dates: {
            start: date,
            end: date
          },
          activity: 'all',
          tag_functions: ['anticipation_piece_sucree', 'anticipation_boisson', 'anticipation_galette']
        }
      );
  
      // Convertir ordersInfo en tableau
      const ordersInfoArray = Object.entries(result.ordersInfo);
  
      // Filtrer les commandes pour chaque date
      const filteredOrdersInfoBoisson = {};
      ordersInfoArray.forEach(([date, info]) => {
        filteredOrdersInfoBoisson[date] = {
          ...info,
          orders: info.orders.filter(order => {
            // Si le type de la commande est breakfast, on la garde
            if (order.orderType === 'breakfast') {
              return true;
            }
  
            // Sinon, on vérifie si la commande a un produit avec un tagName Café ou Thé
            return order.products && order.products.some(product => ['Café', 'Thé'].includes(product.tagName));
          })
        };
      });

      const filteredOrdersInfoSucree = {};
      ordersInfoArray.forEach(([date, info]) => {
        filteredOrdersInfoSucree[date] = {
          ...info,
          orders: info.orders.filter(order => {
            // Sinon, on vérifie si la commande a un produit avec le tagName "anticipation_piece_sucree"
            return order.orderType === 'breakfast' && order.products && order.products.some(product => product.tagUtility === "anticipation_piece_sucree");
          })
        };
      });

      const filteredOrdersInfoGalette = {};
      ordersInfoArray.forEach(([date, info]) => {
        filteredOrdersInfoGalette[date] = {
          ...info,
          orders: info.orders.filter(order => {
            // Sinon, on vérifie si la commande a un produit avec le tagName "anticipation_galette"
            return order.products && order.products.some(product => product.tagUtility === "anticipation_galette");
          })
        };
      });
      
      setData({
        ...result,
        ordersInfoBoisson: filteredOrdersInfoBoisson,
        ordersInfoSucree: filteredOrdersInfoSucree,
        ordersInfoGalette: filteredOrdersInfoGalette
      });
      setLoading(false);
    };
    fetchData();
  }, []);

  const handlePrint = () => {
    window.print();
  };

  return (
    <div>
      {loading ? (
        <Container>
          <FakeDelivery />
        </Container>
      ) : (
        
      <Col xs={12} sm={12} md={12}>
        
        <Row className="align-items-center mt-2 no-print">
          <Col xs={12} sm={4} md={6} className="text-left d-flex">
            <div>
              <input type="checkbox" checked={showBoisson} onChange={() => setShowBoisson(!showBoisson)} /> Anticipation Boisson 
            </div>
            <div>
              <input type="checkbox" checked={showSucree} onChange={() => setShowSucree(!showSucree)} /> Anticipation Sucrée
            </div>
            <div>
              <input type="checkbox" checked={showGalette} onChange={() => setShowGalette(!showGalette)} /> Anticipation Galette
            </div>
          </Col>
          <Col xs={12} sm={4} md={6} className="text-right">
            <Button onClick={handlePrint}>Imprimer</Button>
          </Col>
        </Row>
        
        <Col xs={12} sm={12} md={12}>
          {data.errors.length > 0 && (
            <div className="alert alert-danger" role="alert">
              {data.errors.map((error, i) => (
                <p key={i}>{error}</p>
              ))}
            </div>
          )}
        </Col>
        {date &&  
          <Helmet bodyAttributes={{ style: "background-color : white" }}>
            <title>Anticipation {moment(date).format("DD/MM/YY")}</title>
          </Helmet>
        }
        <Col xs={12} sm={12} md={12}>
          {showBoisson && (
            <div style={{ pageBreakAfter: 'always' }}>
              <Col xs={12} sm={12} md={12}>
                <h1 className={css(styles.title)}>
                  Anticipation du {capitalizeFirstLetter(moment(date).format("dddd DD/MM/YY"))}
                </h1>
              </Col>
              <AnticipationBoisson onPrint={handlePrint} ordersInfo={data.ordersInfoBoisson} tags={data.tags.filter(tag => tag.utility === 'anticipation_boisson')} />
            </div>
          )}
          {showSucree && (
            <div style={{ pageBreakAfter: 'always' }}>
              <Col xs={12} sm={12} md={12}>
                <h1 className={css(styles.title)}>
                  Anticipation du {capitalizeFirstLetter(moment(date).format("dddd DD/MM/YY"))}
                </h1>
              </Col>
              <AnticipationSucree onPrint={handlePrint} ordersInfo={data.ordersInfoSucree} tags={data.tags.filter(tag => tag.utility === 'anticipation_piece_sucree')} />
            </div>
          )}
          {showGalette && (
            <div>
              <Col xs={12} sm={12} md={12}>
                <h1 className={css(styles.title)}>
                  Anticipation du {capitalizeFirstLetter(moment(date).format("dddd DD/MM/YY"))}
                </h1>
              </Col>
              <AnticipationGalette onPrint={handlePrint} ordersInfo={data.ordersInfoGalette} tags={data.tags.filter(tag => tag.utility === 'anticipation_galette')} />
            </div>
          )}
        </Col>
      </Col>
      )}
    </div>
  );
};

const styles = {
  title: {
    color: "#b64e4e",
    textAlign: "center",
    fontSize: "1.6rem",
  },
};

export default Anticipation;
