import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getConnectedUserInfo } from "state/common/actions";

const useConnectedUser = () => {
  const dispatch = useDispatch();
  
  // Sélection des données dans le store
  const connectedUser = useSelector((state) => state.common.user.data);
  const isLoading = useSelector((state) => state.common.user.isLoading);

  // Effet pour charger l'utilisateur si nécessaire
  useEffect(() => {
    if (!connectedUser?.id && !isLoading) {
      dispatch(getConnectedUserInfo());
    }
  }, [connectedUser, isLoading, dispatch]);

  // Retourner l'utilisateur et l'état de chargement
  return { connectedUser, isLoading };
};

export default useConnectedUser;
