import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { css } from 'emotion';
import styles from './styles';
import LeMoulinApi from 'app/universal/utils/LeMoulinApi';
import swal from 'sweetalert';

const PhoneLoginForm = ({ handleSubmit, isLoading }) => {
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isLoadingSMS, setIsLoadingSMS] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [resendLoading, setResendLoading] = useState(false);

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleSendCode = async () => {
    setIsLoadingSMS(true);
    try {
      await LeMoulinApi.request.deliveryMan.sendLoginSms(phoneNumber).then((res) => {
        if (res.success === true) {
          setIsCodeSent(true);
        }
        else {
            let message = res?.message || "Une erreur est survenue";
            swal({
                title: "Oups",
                text: res.message,
                icon: "error",
            })
        }
      });
    } catch (error) {
      console.error('Error sending SMS:', error);
    } finally {
      setIsLoadingSMS(false);
    }
  };

  const handleResendCode = async () => {
    setResendLoading(true);
    try {
      await LeMoulinApi.request.deliveryMan.sendLoginSms(phoneNumber);
    } catch (error) {
      console.error('Error resending SMS:', error);
    } finally {
      setResendLoading(false);
    }
  };

  return (
    <>
      <div className="form-group">
        <label className={css(styles.label)}>Téléphone*</label>
        <input
          type="tel"
          name="username"
          pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
          className={css(styles.input)}
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          required
        />
      </div>
      <form onSubmit={handleSubmit}>
        <input type="hidden" name="username" value={phoneNumber} />
        {isCodeSent && (
          <>
            <div className="form-group">
              <label className={css(styles.label)}>Code SMS reçu*</label>
              <input type="number" name="password" className={css(styles.input)} required />
            </div>
            <button type="submit" className={css(styles.button)} disabled={isLoading}>
              {isLoading ? <FontAwesomeIcon icon={faSpinner} pulse /> : "Se connecter"}
            </button>
            <div className="form-group">
              <div
                  type="button"
                  className={css(styles.resend)}
                  onClick={handleResendCode}
                >
                  {resendLoading ? <FontAwesomeIcon icon={faSpinner} pulse /> : "Renvoyer mon code"}
              </div>
            </div>
          </>
        )}
      </form>
      {!isCodeSent && (
        <button
          type="submit"
          className={css(styles.button)}
          onClick={handleSendCode}
          disabled={!phoneNumber || isLoading}
        >
          {isLoadingSMS ? <FontAwesomeIcon icon={faSpinner} pulse /> : "Recevoir un code de connexion"}
        </button>
      )}
    </>
  );
};

export default PhoneLoginForm;