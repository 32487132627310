import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from '@emotion/styled'
import { Row, Col, Accordion, Card, Button, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faMapPin, faComment, faCheck, faTimes, faClock, faCaretUp, faCaretDown} from '@fortawesome/free-solid-svg-icons'
import { Link, useLocation } from "react-router-dom";

const Delivery = (props) => {
  
  const { delivery, roadmap } = props;
  const location = useLocation();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  // Valeur possible : yes, no, pending
  const isDeliveryDone = delivery.done;

  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const translatePrestationType = (prestationType) => {
    const translationMap = {
      mealtray: "Plateaux-repas",
      caterer: "Traiteur",
      breakfast: "Petit-déjeuner"
    };
    return translationMap[prestationType] || prestationType;
  };

  const formatPhoneNumber = (phone) => {
    return phone.replace(/\D/g, '').slice(-10);
  };

  return (
    <Content>
      <Card key={"D"+delivery.id}>
        <Accordion.Toggle as={Card.Header} variant="link" eventKey={"D" + delivery.id} onClick={handleAccordionToggle} style={{opacity: isDeliveryDone ? "0.5" : "1",  borderLeft: delivery.recovery ? "#e3a7ff 5px solid" : "lightblue 5px solid"}}>
          <Row>
            <Col xs={8}>
              <FontAwesomeIcon icon={isAccordionOpen ? faCaretUp : faCaretDown} /> <b>{delivery?.delivery?.company}</b><small> - {delivery.delivery.lastname}</small>
            </Col>
            <Col className="text-right" xs={4}>
              {isDeliveryDone
                ?
                  <Badge variant="success">{delivery.recovery ? "RECUP "+delivery?.delivery?.info?.numbering : "LIV "+delivery?.delivery?.info?.numbering} <FontAwesomeIcon icon={faCheck} /></Badge>
                :
                  <Badge variant="danger">{delivery.recovery ? "RECUP "+delivery?.delivery?.info?.numbering : "LIV "+delivery?.delivery?.info?.numbering} <FontAwesomeIcon icon={faTimes} /></Badge>
              }
            </Col>
          </Row>
          
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={"D"+delivery.id}>
          <Card.Body>
            <div>
              <Row noGutters>
                <Col xs={1}>
                  <FontAwesomeIcon icon={faMapPin} />
                </Col>
                <Col xs={11}>
                  {delivery.delivery.street_number} {delivery.delivery.street}<br/>
                  {delivery.delivery.postal_code} {delivery.delivery.city}<br/>
                  {delivery.delivery.address_complement}
                </Col>
              </Row>
              <Row noGutters>
                <Col xs={1}>
                <FontAwesomeIcon icon={faUser} />
                </Col>
                <Col xs={11}>
                  {delivery.delivery.firstname} {delivery.delivery.lastname} / <a href={`tel:${formatPhoneNumber(delivery.delivery.phone)}`}>{formatPhoneNumber(delivery.delivery.phone)}</a>
                </Col>
              </Row>
              <hr/>
              <Row className="text-center align-items-center" noGutters>
                <Col xs={3} style={{borderRight: '1px solid #ddd'}}>
                  {delivery.recovery ?
                    <small>{translatePrestationType(delivery.delivery.prestation_type)}<br/>
                    {delivery.delivery.info.numbering} du {moment(delivery.delivery.delivery_date).format("D/M/Y")}<br/>{delivery.delivery.person} pers</small>
                    :
                    <small>{translatePrestationType(delivery.delivery.prestation_type)}<br/>
                    {delivery.delivery.person} pers</small>
                  }
                </Col>
                <Col xs={6} style={{borderRight: '1px solid #ddd'}}>

                  {delivery.recovery ?
                    <small>Récupération à partir de :<br/>
                    {delivery.delivery.end_at != null ?  moment(delivery.delivery.end_at).format("HH:mm") : "NC"}</small>
                  :
                    <small>Liv : {delivery.delivery.arrived_at_start ? moment(delivery.delivery.arrived_at_start).format("HH:mm") : "NC"} et {delivery.delivery.arrived_at_end ? moment(delivery.delivery.arrived_at_end).format("HH:mm") : "NC"}<br/>
                    Début : { delivery.delivery.start_at ? moment(delivery.delivery.start_at).format("HH:mm") : "NC"}</small>
                  }
                </Col>
                <Col xs={3}>
                  <small>{delivery.delivery.blanket ? "Avec nappe" : "Sans nappe"}</small>
                  <br/>
                  {delivery?.delivery?.service && 
                    <small>Avec service</small>
                  }
                </Col>
              </Row>
              <hr/>
              {delivery.recovery && delivery.delivery.recover_information ?
                <Row noGutters>
                  <Col xs={1}>
                    <small><FontAwesomeIcon icon={faComment} /></small>
                  </Col>
                  <Col xs={11}>
                    <small>{delivery.delivery.recover_information}</small>
                  </Col>
                </Row>
                :
                  delivery.recovery == false && delivery.delivery.delivery_information
                ?
                  <Row noGutters>
                    <Col xs={1}>
                      <small><FontAwesomeIcon icon={faComment} /></small>
                    </Col>
                    <Col xs={11}>
                      <small>{delivery.delivery.delivery_information}</small>
                    </Col>
                  </Row>
                :
                  null
              }
              {delivery.delivery.info.grouped_order_id ?
                <Link to={`/companion/tournee/${roadmap.id}/${delivery.id}/detail${location.search}`} style={{ textDecoration: 'none' }}>
                  {isDeliveryDone ? 
                    <Button variant="secondary" className="mt-3" block>
                      {delivery.recovery ? "Modifier récupération faite" : "Modifier livraison faite"}
                    </Button>
                  :  
                    <Button variant="dark" className="mt-3" size="lg" block>
                      {delivery.recovery ? "Voir cette récupération" : "Voir cette livraison"}
                    </Button>
                  }
                </Link>
              : 
                <p className="text-center" style={{ color: "red"}}>Ancien système, merci de migrer cette commande sur le nouveau système</p>
              }

            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Content>
  );
};

const Content = styled.div`
  margin: 10px 0;
`

export default Delivery;
