import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Button, Container } from "react-bootstrap";
import Layout from "app/universal/layout/Layout";
import FakeDelivery from "app/deliveryTool/components/delivery/FakeDelivery";
import { getTenders } from "state/tender/actions";
import {Helmet} from "react-helmet";

const TenderCatalogList = () => {
  const dispatch = useDispatch();
  const tendersState = useSelector((state) => state.tender.tenders);
  const { data: tenders, isLoading } = tendersState;

  useEffect(() => {
    if (!tendersState.isInitialized) {
      dispatch(getTenders());
    }
  }, [dispatch, tendersState.isInitialized]);

  return (
    <Layout reduxIsUsed={true}>
      <Helmet>
        <title>Liste Appels d'offre</title>
      </Helmet>
      <Container fluid>
       <h2 className="text-center py-3"><small>Liste des Appels d'Offre</small></h2>
        {isLoading ? (
          <FakeDelivery />
        ) : (
          <Table striped bordered hover>
            <tbody>
              {tenders.map((tender) => (
                <tr key={tender.id}>
                  <td>{tender.id}</td>
                  <td>{tender.name}</td>
                  <td className="text-right">
                    <Link to={`/appel_offre_prix/${tender.id}/prix`} className="mr-2">
                      <Button variant="primary">Editer les prix</Button>
                    </Link>
                    <Link to={`/appel_offre_prix/${tender.id}/palier`}>
                      <Button variant="secondary">Editer les paliers</Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Container>
    </Layout>
  );
};

export default TenderCatalogList;
