import React from "react";
import { Form, Row, Col } from "react-bootstrap";

const InstallationBlock = ({ 
  office,
  trash,
  nonWovenTablecloth,
  basin,
  buffetDecoration,
  hasOtherMaterial,
  rentalEquipment,
  moulinEquipment,
  onSiteEquipment,
  onChange }) => (
  <div>
    <h3>Installation</h3>
    <Row
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        border: "1px solid lightgray",
        borderRadius: "10px",
        margin: "10px 0",
      }}
    >
      <Col xs={6} md="6">
        <Form.Check
          type="checkbox"
          label="Office"
          checked={office}
          onChange={(e) => onChange("office", e.target.checked)}
        />
        <Form.Check
          type="checkbox"
          label="Supports de poubelle"
          checked={trash}
          onChange={(e) => onChange("trash", e.target.checked)}
        />
        <Form.Check
          type="checkbox"
          label="Vasque"
          checked={basin}
          onChange={(e) => onChange("basin", e.target.checked)}
        />
        <Form.Check
          type="checkbox"
          label="Déco de buffet"
          checked={buffetDecoration}
          onChange={(e) => onChange("buffetDecoration", e.target.checked)}
        />
        <Form.Check
          type="checkbox"
          label="Nappage intissé"
          checked={nonWovenTablecloth}
          readOnly
        />
        <Form.Check
          type="checkbox"
          label="Autre..."
          checked={hasOtherMaterial}
          onChange={(e) => onChange("hasOtherMaterial", e.target.checked)}
        />
      </Col>
      {/* Matériel de location */}
      <Col xs={6} md="6">
        <Form.Group controlId="formRentalEquipment">
          <Form.Label>Matériel de location</Form.Label>
          <div className="only-web">
            <Form.Control
              as="textarea"
              value={rentalEquipment}
              onChange={(e) => onChange("rentalEquipment", e.target.value)}
            />
          </div>
          <div className="only-print">
            <b>{rentalEquipment}</b>
          </div>
        </Form.Group>
      </Col>
      {/* Matériel du moulin */}
      <Col xs={6} md="6">
        <Form.Group controlId="formMoulinEquipment">
          <Form.Label>Matériel du moulin</Form.Label>
          <div className="only-web">
            <Form.Control
              as="textarea"
              value={moulinEquipment}
              onChange={(e) => onChange("moulinEquipment", e.target.value)}
            />
          </div>
          <div className="only-print">
            <b>{moulinEquipment}</b>
          </div>
        </Form.Group>
      </Col>
      {/* Disponible sur place */}
      <Col xs={6} md="6">
        <Form.Group controlId="formOnSiteEquipment">
          <Form.Label>Disponible sur place</Form.Label>
          <div className="only-web">
            <Form.Control
              as="textarea"
              value={onSiteEquipment}
              onChange={(e) => onChange("onSiteEquipment", e.target.value)}
            />
          </div>
          <div className="only-print">
            <b>{onSiteEquipment}</b>
          </div>
        </Form.Group>
      </Col>

    </Row>
  </div>
);

export default InstallationBlock;
