export const GET_ROADMAPS_COMPANION = "GET_ROADMAPS_COMPANION";
export const GET_ROADMAPS_COMPANION_COMPLETED = "GET_ROADMAPS_COMPANION_COMPLETED";
export const GET_ROADMAPS_COMPANION_FAILED = "GET_ROADMAPS_COMPANION_FAILED";

export const UPDATE_DELIVERY = "UPDATE_DELIVERY";
export const UPDATE_DELIVERY_COMPLETED = "UPDATE_DELIVERY_COMPLETED";
export const UPDATE_DELIVERY_FAILED = "UPDATE_DELIVERY_FAILED";

export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_COMMENT_COMPLETED = "ADD_COMMENT_COMPLETED";
export const ADD_COMMENT_FAILED = "ADD_COMMENT_FAILED";

export const REMOVE_COMMENT = "REMOVE_COMMENT";
export const REMOVE_COMMENT_COMPLETED = "REMOVE_COMMENT_COMPLETED";
export const REMOVE_COMMENT_FAILED = "REMOVE_COMMENT_FAILED";

export const GET_DELIVERY_MEN = "COMPANION_GET_DELIVERY_MEN";
export const GET_DELIVERY_MEN_COMPLETED = "COMPANION_GET_DELIVERY_MEN_COMPLETED";
export const GET_DELIVERY_MEN_FAILED = "COMPANION_GET_DELIVERY_MEN_FAILED";

export const SET_CURRENT_DELIVERY_MAN = "COMPANION_SET_CURRENT_DELIVERY_MAN";

export const GET_MATERIALS = "GET_MATERIALS";
export const GET_MATERIALS_COMPLETED = "GET_MATERIALS_COMPLETED";
export const GET_MATERIALS_FAILED = "GET_MATERIALS_FAILED";

export const UPDATE_ORDER_MATERIAL = "UPDATE_ORDER_MATERIAL";
export const UPDATE_ORDER_MATERIAL_COMPLETED = "UPDATE_ORDER_MATERIAL_COMPLETED";
export const UPDATE_ORDER_MATERIAL_FAILED = "UPDATE_ORDER_MATERIAL_FAILED";

export const ADD_ORDER_MATERIAL = "ADD_ORDER_MATERIAL";
export const ADD_ORDER_MATERIAL_COMPLETED = "ADD_ORDER_MATERIAL_COMPLETED";
export const ADD_ORDER_MATERIAL_FAILED = "ADD_ORDER_MATERIAL_FAILED";

export const ADD_DELIVERY_MEDIA = "ADD_DELIVERY_MEDIA";
export const ADD_DELIVERY_MEDIA_COMPLETED = "ADD_DELIVERY_MEDIA_COMPLETED";
export const ADD_DELIVERY_MEDIA_FAILED = "ADD_DELIVERY_MEDIA_FAILED";

export const DELETE_DELIVERY_MEDIA = "DELETE_DELIVERY_MEDIA";
export const DELETE_DELIVERY_MEDIA_COMPLETED = "DELETE_DELIVERY_MEDIA_COMPLETED";
export const DELETE_DELIVERY_MEDIA_FAILED = "DELETE_DELIVERY_MEDIA_FAILED";

export const UPDATE_ROADMAP_DELIVERY = "UPDATE_ROADMAP_DELIVERY";
export const UPDATE_ROADMAP_DELIVERY_COMPLETED = "UPDATE_ROADMAP_DELIVERY_COMPLETED";
export const UPDATE_ROADMAP_DELIVERY_FAILED = "UPDATE_ROADMAP_DELIVERY_FAILED";

export const SET_CURRENT_DELIVERY = "SET_CURRENT_DELIVERY";

export const getRoadmapsCompanion = (filters) => ({
  type: GET_ROADMAPS_COMPANION,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/roadmaps.json`,
    method: "GET",
    params: {filters: filters},
  },
});

export const updateDelivery = (id, data) => ({
  type: UPDATE_DELIVERY,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/deliveries/${id}.json`,
    method: "PUT",
    body: data,
  },
});

export const updateRoadmapDelivery = (id, data) => ({
  type: UPDATE_ROADMAP_DELIVERY,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/roadmap-deliveries/${id}.json`,
    method: "PUT",
    body: data,
  },
});

export const addComment = (deliveryId, data) => ({
  type: ADD_COMMENT,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/deliveries/${deliveryId}/comments.json`,
    method: "POST",
    body: data
  },  
});

export const deleteComment = (deliveryId, commentId) => ({
  type: REMOVE_COMMENT,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/deliveries/${deliveryId}/comments/${commentId}.json`,
    method: "DELETE"
  },
});

export const getDeliveryMen = () => ({
  type: GET_DELIVERY_MEN,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/deliverymen.json?order_by[firstname]=ASC`,
    method: "GET",
  },
});

export const setCurrentDeliveryMan = (deliveryMan) => ({
  type: SET_CURRENT_DELIVERY_MAN,
  payload: deliveryMan,
});

export const getMaterials = () => ({
  type: GET_MATERIALS,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/materials.json`,
    method: "GET",
  },
});

export const updateOrderMaterial = (orderId, deliveryId, materialId, data) => ({
  type: UPDATE_ORDER_MATERIAL,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/orders/${orderId}/materials/${materialId}.json`,
    method: "PUT",
    body: data,
    deliveryId: deliveryId,
  },
});

export const addOrderMaterial = (orderId, deliveryId, materialId, quantity) => ({
  type: ADD_ORDER_MATERIAL,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/orders/${orderId}/materials.json`,
    method: "POST",
    body: {
      materialId: materialId,
      deliveredQuantity: quantity,
    },
    deliveryId: deliveryId,
  },
});

export const addOrderMaterialAsRecovery = (orderId, deliveryId, materialId, quantity) => ({
  type: ADD_ORDER_MATERIAL,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/orders/${orderId}/materials.json`,
    method: "POST",
    body: {
      materialId: materialId,
      recoveredQuantity: quantity,
    },
    deliveryId: deliveryId,
  },
});

export const addMedia = (deliveryId, data) => ({
  type: ADD_DELIVERY_MEDIA,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/deliveries/${deliveryId}/medias.json`,
    method: "POST",
    body: data
  },  
});

export const deleteMedia = (deliveryId, mediaId) => ({
  type: DELETE_DELIVERY_MEDIA,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/deliveries/${deliveryId}/medias/${mediaId}.json`,
    method: "DELETE"
  },  
});

export const setCurrentDelivery = (delivery) => ({
  type: SET_CURRENT_DELIVERY,
  payload: delivery,
});
