import React  from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import { css } from 'emotion'
import Layout from 'app/universal/layout/Layout'
import Orders from 'app/orderTool/pages/missingInformations/Orders'
import OrdersProvider from 'app/orderTool/context/OrdersProvider'
import moment from 'moment'
import { rangeDate } from 'app/orderTool/context/Utils'
import {Helmet} from "react-helmet";

const MissingInformations = (props) => {

  const today = moment(); // date du jour
  const end = moment().add(2, 'month'); // 

  return (
    <OrdersProvider params={{
      'limit': 100, 
      'active_missing_fields':1, 
      'startDate': moment(today).format('YYYY-MM-DD'), 
      'endDate': moment(end).format('YYYY-MM-DD'), 
      'filters': {
        'status':[101,102],
        'delivery_date': rangeDate(moment(today).format('YYYY-MM-DD'),moment(end).format('YYYY-MM-DD'))
      },
      'order_by': {'delivery_date' : 'ASC'}
    }}>
      <Layout reduxIsUsed={true}>
        <Helmet>
          <title>Information manquante</title>
        </Helmet>
        <Container fluid={true}>
          <Row noGutters>
            <Col xs={12} sm={12}>
              <h4 className={css(styles.h4)}>Commandes avec des informations manquantes pour finaliser un devis <br/><small><i>(Classé de la date de prestation la plus proche à la plus éloignée les 100 premières sur les 2 prochains mois)</i></small></h4>
                <Orders router={props.history}/>
            </Col>
          </Row>
        </Container>
      </Layout>
    </OrdersProvider>
  )

}


const styles = {
  h4:{
    margin: '2rem 0 1rem 0' 
  }
}

export default MissingInformations
