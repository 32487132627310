import React from "react";
import { Form, Row, Col } from "react-bootstrap";

const AdditionalInfoBlock = ({
  brief,
  participants,
  nbWaiters,
  onChange,
}) => (
  <Row
    style={{
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      border: "1px solid lightgray",
      borderRadius: "10px",
      margin: "10px 0",
    }}
  >
    <Col md="12">
      <Form.Group controlId="formBrief">
        <Form.Label>Brief :</Form.Label>
        <div className="only-web">
          <Form.Control
            as="textarea"
            value={brief}
            onChange={(e) => onChange("brief", e.target.value)}
          />
        </div>
        <div className="only-print">
          <b>{brief}</b>
        </div>
      </Form.Group>
    </Col>
    <Col xs={6} md="6">
      <Form.Group controlId="formParticipants">
        <Form.Label>Nombre de participants : </Form.Label>
        <br/><b>{participants}</b>
      </Form.Group>
    </Col>
    <Col xs={6} md="6">
      <Form.Group controlId="formsNbWaiters">
        <Form.Label>Nombre de serveurs : </Form.Label>
        <div className="only-web">
          <Form.Control type="number" value={nbWaiters} onChange={(e) => onChange("nbWaiters", e.target.value)}/>
        </div>
        <div className="only-print">
          <b>{nbWaiters}</b>
        </div>
      </Form.Group>
    </Col>
  </Row>
);

export default AdditionalInfoBlock;
