import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "app/companion/components/layout/Layout";
import { Container, Row, Col, Card, Badge, Form, Modal, Button, Spinner } from "react-bootstrap";
import Menu from "app/companion/pages/delivery/Menu";
import DeliveryTitle from 'app/companion/pages/delivery/DeliveryTitle';
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import AddMaterial from "app/companion/pages/delivery/materials/AddMaterial";
import useCompanion from "app/companion/useCompanion";
import useConnectedUser from "app/universal/hook/useConnectedUser";
import { updateOrderMaterial, addOrderMaterial, addOrderMaterialAsRecovery } from "state/roadmapsCompanion/actions";

const DeliveryMaterial = () => {

  const dispatch = useDispatch();

  const { connectedUser } = useConnectedUser();
  const { filteredRoadmap, filteredDelivery, materials, isCompanionLoading } = useCompanion();
  const [isOpenModalMaterial, setIsOpenModalMaterial] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [isRecovery, setIsRecovery] = useState(filteredDelivery?.delivery?.recovery || null);
  const [loadingStates, setLoadingStates] = useState({});
  const [quantities, setQuantities] = useState({});
  const [orderMaterials, setOrderMaterials] = useState([]);

  useEffect(() => {
    if (filteredDelivery) {
      setOrderId(filteredDelivery?.delivery?.info?.order_id ? filteredDelivery.delivery.info.order_id : null);
      setIsRecovery(filteredDelivery?.recovery);
      setOrderMaterials(filteredDelivery?.delivery?.info?.materials ? filteredDelivery.delivery.info.materials : []);
    }
  }, [filteredDelivery]);

  const handleClose = () => {
    setIsOpenModalMaterial(false);
  };

  const handleOrderMaterialChange = async (orderMaterialId, value) => {
    let data = {};
    setLoadingStates((prevState) => ({ ...prevState, [orderMaterialId]: true }));
    if (isRecovery) {
      data = {
        recoveredQuantity: value,
      };
    } else {
      data = {
        deliveredQuantity: value,
      };
    }
    try {
      await dispatch(updateOrderMaterial(orderId, filteredDelivery.id, orderMaterialId, data));
    } catch (error) {
      console.error('Error updating order material:', error);
    } finally {
      setLoadingStates((prevState) => ({ ...prevState, [orderMaterialId]: false }));
    }
  };

  const handleInputChange = (orderMaterialId, value) => {
    setQuantities((prevState) => ({ ...prevState, [orderMaterialId]: value }));
  };

  const handleAddOrderMaterial = (materialId, quantity) => { 
    if (isRecovery) {
      dispatch(addOrderMaterialAsRecovery(orderId, filteredDelivery.id, materialId, quantity));
    } else {
      dispatch(addOrderMaterial(orderId, filteredDelivery.id, materialId, quantity));
    }
    setIsOpenModalMaterial(false);
  };

  return (
    (filteredDelivery && filteredRoadmap) &&
    <Layout backButton
    isCompanionLoading={isCompanionLoading}>
        <>
          <Container fluid={true}>
            <>
              <DeliveryTitle/>
              <h6 className="text-center my-3">{isRecovery ? "MATERIEL A RÉCUPERER" : "MATÉRIEL À DÉPOSER"}</h6>

              {orderMaterials.filter((orderMaterial) => orderMaterial.is_to_collect).map((orderMaterial, index) => (
                <Card key={index} className="p-2 mb-2">
                  <Row className="align-items-center">
                    <Col xs="4">
                      {isRecovery ? 
                        <Form.Control 
                          type="number" 
                          placeholder="Quantité" 
                          value={quantities[orderMaterial.id] !== undefined ? quantities[orderMaterial.id] : (orderMaterial.recovered_quantity !== undefined ? orderMaterial.recovered_quantity : (orderMaterial.delivered_quantity !== undefined ? orderMaterial.delivered_quantity : (orderMaterial.quantity !== undefined ? orderMaterial.quantity : orderMaterial.auto_generated_quantity)))}
                          onChange={(e) => handleInputChange(orderMaterial.id, e.target.value)}
                          onBlur={(e) => handleOrderMaterialChange(orderMaterial.id, e.target.value)}
                        />
                        :
                        <Form.Control 
                          type="number" 
                          placeholder="Quantité" 
                          value={quantities[orderMaterial.id] !== undefined ? quantities[orderMaterial.id] : (orderMaterial.delivered_quantity !== undefined ? orderMaterial.delivered_quantity : orderMaterial.quantity !== undefined ? orderMaterial.quantity : orderMaterial.auto_generated_quantity)}
                          onChange={(e) => handleInputChange(orderMaterial.id, e.target.value)}
                          onBlur={(e) => handleOrderMaterialChange(orderMaterial.id, e.target.value)}
                          disabled={loadingStates[orderMaterial.id]}
                        />
                      }
                    </Col>
                    <Col xs="8">
                      <Card.Text>
                      {loadingStates[orderMaterial.id] && <Spinner animation="border" size="sm" />} {orderMaterial.name}
                      </Card.Text>
                    </Col>
                    {connectedUser.roles.includes("ROLE_DEV") && (
                      <Col xs="12" className="warning">
                        <Card.Text>
                          <Warning>
                            <i>Visible dev uniquement</i><br />
                            Quantité auto: {orderMaterial.auto_generated_quantity || 0}<br />
                            Quantité prod: {orderMaterial.quantity !== undefined ? orderMaterial.quantity : "Identique à auto"}<br />
                            Quantité livrée: {orderMaterial.delivered_quantity !== undefined ? orderMaterial.delivered_quantity : "Identique à prod"}<br />
                            Quantité recupérée: {orderMaterial.recovered_quantity !== undefined ? orderMaterial.recovered_quantity : "Identique à livrée"}<br />
                          </Warning>
                        </Card.Text>
                      </Col>
                    )}
                  </Row>
                </Card>
              ))}

              {/* Ajout Materiel */}
              <FixButton>
                <Button size="lg" variant="dark" className="mt-2" onClick={() => setIsOpenModalMaterial(true)}><FontAwesomeIcon icon={faPlus} /></Button>
              </FixButton>
              <AddMaterial isOpen={isOpenModalMaterial} handleClose={handleClose} handleAddMaterial={handleAddOrderMaterial} materials={materials}/>
            </>
          </Container>
          <Menu roadmap={filteredRoadmap && filteredRoadmap} delivery={filteredDelivery && filteredDelivery} comments={filteredDelivery?.delivery?.comments?.length}/>
        </>
    </Layout>
  );
};

const Warning = styled.span`
  color: orange;
  font-style: italic;
  font-size: small;
`
const FixButton = styled.span`
  position: fixed;
  bottom: 75px;
  right: 20px;
  margin: auto;
  text-align: center;
`

export default DeliveryMaterial;