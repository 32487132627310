import React, { useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointRight } from '@fortawesome/free-solid-svg-icons';
// La feuille de route est spécifique pour la personne dédié aux service lors d'évenements avec service = info de livraison + info commande + info de prod & co
const TutoPwaModal = (props) => {

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <div>
      <Button
        variant="light"
        onClick={handleShowModal}
        block
        className="mb-3"
        size=""
      >
        <FontAwesomeIcon icon={faHandPointRight} className="ml-2" /> Nouveau ? Installe l'appli ici
      </Button>
      
      <Modal show={showModal} onHide={handleCloseModal}>
        <div className="p-2">
          <Modal.Header closeButton>
            <Modal.Title>Comment installer l'appli sur ton téléphone ?</Modal.Title>
          </Modal.Header>

          <Alert variant="warning">Avant l'installation merci d'utiliser/mettre à jour l'un de ces navigateurs pour utiliser notre appli : Chrome/Firefox/Safari dispo dans ton magasin d'appli.</Alert>

          <h5 className="mt-3">
            Safari - Sur iPhone
            <hr/>
          </h5>
          <p>Appuyez sur le bouton "Partager" situé en bas de l'écran (icône représentant un carré avec une flèche). Dans le menu qui s'affiche, sélectionnez l'option "Ajouter à l'écran d'accueil". Ceci créera une icône sur votre écran d'accueil, semblable à une application</p>

          <h5 className="mt-3">
            Chrome - Sur Android ou iphone
            <hr/>
          </h5>
          <p>Cliquez sur l'icône en forme de menu à trois lignes dans le coin supérieur droit de la fenêtre du navigateur. Sélectionnez "Installer Companion" dans le menu déroulant. Confirmez l'installation, et voilà ! L'icône de votre application apparaîtra dans votre menu de démarrage.</p>

          <h5 className="mt-3">
            Firefox - Sur Android ou iphone
            <hr/>
          </h5>
          <p>Cliquez sur l'icône en forme de menu à trois lignes dans le coin supérieur droit de la fenêtre du navigateur. Sélectionnez "Installer Companion" dans le menu déroulant. Confirmez l'installation, et voilà ! L'icône de votre application apparaîtra dans votre menu de démarrage.</p>


        </div>
      </Modal>
  </div>
  );
};

export default TutoPwaModal;
