import React, { useState, useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { Col, Row } from "react-bootstrap";
import { css } from "emotion";
import moment from "moment";

import { useFilters } from "app/deliveryTool/context/FiltersProvider";
import { useRoadmaps } from "app/deliveryTool/context/RoadmapsProvider";

import LeMoulinApi from "app/universal/utils/LeMoulinApi";
import ListRoadMaps from "app/deliveryTool/pages/itineraire/ListRoadMaps";
import DetailRoadMap from "app/deliveryTool/pages/itineraire/DetailRoadMap";
import CreateRoadmapInput from "app/deliveryTool/pages/itineraire/CreateRoadmapInput";
import DnDDeliveries from "app/deliveryTool/pages/itineraire/DnDDeliveries";
import DirectionsMap from "app/universal/map/DirectionsMap";
import FakeDelivery from "app/deliveryTool/components/delivery/FakeDelivery";

const BuildRoadMaps = (props) => {
  const filters = useFilters();
  const rmData = useRoadmaps();

  const { roadmapsInit, roadmaps, deliveries, recoveries, loading } = rmData;

  const setRoadmaps = (data) => {
    rmData.setRoadmaps(data);
  };
  const setDeliveries = (data) => {
    rmData.setDeliveries(data);
  };
  const setRecoveries = (data) => {
    rmData.setRecoveries(data);
  };

  const [gmapData, setGmapData] = useState();
  const [roadmapId, setRoadmapId] = useState();
  const [rmDistance, setRmDistance] = useState(undefined);
  const [rmStartHour, setRmStartHour] = useState(undefined);
  const [roadmapIndex, setRoadmapIndex] = useState(null);

  // Permet de gérer le chargement des requetes lancées depuis ce composant
  const [localLoading, setLocalLoading] = useState(false);

  // Assigne la mise en valeur de l'element quand son marqueur est cliqué sur la map
  const onMarkerClick = (id) => {
    filters.setShowDelivery(id);
  };

  // Défini l'index du rm dans la tableau des rm
  // let roadmapIndex = null;
  // if (roadmaps.length > 0) {
  //   roadmapIndex = roadmaps.findIndex(
  //     function (element) {
  //       return parseInt(element.id) === parseInt(roadmapId);
  //     },
  //     [roadmapId]
  //   );
  // }

  // Gère le transfert entre un roadmap et la liste
  useEffect(() => {
    if (roadmapId === undefined && props.idRoadMapUrl !== undefined) {
      setRoadmapId(props.idRoadMapUrl);
      filters.setShowRoadmap(props.idRoadMapUrl);
    } else if (roadmapId !== undefined && props.idRoadMapUrl === undefined) {
      setRoadmapId();
      filters.setShowRoadmap(null);
    }
  }, [props.idRoadMapUrl, roadmapId]);

  // L'index du roadmap dans le tableau des roadmaps
  useEffect(() => {
    if (roadmaps.length > 0) {
      let index = roadmaps.findIndex(
        function (element) {
          return parseInt(element.id) === parseInt(roadmapId);
        },
        [roadmapId]
      );
      if (index !== -1) {
        setRoadmapIndex(index);
      }
    }
  }, [roadmapId, roadmaps]);

  // Quand la date change, on revient à la liste
  useEffect(() => {
    props.router.push({
      pathname: "/itineraires",
      search: window.location.search,
    });

    filters.setShowRoadmap(null);
    // eslint-disable-next-line
  }, [filters.date]);

  // Les deux effets s'occupent de l'heure de début et de la distance des livraisons
  useEffect(() => {
    const rmValues = calculDistanceNHour();
    if (rmValues !== undefined) {
      if (rmValues.distance !== undefined) {
        setRmDistance(rmValues.distance);
      }
      if (rmValues.startHour !== undefined) {
        setRmStartHour(rmValues.startHour);
      }
    }
    // eslint-disable-next-line
  }, [gmapData]);

  useEffect(() => {
    if (
      rmDistance !== undefined &&
      roadmapsInit &&
      roadmapsInit[roadmapIndex] &&
      (roadmapsInit[roadmapIndex].distance !== rmDistance ||
        roadmapsInit[roadmapIndex].startHour !== rmStartHour)
    ) {
      const updateData = async () => {
        setLocalLoading(true);
        const result = await LeMoulinApi.request.roadmap.put(
          roadmapsInit[roadmapIndex].id,
          { 
            distance: rmDistance, 
            startHour: rmStartHour
          }
        );
        rmData.setRoadmapsInit(() => {
          const data = roadmapsInit.map((item, i) => {
            if (i === roadmapIndex) {
              return result;
            } else {
              return item;
            }
          });
          return data;
        });
      };
      updateData();
      setLocalLoading(false);
    }
    // eslint-disable-next-line
  }, [rmStartHour, rmDistance, roadmapIndex]);

  // Création d'un rm
  const create = async (event) => {
    const deliveryManId = event.target.value;
    if (deliveryManId !== "all") {
      let roadmapsInitClone = [...rmData.roadmapsInit];
      setLocalLoading(true);
      const postRoadmap = await LeMoulinApi.request.roadmap.post({
        date: filters.date,
        deliveryMan: deliveryManId,
        distance: 0,
      });
      roadmapsInitClone.push(postRoadmap);
      rmData.setRoadmapsInit(roadmapsInitClone);
      props.router.push({
        pathname: "itineraires/" + postRoadmap.id,
        search: window.location.search,
      });
      setLocalLoading(false);
    }
  };

  // Ajoute une livraison à un rm
  const addRmDelivery = async (index, source, destination) => {
    let items = [...roadmaps];
    let delivery =
      source.droppableId === "recoveries"
        ? recoveries[source.index]
        : deliveries[source.index];
    let isRecovery = source.droppableId === "recoveries" ? 1 : 0;
    items[index].deliveries.splice(destination.index, 0, {
      delivery: delivery,
      sort: destination.index,
      recovery: isRecovery,
    });
    setRoadmaps(items);
    removeDelivery(source);
    setLocalLoading(true);
    await LeMoulinApi.request.roadmap.deliveries.add(roadmapsInit[index].id, {
      delivery: delivery.id,
      sort: destination.index + 1,
      recovery: isRecovery,
    });

    // Ajout de la fonction handleDeliveriesApiData sinon les autres livraisons n'avait pas leur sort à jour et créaient des décalages, leger décalage le temps que ca se met à jour, à améliorer mais fonctionnel
    const deliveriesUpdate = handleDeliveriesApiData(index);
    const resultUpdate = await LeMoulinApi.request.roadmap.put(
      roadmapsInit[index].id,
      {
        deliveries: deliveriesUpdate,
        distance: rmDistance,
        startHour: rmStartHour,
      }
    );

    updateRmInit(resultUpdate, index);
    setLocalLoading(false);
  };

  // Retire une livraison d'un rm
  const removeRmDelivery = async (
    roadmapIndex,
    source,
    destination,
    disableDrag
  ) => {

    setLocalLoading(true);

    let items = [...roadmaps];
    let rmDelivery = items[roadmapIndex].deliveries.splice(source.index, 1);
    let rmDeliveryId = rmDelivery[0].id;
    setRoadmaps(items);
    addDelivery(rmDelivery[0], destination);
    await LeMoulinApi.request.roadmap.deliveries.remove(
      roadmapsInit[roadmapIndex].id,
      rmDeliveryId
    );

    // Ajout de la fonction handleDeliveriesApiData sinon les autres livraisons n'avait pas leur sort à jour et créaient des décalages, leger décalage le temps que ca se met à jour, à améliorer mais fonctionnel
    const deliveriesUpdate = handleDeliveriesApiData(roadmapIndex);
    const resultUpdate = await LeMoulinApi.request.roadmap.put(
      roadmapsInit[roadmapIndex].id,
      {
        deliveries: deliveriesUpdate,
        distance: rmDistance,
        startHour: rmStartHour,
      }
    );

    updateRmInit(resultUpdate, roadmapIndex, disableDrag);
    
    setLocalLoading(false);
  };

  /**
   * 02/23 - Réattribution possible des récupérations
   */
  const recoveryReallocation = async (
    roadmapIndex,
    rmDeliveryIndex,
    roadmapId,
    disableDrag
  ) => {
    let source = {
      droppableId: roadmapId,
      index: rmDeliveryIndex,
    };
    let destination = {
      droppableId: "recoveries",
      index: 0,
    };
    return removeRmDelivery(roadmapIndex, source, destination, disableDrag);
  };

  // Reorganise l'ordre d'une livraison dans un rm
  const sortRmDelivery = async (index, source, destination) => {
    setLocalLoading(true);
    let items = [...roadmaps];
    let rmDelivery = items[index].deliveries.splice(source.index, 1);
    items[index].deliveries.splice(destination.index, 0, rmDelivery[0]);
    setRoadmaps(items);
    const deliveries = handleDeliveriesApiData(index);
    const result = await LeMoulinApi.request.roadmap.put(
      roadmapsInit[index].id,
      { deliveries: deliveries, 
        distance: rmDistance, 
        startHour: rmStartHour
      }
    );
    updateRmInit(result, index);
    setLocalLoading(false);
  };

  // Forme les donnees pour la requete des sorting des livraisons
  const handleDeliveriesApiData = (index) => {
    var data = [];

    roadmaps[index].deliveries.forEach((rmDelivery, index) => {
      let isRecovery = rmDelivery.recovery ? 1 : 0;
      data.push({
        delivery: rmDelivery.delivery.id,
        recovery: isRecovery,
      });
    });
    // Sort
    data.forEach((d, i) => {
      data[i].sort = i + 1;
    });
    return data;
  };

  // Retire une livraison de la liste des livraisons
  const removeDelivery = (source) => {
    let items =
      source.droppableId === "recoveries" ? [...recoveries] : [...deliveries];
    items.splice(source.index, 1);
    source.droppableId === "recoveries"
      ? setRecoveries(items)
      : setDeliveries(items);
  };

  // Ajoute une livraison à la liste des livraisons
  const addDelivery = (deliveryRm, destination) => {
    let items = deliveryRm.recovery ? [...recoveries] : [...deliveries];
    items.splice(destination.index, 0, deliveryRm.delivery);
    deliveryRm.recovery ? setRecoveries(items) : setDeliveries(items);
  };

  // Calcul la distance et l'heure de départ avec les données gmap
  const calculDistanceNHour = () => {
    if (roadmapsInit[roadmapIndex]) {
      let today = moment(filters.date);
      if (roadmapsInit[roadmapIndex].deliveries.length < 1) {
        return { distance: 0, startHour: null };
      }

      let distance = 0;
      let startHour = null;
      let arrivedAt = null;

      // Livraison
      if (
        roadmapsInit[roadmapIndex].deliveries[0].recovery === false &&
        roadmapsInit[roadmapIndex].deliveries[0].delivery.arrived_at_start
      ) {
        arrivedAt =
          roadmapsInit[roadmapIndex].deliveries[0].delivery.arrived_at_start;
        arrivedAt = moment(arrivedAt).format("HH:mm");
        arrivedAt = arrivedAt.split(":");
        today = today
          .set({ hour: arrivedAt[0], minute: arrivedAt[1] })
          .toDate();
        arrivedAt = today;
      }
      // Recup
      else if (
        roadmapsInit[roadmapIndex].deliveries[0].recovery === true &&
        roadmapsInit[roadmapIndex].deliveries[0].delivery.end_at
      ) {
        arrivedAt = roadmapsInit[roadmapIndex].deliveries[0].delivery.end_at;
        arrivedAt = moment(arrivedAt).format("HH:mm");
        arrivedAt = arrivedAt.split(":");
        today = today
          .set({ hour: arrivedAt[0], minute: arrivedAt[1] })
          .toDate();
        arrivedAt = today;
      }

      if (gmapData.routes.length > 0) {
        gmapData.routes.forEach((route, index) => {
          route.legs.forEach((leg, i) => {
            distance += leg.distance.value;

            if (i === 0 && arrivedAt !== null) {
              startHour = moment(arrivedAt)
                .subtract(leg.duration.value, "seconds")
                .format("HH:mm");
            }
          });
        });
      }

      return { distance: distance, startHour: startHour };
    }
  };

  // Met à jour les données init des rm
  const updateRmInit = (result, index, disabledDrag) => {
    rmData.setRoadmapsInit(() => {
      const data = roadmapsInit.map((item, i) => {
        if (i === index) {
          return result;
        } else {
          return item;
        }
      });
      return data;
    });
    rmData.setDragDisabled(false);
  };

  // Fonction quand un dnd se termine
  const onDragEnd = (result) => {
    const { source, destination } = result;

    let action;
    if (!destination) {
      return;
    }
    if (
      source.droppableId === "deliveries" &&
      destination.droppableId === "recoveries"
    ) {
      return;
    }
    if (
      destination.droppableId === "deliveries" &&
      source.droppableId === "recoveries"
    ) {
      return;
    } else if (
      source.droppableId !== "deliveries" &&
      source.droppableId !== "recoveries" &&
      source.droppableId === destination.droppableId
    ) {
      // Si aucun changement de place dans la liste de l'itinéraire, on ne fait rien
      if (source.index === destination.index) {
        return;
      }
      action = "sort";
    } else if (source.droppableId === destination.droppableId) {
      return;
    } else if (
      destination.droppableId === "deliveries" ||
      destination.droppableId === "recoveries"
    ) {
      action = "remove";
    } else {
      action = "add";
    }

    // Désactive le dnd
    rmData.setDragDisabled(true);

    // Ajoute/supprime temporairement la livraison au planning
    switch (action) {
      case "add":
        addRmDelivery(roadmapIndex, source, destination);
        break;
      case "remove":
        removeRmDelivery(roadmapIndex, source, destination);
        break;
      case "sort":
        sortRmDelivery(roadmapIndex, source, destination);
        break;
      default:
        console.log("Action introuvable: " + action + ".");
    }
  };

  /**
   * 02/23 - Permet de changer le livreur d'une tournée
   */
  const handleChangeDeliveryMan = async (roadmapId, deliveryManId) => {
    setLocalLoading(true);
    const result = await LeMoulinApi.request.roadmap.put(roadmapId, {
      deliveryManId: deliveryManId,
    });
    let index = roadmaps.findIndex((x) => x.id === roadmapId);
    updateRmInit(result, index);
    setLocalLoading(false);
  };

  const handleChangeRoadmapStartHour = async (roadmapId, startHour, isManual = false) => {
    setLocalLoading(true);
    let manualStartHour = isManual && startHour && startHour !== '' ? startHour : null;
    let autoStartHour = null;
    if (!manualStartHour) {
      const rmValues = calculDistanceNHour();
      autoStartHour = rmValues.startHour
    }
    const result = await LeMoulinApi.request.roadmap.put(roadmapId, {
      startHour: autoStartHour,
      manualStartHour: manualStartHour,
    });
    let index = roadmaps.findIndex((x) => x.id === roadmapId);
    updateRmInit(result, index);
    setLocalLoading(false);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Row noGutters>
        <Col
          xs={12}
          sm={12}
          md={4}
          className={css(styles.col, styles.noPrint, styles.noBorder)}
          id="listing"
        >
          {loading ? (
            <FakeDelivery />
          ) : (
            <div>
              {filters.deliveryType !== "recovery" && (
                <DnDDeliveries
                  items={deliveries}
                  type="deliveries"
                  dragDisabled={rmData.dragDisabled}
                />
              )}
              {filters.deliveryType !== "delivery" && (
                <DnDDeliveries
                  items={recoveries}
                  type="recoveries"
                  dragDisabled={rmData.dragDisabled}
                />
              )}
            </div>
          )}
        </Col>
        <Col xs={12} sm={12} md={4} className={css(styles.col, styles.printFw)}>
          {loading ? (
            <FakeDelivery />
          ) : (
            <div>
              {roadmapId ? (
                <div>
                  <DetailRoadMap
                    roadmap={roadmaps[roadmapIndex]}
                    date={filters.date}
                    dragDisabled={rmData.dragDisabled}
                    recoveryReallocation={(rmDeliveryIndex) => {
                      recoveryReallocation(
                        roadmapIndex,
                        rmDeliveryIndex,
                        roadmaps[roadmapIndex].id,
                        true
                      );
                    }}
                    handleChangeDeliveryMan={handleChangeDeliveryMan}
                    handleChangeRoadmapStartHour={handleChangeRoadmapStartHour}
                  />
                </div>
              ) : (
                <div>
                  <ListRoadMaps roadmaps={roadmaps} date={filters.date} />
                  <div className={css(styles.center)}>
                    <CreateRoadmapInput handleChange={create} />
                  </div>
                </div>
              )}
            </div>
          )}
        </Col>
        <Col xs={12} sm={12} md={4} className="no-print">
          {localLoading ? (
            <FakeDelivery />
          ) : roadmapId ? (
            <DirectionsMap 
              roadmap={roadmaps[roadmapIndex]}
              filters={{ showRoadmap: roadmapId }} 
              isLoading={false}
              handleRouteData={(data) => {
                setGmapData(data);
              }}
            />
          ) : (
            <DirectionsMap
              onMarkerClick={onMarkerClick}
              filters={filters}
              deliveries={deliveries}
              recoveries={recoveries}
              roadmaps={roadmaps}
              isLoading={loading || localLoading}
            />
          )}
        </Col>
      </Row>
    </DragDropContext>
  );
};

const styles = {
  center: {
    textAlign: "center",
  },
  title: {
    fontWeight: "inherit",
    fontSize: "1.6rem",
  },
  col: {
    padding: "0.5rem 0.5rem",
    borderLeft: "1px solid #dedede",
    borderRight: "1px solid #dedede",
    height: "89vh",
    overflowY: "scroll",
  },
  noBorder: {
    borderLeft: "none",
  },
  printFw: {
    "@media print": {
      width: "100%",
      flex: "0 0 100%",
      maxWidth: "100%",
      height: "auto",
      overflowY: "auto",
    },
  },
  noPrint: {
    "@media print": {
      display: "none",
    },
  },
};

export default BuildRoadMaps;
