import React, { useEffect, useState }  from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import { css } from 'emotion'
import moment from 'moment'
import 'moment/locale/fr';
import LeMoulinApi from 'app/universal/utils/LeMoulinApi'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

import Layout from 'app/universal/layout/Layout'
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

const Agenda = (props) => {

  // Permet d'ajouter les dates dans l'url
  let history = useHistory();
  // Les commandes formatées pour le full calendar
  const [orders, setOrders] = useState([]);
  // Les commandes filtrées pour le full calendar
  const [ordersFiltered, setOrdersFiltered] = useState([]);
  // Date de début et de fin du calendrier
  const [dates, setDates] = useState({});
  // Contiendra les dates pour lesquelles la requete order aura deja été faite (pour éviter de les refaire)
  const [dateListing, setDateListing] = useState([]);
  const [newDates, setNewDates]       = useState([]);
  // Etat de la checkbox "annulation uniquement"
  const [isCanceled, setIsCanceled] = useState(false);

  // Retourn un object date à partir d'une date au format 'YYYY-MM-DD'
  const getDateObject = (date) => {
    let dateSplited = date.split('-');
    // 07/03/22 - fix, le mois correspond à l'index (donc janvier = 0)
    return new Date(dateSplited[0],dateSplited[1] - 1,dateSplited[2]);
  }

  // Retourne un tableau listant les dates entre deux dates
  const getDatesBetweenTwoDates = (start, end) => {
    var dateArray = [];
    var currentDate = moment(getDateObject(start));
    var stopDate = moment(getDateObject(end));
    // 07/03/22 - lié au fix
    // currentDate = moment(currentDate).subtract(1, 'M');
    // stopDate    = moment(stopDate).subtract(1, 'M');
    while (currentDate < stopDate) {
        dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
  }

  // Met à jour les states utiles pour les dates
  const changeDates = (dates) => {
    if (dates.start === undefined) {
      return;
    }
    setDates({'start':dates.start,'end':dates.end});
    // Ajoute à l'url
    history.push('?startDate='+dates.start+'&endDate='+dates.end);
    // Liste les nouvelles dates
    let newDateListing = getDatesBetweenTwoDates(dates.start,dates.end);
    newDateListing = newDateListing.filter(x => !dateListing.includes(x))
    // Pas de nouvelles dates
    if (newDateListing.length < 1) {
      return;
    }
    // Ajoute les nouvelles dates
    setNewDates(newDateListing);
    newDateListing = [...new Set([...newDateListing, ...dateListing])]
    setDateListing(newDateListing)
  }

  // Uniquement quand des nouvelles dates ont été ajoutées
  useEffect(() => {

    // Recupère les commandes
    const fetchData = async () => {
      
      if (newDates.length < 1) {
        return;
      }
      // 25/01/24 - On veut récuperer toutes les commandes meme celle annulées/refusées
      let params = {
        groups : ['delivery'],
        filters: {
          delivery_date: newDates,
          status       : [101,102,103,400,401,402,411,412]
        },
        include_cancel_order : 1,
        limit: 1000
      }

      const ordersResult = await LeMoulinApi.request.order.gets(params)

      let data = [];
      // 06/23 - ajoute le niveau d'activité
      newDates.map((newDate, i) => {

        // Jour fermé
        let closedDate = ordersResult.closedDates.find((x) => {
          return moment(x).format('YYYY-MM-DD') == newDate;
        });

        // Niveau d'activité
        let activityLevel = ordersResult.activityLevels.find((x) => {
          return moment(x.date).format('YYYY-MM-DD') == newDate;
        });
        
        // Si week end pas d'event sauf si niveau d'activité
        // if (!activityLevel && (moment(newDate).format('dddd') == 'samedi' || moment(newDate).format('dddd') == 'dimanche')) {
        //   return;
        // }
        
        // Si une activité en bdd ou que ce n'est pas un jour fermé
        return data.push({
          'id'   : 'al-'+ newDate,
          'title': "Niveau d'activité",
          'start': new Date(moment(newDate).format('YYYY/MM/DD')),
          'end'  : new Date(moment(newDate).format('YYYY/MM/DD')),
          'allDay': true,
          'name' : 'activityLevel',
          'prLevel': activityLevel ? activityLevel.pr_level : 'low',
          'breakfastLevel': activityLevel ? activityLevel.breakfast_level : 'low',
          'catererLevel': activityLevel ? activityLevel.caterer_level : 'low',
          'prLevelFR': (closedDate || (moment(newDate).format('dddd') == 'samedi' || moment(newDate).format('dddd') == 'dimanche')) ? "fermé" : (activityLevel && activityLevel.pr_level == 'high' ? 'activité bloquée' : activityLevel && activityLevel.pr_level == 'medium' ? 'forte activité' : 'libre'),
          'breakfastLevelFR': activityLevel && activityLevel.breakfast_level == 'high' ? 'activité bloquée' : activityLevel && activityLevel.breakfast_level == 'medium' ? 'forte activité' : 'libre',
          'catererLevelFR': activityLevel && activityLevel.caterer_level == 'high' ? 'activité bloquée' : activityLevel && activityLevel.caterer_level == 'medium' ? 'forte activité' : 'libre',
          'prLevelColor': (closedDate || (moment(newDate).format('dddd') == 'samedi' || moment(newDate).format('dddd') == 'dimanche')) ? "red" : (activityLevel && activityLevel.pr_level == 'high' ? 'red' : activityLevel && activityLevel.pr_level == 'medium' ? 'orange' : 'green'),
          'breakfastLevelColor': activityLevel && activityLevel.breakfast_level == 'high' ? 'red' : activityLevel && activityLevel.breakfast_level == 'medium' ? 'orange' : 'green',
          'catererLevelColor': activityLevel && activityLevel.caterer_level == 'high' ? 'red' : activityLevel && activityLevel.caterer_level == 'medium' ? 'orange' : 'green',
        });
        
      });
      
      ordersResult.orders.map((order, i) => {
        return data.push({
          'id' : order.id,
          'title' : order.delivery.company,
          'start' : order.delivery.arrived_at_start ? new Date(moment(order.delivery.delivery_date).format('YYYY/MM/DD')+' '+moment(order.delivery.arrived_at_start).format('HH:mm')) : new Date(moment(order.delivery.delivery_date).format('YYYY/MM/DD')),
          'end' : order.delivery.arrived_at_start ? new Date(moment(order.delivery.delivery_date).format('YYYY/MM/DD')+' '+moment(order.delivery.arrived_at_start).format('HH:mm')) : new Date(moment(order.delivery.delivery_date).format('YYYY/MM/DD')),
          'name' : order.lastname,
          'format' : order.format,
          'type' : order.delivery.prestation_type,
          'status' : order.status,
          'nbPerso' : order.participants_number,
          'numbering': order.numbering,
          'confirmed_number' : order.confirmed_participants_number,
          'url': order.info.quotation_url,
          'service': order.delivery.service,
          'hasZeroWasteProduct' : order.info.hasZeroWasteProduct,
          'cancelDate' : order.cancel_date
        })
      });
      
      setOrders(o => data.concat(o));
      // Etat initial après requete - On filtre les commandes récupérées en excluant les annulées et refusées
      data = data.filter((o) => ((o.status < 400 && (o.cancelDate === null || o.cancelDate === undefined)) || (o.title == "Niveau d'activité") ));
      setOrdersFiltered(o => data.concat(o));
    }
    fetchData()

  }, [newDates]);
  
  // Récupère les dates du calendrier au chargement de la page
  const onCalendarLoading = (calendarApi) => {
    if (calendarApi.view !== undefined && calendarApi.view.activeStart !== undefined) {
      if (dates.start !== calendarApi.view.activeStart.getFullYear()+'-'+(calendarApi.view.activeStart.getMonth() + 1)+'-'+calendarApi.view.activeStart.getDate()) {
        // setDates({'start':calendarApi.view.activeStart.getFullYear()+'-'+(calendarApi.view.activeStart.getMonth() + 1)+'-'+calendarApi.view.activeStart.getDate(),'end':calendarApi.view.activeEnd.getFullYear()+'-'+(calendarApi.view.activeEnd.getMonth() + 1)+'-'+calendarApi.view.activeEnd.getDate()})
        changeDates({'start':calendarApi.view.activeStart.getFullYear()+'-'+(calendarApi.view.activeStart.getMonth() + 1)+'-'+calendarApi.view.activeStart.getDate(),'end':calendarApi.view.activeEnd.getFullYear()+'-'+(calendarApi.view.activeEnd.getMonth() + 1)+'-'+calendarApi.view.activeEnd.getDate()})
      }
    }
  }

  // Récupère les dates du calendrier qu changement du mois
  const changeMonth = (event) => {
    if (['fc-icon fc-icon-chevron-right','fc-icon fc-icon-chevron-left', 'fc-today-button fc-button fc-button-primary'].indexOf(event.target.className) > -1) {
      if (calendarRef.current) {
        let calendarApi = calendarRef.current.getApi();
        let activeRange = calendarApi.state.dateProfile.activeRange;
        let startDate   = activeRange.start;
        let endDate     = activeRange.end;
        changeDates({'start':startDate.getFullYear()+'-'+(startDate.getMonth() + 1)+'-'+startDate.getDate(),'end':endDate.getFullYear()+'-'+(endDate.getMonth() + 1)+'-'+endDate.getDate()})
        // setDates({'start':startDate.getFullYear()+'-'+(startDate.getMonth() + 1)+'-'+startDate.getDate(),'end':endDate.getFullYear()+'-'+(endDate.getMonth() + 1)+'-'+endDate.getDate()});
      }
    }
  }
  document.addEventListener('click', changeMonth);

  const EventMonth = ({ event, el, view }) => {

    var start            = event.start;
    var title            = event._def.title;
    var name             = event._def.extendedProps.name;
    var kind             = event._def.extendedProps.type;
    var type             = event._def.extendedProps.format;
    var numbering        = event._def.extendedProps.numbering;
    var nbPerso          = event._def.extendedProps.nbPerso;
    var status           = event._def.extendedProps.status;
    var confirmed_number = event._def.extendedProps.confirmed_number;
    var service          = event._def.extendedProps.service;
    var hasZeroWasteProduct          = event._def.extendedProps.hasZeroWasteProduct;
    var cancelDate       = event._def.extendedProps.cancelDate;
    
    if ( type === 'mealtray' )
      type = "Plateaux-repas";
    else if ( type === 'buffet' )
      type = "Buffet";
    else if ( type === 'salad_bowl' )
      type = "Saladiers";
    else if ( type === 'packed_lunch' )
      type = "Repas nomades";
    else if ( kind === 'breakfast' )
      type = "Petit déjeuner - " + type;
    else if ( kind === 'caterer' )
      type = "Traiteur - " + type;

    var bgColor = (kind === 'mealtray' || kind === 'v2') ? 'lightgrey' : kind === 'caterer' ? 'lightblue' : kind === 'breakfast' ? 'lightgreen' : name === 'activityLevel' || name === 'closedDate' ? 'white' : 'orange';
    
    var bgColor = (status >= 400 || (cancelDate !== null && cancelDate !== undefined)) ? 'red' : bgColor;

    var borderBottom = (
      status === '101'
        ?
      'orange'
        :
      ((status === '102' || status === '103') && confirmed_number === true)
        ?
      'green'
        :
      ((status === '102' || status === '103') && (confirmed_number === null || confirmed_number === false))
        ?
      'yellow'
        :
      'red');

    if(numbering === undefined){ numbering = ""}

    var styles = "overflow: hidden;white-space: nowrap;text-align:center;border:none;background-color:"+bgColor+";color:black;margin-bottom:3px;font-size:0.8rem;border-bottom-style:solid;border-bottom-color:"+borderBottom+";border-bottom-width:3px;    display: block;"

    el.setAttribute("style", styles);

    // 06/23 - Ajoute l'activité
    if (name == 'activityLevel'){
      el.innerHTML = "<span><span style='background-color:"+event._def.extendedProps.prLevelColor+";'>PR : " + event._def.extendedProps.prLevelFR +"</span><br/> <span style='background-color:"+event._def.extendedProps.catererLevelColor+";'>Cockt :" + event._def.extendedProps.catererLevelFR +"</span><br/> <span style='background-color:"+event._def.extendedProps.breakfastLevelColor+";'>PDJ :" + event._def.extendedProps.breakfastLevelFR +"</span></span>"
    }
    else if((status >= 400 || (cancelDate !== null && cancelDate !== undefined))){
      el.innerHTML = "<b>- ANNULÉE - </b><span>" + title + "<br/>" + numbering + " " + name + " " + nbPerso +" pers<br/>" +type+"</span>"
    }
    else if (name == 'closedDate'){
      el.innerHTML = "<span style='background-color:red;'>PR fermé</span>"
    }
    else if((hasZeroWasteProduct === true || hasZeroWasteProduct === 1) && (service === true || service === 1)){
      el.innerHTML = "<span>" + moment(start).format('HH:mm') + "- <strong>" + title + "</strong><br/> " + numbering + " " + name + " " + nbPerso +" pers <span style='width:20px;height:20px; background-color: red; color: red; display: inline-block;border-radius: 50px;'></span><span style='width:20px;height:20px; background-color:#94c42c; color: white; display: inline-block;border-radius: 50px;'></span><br/>" +type+"</span>"
    }
    else if(hasZeroWasteProduct === true || hasZeroWasteProduct === 1){
      el.innerHTML = "<span>" + moment(start).format('HH:mm') + "- <strong>" + title + "</strong><br/> " + numbering + " " + name + " " + nbPerso +" pers <span style='width:20px;height:20px; background-color:#94c42c; color: white; display: inline-block;border-radius: 50px;'></span><br/>" +type+"</span>"
    }
    else if(service === true || service === 1){
      el.innerHTML = "<span>" + moment(start).format('HH:mm') + "- <strong>" + title + "</strong><br/> " + numbering + " " + name + " " + nbPerso +" pers <span style='width:20px;height:20px; background-color: red; color: red; display: inline-block;border-radius: 50px;'></span><br/>" +type+"</span>"
    }
    else{
      el.innerHTML = "<span>" + moment(start).format('HH:mm') + "- <strong>" + title + "</strong><br/> " + numbering + " " + name + " " + nbPerso +" pers <br/>" +type+"</span>"
    }


  }
  const eventClick = (info) => {
    info.jsEvent.preventDefault();
    if (info.event.url) {
       window.open(info.event.url, "_blank");

       return false;
    }
  }

  // Fonction pour inverser l'état de la checkbox lorsqu'elle est cliquée + filtrage
  const handleIsCanceledChange = () => {
    setIsCanceled(!isCanceled);
    if(!isCanceled){
      // On filtre que pour avoir les annulées et refusées
      setOrdersFiltered(orders.filter((order) => ((order.status >= 400 || (order.cancelDate !== null && order.cancelDate !== undefined)) || (order.title == "Niveau d'activité") )));
    }else{
       // On filtre pour exclure les annulées et refusées
       setOrdersFiltered(orders.filter((order) => ((order.status >= 400 || (order.cancelDate !== null && order.cancelDate !== undefined)) || (order.title == "Niveau d'activité") )));
      }
  };

  const calendarRef = React.createRef();
  return (
    <Layout>
      <Helmet>
        <title>Agenda</title>
      </Helmet>
      <Container fluid={true}>
        <div className={css(styles.calendar)}>
          <Row>
              <Col xs={12} sm={6}>
              <p>
                <small>Légende :  <span className={css(styles.pastilleRed)}></span> Service, <span className={css(styles.pastilleGreen)}></span> Zéro déchet </small><br/>
              </p>
              </Col>
              <Col xs={12} sm={6}>
                <p className="text-right">
                  <input
                    type="checkbox"
                    checked={isCanceled}
                    onChange={handleIsCanceledChange}
                    id="isCanceled"
                  /> <label htmlFor="isCanceled"><small> Commandes annulées uniquement</small></label>
                </p>
              </Col>
            </Row>
        <FullCalendar
          eventClick={eventClick}
          viewSkeletonRender={onCalendarLoading}
          defaultView="dayGridWeek"
          plugins={[ dayGridPlugin, listPlugin ]}
          events={ordersFiltered}
          eventRender={EventMonth}
          locale="frLocale"
          firstDay={1}
          ref={calendarRef}
          views= {{
            dayGridMonth: { buttonText: 'Agenda par mois' },
            dayGridWeek: { buttonText: 'Agenda par semaine' },
            dayGridDay: { buttonText: 'Agenda par jour' }
          }}
          header={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth, dayGridWeek'
          }}
          />
        </div>
      </Container>
    </Layout>
  )

}


const styles = {
  calendar: {
    padding: '10px 20px'
  },
  pastilleRed:{
    'width': '15px',
    'height':'15px', 
    'backgroundColor': 'red',
    'color': 'red',
    'display': 'inline-block',
    'borderRadius': '50px'
  },
  pastilleGreen:{
    'width': '15px',
    'height':'15px', 
    'backgroundColor': '#94c42c',
    'color': 'red',
    'display': 'inline-block',
    'borderRadius': '50px'
  }
}

export default Agenda
