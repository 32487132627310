export const GET_CONNECTED_USER = "GET_CONNECTED_USER";
export const GET_CONNECTED_USER_COMPLETED = "GET_CONNECTED_USER_COMPLETED";
export const GET_CONNECTED_USER_FAILED = "GET_CONNECTED_USER_FAILED";

export const REVOKE_TOKEN = "REVOKE_TOKEN";
export const REVOKE_TOKEN_COMPLETED = "REVOKE_TOKEN_COMPLETED";
export const REVOKE_TOKEN_FAILED = "REVOKE_TOKEN_FAILED";

export const getConnectedUserInfo = () => ({
  type: GET_CONNECTED_USER,
  meta: {
    async: true,
    blocking: true,
    path: `/api/me.json`,
    method: "GET",
    params: {},
  },
});

export const revokeToken = () => {
  const refreshToken = window.localStorage.getItem('refresh_token');
  return {
    type: REVOKE_TOKEN,
    meta: {
      async: true,
      blocking: true,
      path: `/api/token/invalidate`,
      method: "GET",
      params: {refresh_token: refreshToken},
    }
  }
};