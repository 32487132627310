import React from 'react'
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom'
import {Container, Tooltip, ButtonToolbar, OverlayTrigger, Dropdown} from 'react-bootstrap'
import { css } from 'emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faListAlt, faFolderOpen, faMapMarkedAlt, faArrowCircleLeft, faSync, faCalendar, faExclamationTriangle, faCog } from '@fortawesome/free-solid-svg-icons'
import { useWindowDimensions } from 'app/universal/utils/WindowDimensionsProvider'

import logo from 'app/universal/assets/images/logo-moulin.png'
import {
  revokeToken,
} from "state/common/actions";


const url = `${process.env.REACT_APP_API_URL}/e28XmZh3/`;

const DesktopMenu = (props) => {
    const dispatch = useDispatch();
    const { width } = useWindowDimensions() || { width: 0 };
    const logout = () => {
        dispatch(revokeToken()).then(() => {
          window.localStorage.removeItem('token');
          window.localStorage.removeItem('refresh_token');
          window.location.href = '/';
        });
    };

    return (
      <Container fluid={true} className={css(style.header)}>
        <nav>
          <li className={css(style.menuLogo)}>
            <img src={logo} alt='Logo Moulin' />
          </li>
          <ul className={css(style.desktopMenu)}>
            {width >= 890 &&
              <span>
                <li className={css(style.menuItem, style.doc)}>
                  <a target="_blank" href="https://moulindev.freshdesk.com/support/solutions/folders/48000699166"><FontAwesomeIcon icon={faFile} /> Doc multidevis</a>
                </li>
                <li className={css(style.menuItem)}><Link id="managerOrder" to={{
                    pathname: `/`,
                    search: window.location.search
                  }}><FontAwesomeIcon icon={faFolderOpen} /> Devis/Facture</Link></li>
                <li className={css(style.menuItem)}><Link id="missing-information" to={{
                    pathname: `/informations-devis-manquantes`,
                    search: window.location.search
                  }}><FontAwesomeIcon icon={faExclamationTriangle} /> Info manquante</Link></li>
                <li className={css(style.menuItem)}><Link id="planning" to={{
                    pathname: `/planning`,
                    search: window.location.search
                  }}><FontAwesomeIcon icon={faListAlt} /> Planning</Link></li>
                <li className={css(style.menuItem)}><Link id="roadmap" to={{
                    pathname: `/itineraires`,
                    search: window.location.search
                  }}><FontAwesomeIcon icon={faMapMarkedAlt} /> Tournée</Link></li>
                <li className={css(style.menuItem)}><Link id="calendar" to={{
                    pathname: `/agenda`
                  }}><FontAwesomeIcon icon={faCalendar} /> Agenda</Link></li>
                <li className={css(style.menuRight)}>
                  {/* <ButtonToolbar>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>
                            Permet de synchroniser les infos de l'interface si des modifications ont été faites sur le BO du Moulin en parrallèle.
                          </Tooltip>
                        }
                      >
                        <div className={css(style.syncro)} onClick={ () => window.location.reload()}><FontAwesomeIcon icon={faSync} /> Synchroniser</div>
                      </OverlayTrigger>
                  </ButtonToolbar> */}
                    <Dropdown className={css(style.button)} drop="left">
                        <Dropdown.Toggle variant="outline-light" className="mt-1">
                            <FontAwesomeIcon icon={faCog} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu variant="dark">
                            <Dropdown.Item as={Link} to={'/appel_offre'}>
                                Prix Appel d'offre
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} to={'/companion'}>Companion livreur</Dropdown.Item>
                            <Dropdown.Item href="#/" onClick={ () => window.location.reload()}>Rafraîchir les données</Dropdown.Item>
                            <Dropdown.Item href={url}>Retour au BO</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="#/" onClick={ () => logout()}>Se déconnecter</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
              </span>
            }

          </ul>
        </nav>
      </Container>
    )
}

const style = {
  header: {
    background: "#689D71",
  },
  'desktopMenu': {
    listStyleType: 'none',
    margin: '0',
    padding: '0',
    height: '52px'
  },
  menuLogo:{
    float: 'left',
    display: 'block',
    textAlign: 'center',
    padding: '3px 16px 0px 0',
    textDecoration: 'none',
    transition: '300ms',
    cursor: 'pointer',
    ':hover': {
      opacity: '0.5'
    }
  },
  menuItem: {
    float: 'left',
    borderLeft: '1px solid rgba(255, 255, 255, 0.2)',
    a: {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      padding: '14px 16px',
      textDecoration: 'none',
      ':hover': {
        opacity: '0.5'
      }
    }
  },
  doc: {
    'backgroundColor': '#e6005c'
  },
  'active': {
    'color' : 'black'
  },
  'menuRight':{
    'float': 'right',
    'border': 'none'
  },
  'button': {
    color: 'black'
  },
}

export default DesktopMenu
