import React, { useState } from "react";
import { Form, Modal, Button, Alert } from "react-bootstrap";

const AddComment = (props) => {
  const { deliveryId, isOpen, handleClose, isCompanionLoading, addComment } = props;
  const [comment, setComment] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // FormData pour envoyer des fichiers
    const formData = new FormData();
    formData.append("comment", comment);
    formData.append("tags[0]", selectedTag);
    files.forEach((file, index) => {
      formData.append(`medias[${index}]`, file);
    });
    
    await addComment(deliveryId, formData).then((resp) => {
      if (
        (resp?.response?.data?.success !== undefined && resp.response.data.success === false)
        ||
        (resp.success !== undefined && resp.success === false)
      ) {
        let errorMessage = "Une erreur s'est produite lors de l'ajout du commentaire.";
        if (resp?.response?.data?.message) {
          errorMessage = resp.response.data.message;
        }
        else if (resp?.message) {
          errorMessage = resp.message;
        }
        setError(errorMessage);
      } else {
        setError(null);
        handleClose();
        setComment("");
        setSelectedTag("");
        setFiles([]);
      }
    });
  };

  const handleFileChange = (event) => {
    const maxSizeMb = 20;
    const maxTotalMb = 21;
    const maxSizePerFile = maxSizeMb * 1024 * 1024; // 20MB
    const maxTotalSize = maxTotalMb * 1024 * 1024; // 21MB
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const selectedFiles = Array.from(event.target.files);
    const validFiles = [];
    let totalSize = 0;

    selectedFiles.forEach((file) => {
      if (file.size > maxSizePerFile) {
        setError(`Le fichier ${file.name} dépasse la taille maximale autorisée de ${maxSizeMb}MB.`);
      } else if (!allowedTypes.includes(file.type)) {
        setError(`Le type de fichier ${file.type} n'est pas autorisé.`);
      } else {
        totalSize += file.size;
        if (totalSize > maxTotalSize) {
          setError(`La taille totale des fichiers dépasse la limite autorisée de ${maxTotalMb}MB.`);
        } else {
          validFiles.push(file);
        }
      }
    });

    setFiles(validFiles);
  };

  return (
    <Modal show={isOpen} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Ajout de commentaire</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isCompanionLoading ? 
          <p>Chargement...</p>
        :
          <Form onSubmit={handleSubmit}>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form.Group controlId="formTag">
              <Form.Label>Tag</Form.Label>
              <Form.Control
                as="select"
                value={selectedTag}
                onChange={(e) => setSelectedTag(e.target.value)}
                required
              >
                <option value="">Sélectionnez un tag</option>
                <option value="client">Client</option>
                <option value="lieux">Lieux</option>
                <option value="materiel">Matériel</option>
                <option value="autres">Autres</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formComment">
              <Form.Label>Commentaire</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formFile">
              <Form.Label>Fichiers</Form.Label>
              <Form.Control
                type="file"
                multiple
                onChange={handleFileChange}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Soumettre
            </Button>
          </Form>
      }
      </Modal.Body>
    </Modal>
  );
};

export default AddComment;