import {
  GET_CONNECTED_USER,
  GET_CONNECTED_USER_COMPLETED,
  GET_CONNECTED_USER_FAILED
} from "./actions";

const initialState = {
  user: {
    isLoading: false,
    isInitialized: false,
    hasFailed: false,
    data: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
    }
  },
  isLoading: false,
  isInitialized: false,
  hasFailed: false,
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONNECTED_USER:
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: true,
        },
      };
    case GET_CONNECTED_USER_COMPLETED:
      return {
        ...state,
        user: {
          data: action.payload.result,
          isLoading: false,
          isInitialized: true,
          hasFailed: false,
        },
      };
    case GET_CONNECTED_USER_FAILED:
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: false,
          isInitialized: true,
          hasFailed: true,
        },
      };
    default:
      return state;
  }
};

export default reducers;
