import React from "react";
import styled from "@emotion/styled";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faBiking,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Badge } from "react-bootstrap";
import useCompanion from "app/companion/useCompanion";

const MenuWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  border-top: 1px solid #ccc;
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: #000;
  padding: 0px 20px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    font-size: 16px;
  }
  
  &:hover, &.active {
    padding: 0px 20px;
    background-color: #eee !important;
    color: black;
    text-decoration: none;
  }
`;

const Menu = (props) => {
    
  const { delivery, roadmap, comments } = props;
  const location = useLocation();
  const { orderMaterials } = useCompanion();
  return (
    (roadmap && delivery) && 
      <MenuWrapper>
        <Row noGutters>
          <Col>
            <MenuItem
              as={Link}
              to={`/companion/tournee/${roadmap.id}/${delivery.id}/detail${location.search}`}
              className={location.pathname === `/companion/tournee/${roadmap.id}/${delivery.id}/detail` && "active"}
            >
              <div>
                <FontAwesomeIcon icon={faBiking} /> <Badge variant="primary" pill className="ml-1">{delivery.delivery.info.numbering}</Badge>
              </div>
              <span>{delivery.recovery ? "Récupération" : "Livraison"}</span>
            </MenuItem>
          </Col>
          <Col>
            <MenuItem
            as={Link}
            to={`/companion/tournee/${roadmap.id}/${delivery.id}/materiel${location.search}`}
            className={location.pathname === `/companion/tournee/${roadmap.id}/${delivery.id}/materiel` && "active"}
          >
            <div>
              <FontAwesomeIcon icon={faUtensils} /> {(orderMaterials && orderMaterials.length > 0) ? <Badge variant="primary" pill className="ml-1">{orderMaterials.filter((orderMaterial) => orderMaterial.is_to_collect).length}</Badge> : null}
            </div>
            <span>Matériel</span>
            </MenuItem>
          </Col>
          <Col>
            <MenuItem
              as={Link}
              to={`/companion/tournee/${roadmap.id}/${delivery.id}/commentaire${location.search}`}
              className={location.pathname === `/companion/tournee/${roadmap.id}/${delivery.id}/commentaire` && "active"}
            > 
              <div>
                <FontAwesomeIcon icon={faComment} /> {(comments && comments > 0) ? <Badge variant="primary" pill className="ml-1">{comments}</Badge> : null}
              </div>
              <span>Commentaire </span>
            </MenuItem>
          </Col>
        </Row>
      </MenuWrapper>
  );
};

export default Menu;
