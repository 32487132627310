import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setOrderDeliveryInfo,
  setOrderInfo,
} from "state/orderEdition/actions";
import { Container, Row, Col, Form } from "react-bootstrap";

const CommentaryOrderInfoComponent = ({ orderIndex }) => {
  const dispatch = useDispatch();
  const order = useSelector((state) => state.orderEdition.orders[orderIndex]);
  const deliveryInfo = order?.deliveryInfo;

  const [deliveryInformation, setDeliveryInformation] = useState(deliveryInfo.deliveryInformation);

  const [recoverInformation, setRecoverInformation] = useState(deliveryInfo.recoverInformation);

  const [commentaryForProd, setCommentaryForProd] = useState(
    order.commentaryForProd || null
  );

  useEffect(() => {
    if (
      deliveryInformation !== deliveryInfo.deliveryInformation ||
      recoverInformation !== deliveryInfo.recoverInformation
    ) {
      dispatch(
        setOrderDeliveryInfo(orderIndex, {
          ...deliveryInfo,
          deliveryInformation: deliveryInformation,
          recoverInformation: recoverInformation,
        })
      );
    }
  }, [
    dispatch,
    deliveryInformation,
    recoverInformation,
  ]);

  useEffect(() => {
    if (
      commentaryForProd !== order?.commentaryForProd
    ) {
      dispatch(
        setOrderInfo(orderIndex, {
          ...order,
          commentaryForProd: commentaryForProd
        })
      );
    }
  }, [
    dispatch,
    commentaryForProd,
    orderIndex,
    order,
  ]);

  useEffect(() => {
    setDeliveryInformation(deliveryInfo.deliveryInformation || null);
    setRecoverInformation(deliveryInfo.recoverInformation || null);
  }, [deliveryInfo]);

  useEffect(() => {
    setDeliveryInformation(deliveryInfo.deliveryInformation || null);
    setRecoverInformation(deliveryInfo.recoverInformation || null);
    setCommentaryForProd(order.commentaryForProd || null);
  }, [orderIndex]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Form.Group controlId="deliveryInformation">
            <Form.Label>Livraison (livreur)</Form.Label>
            <Form.Control
              as="textarea"
              value={deliveryInfo.deliveryInformation || ''}
              onChange={(e) => setDeliveryInformation(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="recoverInformation">
            <Form.Label>Récupération (livreur)</Form.Label>
            <Form.Control
              as="textarea"
              value={deliveryInfo.recoverInformation || ''}
              onChange={(e) => setRecoverInformation(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="commentaryForProd">
            <Form.Label>Production</Form.Label>
            <Form.Control
              as="textarea"
              value={commentaryForProd || ''}
              onChange={(e) => setCommentaryForProd(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="customerSpecialCommentary ">
            <Form.Label>Commentaire du client</Form.Label>
            <Form.Control
              as="textarea"
              value={order?.customerSpecialCommentary  || ''}
              disabled
            />
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export default CommentaryOrderInfoComponent;
