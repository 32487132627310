import React from "react";
import moment from "moment";
import styled from '@emotion/styled'
import { Accordion } from "react-bootstrap";
import Delivery from "./Delivery";
import { Element } from 'react-scroll';
import DirectionsMap from 'app/universal/map/DirectionsMap'; 

const Roadmap = (props) => {
  
  const { roadmap } = props;

  return (
    <Element name={roadmap.name + roadmap.id} className="element">
      <Title color={"#02B39E"}>
        <b>TOURNÉE {roadmap.name}</b><br />
        <small>{moment(roadmap.date).format("DD/MM/YYYY")}</small><br />
        <small>Départ : {moment(roadmap?.manual_start_hour ? roadmap.manual_start_hour : roadmap.start_hour).format("HH:mm")} - {Number(roadmap.distance / 1000).toFixed(1)}km</small>
      </Title>
      {roadmap && <MapCss>
        <DirectionsMap 
          roadmap={roadmap}
          filters={{ showRoadmap: roadmap.id }} 
          isLoading={false}
        />
      </MapCss>}
      
        {roadmap.deliveries.map((delivery, index) => (
          <Accordion className="mt-3" defaultActiveKey="0" key={index}>
           <Delivery delivery={delivery} roadmap={roadmap}/>
          </Accordion>
        ))}
      
    </Element>
  );
};

const Title = styled.div`
  text-align: center;
  border-radius: 4px;
  background-color: ${props => (props.color ? props.color : 'white')};
  width: 100%;
  border: thin solid ${props => (props.color ? props.color : 'white')};
  color: white;
  padding: 0.5rem 0.5rem;
  white-space: nowrap;
  margin: 1rem 0;
`

const MapCss = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
  height: 200px;
  border-radius: 4px;
  background-color: #ddd;
  overflow: hidden;
`

export default Roadmap;
