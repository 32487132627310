import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import moment from "moment";
import { updateRoadmapDelivery } from "state/roadmapsCompanion/actions";
import { faHandPointer } from "@fortawesome/free-solid-svg-icons";
import { selectIsRoadmapsLoading } from "state/roadmapsCompanion/selectors";
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import useCompanion from "app/companion/useCompanion";

const DeliveryTitle = () => {
  const dispatch = useDispatch();
  const { filteredRoadmap: roadmap, filteredDelivery: delivery } = useCompanion();
  const isLoading = useSelector((state) => selectIsRoadmapsLoading(state));
  const [isDelivered, setIsDelivered] = useState(delivery?.done);

  useEffect(() => {
    if (delivery) {
      setIsDelivered(delivery.done);
    }
  }, [delivery]);

  const toggleDeliveryStatus = async () => {
    const isRecovery = delivery.recovery;
    if (isRecovery) {
      await dispatch(
        updateRoadmapDelivery(delivery.id, { done: !isDelivered })
      );
    } else {
      await dispatch(
        updateRoadmapDelivery(delivery.id, { done: !isDelivered })
      );
    }
  };

  return (
    <>
      {roadmap && delivery && (
        <Title color={"#02B39E"}>
          <b>
            {moment(roadmap.date).format("DD/MM/YYYY")} -
            {delivery.recovery
              ? "RÉCUPÉRATION"
              : "LIVRAISON " + delivery.delivery.info.numbering}{" "}
            - TOURNÉE {roadmap.name}
          </b>
          <br />
          <Button
            disabled={isLoading}
            variant={isDelivered ? "danger" : "success"}
            className="mt-1"
            onClick={toggleDeliveryStatus}
          >
            {isLoading ? (
              <>
                <FontAwesomeIcon icon={faSpinner} pulse /> Enregistrement ...
              </>
            ) : isDelivered ? (
              <>
                <FontAwesomeIcon icon={faHandPointer} />{" "}
                {delivery.recovery ? "Annuler la récupération" : "Annuler la livraison"}
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faHandPointer} />{" "}
                {delivery.recovery ? "Confirmer la récupération" : "Confirmer la livraison"}
              </>
            )}
          </Button>
        </Title>
      )}
    </>
  );
};

const Title = styled.div`
  text-align: center;
  border-radius: 4px;
  background-color: ${(props) => (props.color ? props.color : "white")};
  width: 100%;
  border: thin solid ${(props) => (props.color ? props.color : "white")};
  color: white;
  padding: 0.5rem 0.5rem;
  white-space: nowrap;
  margin: 1rem 0;
`;

export default DeliveryTitle;
