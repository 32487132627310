import { createSelector } from 'reselect';

// Sélecteur de base pour accéder à la partie du state qui contient les roadmaps
const selectRoadmapsState = (state) => state.roadmaps;

export const selectIsRoadmapsLoading = createSelector(
  [selectRoadmapsState],
  (roadmapsState) => roadmapsState.isLoading
);

export const selectIsRoadmapsInitialized = createSelector(
  [selectRoadmapsState],
  (roadmapsState) => roadmapsState.isInitialized
);

export const selectDeliveryMen = createSelector(
  [selectRoadmapsState],
  (roadmapsState) => roadmapsState?.deliveryMen?.data
);

export const selectCurrentDeliveryMan = createSelector(
  [selectRoadmapsState],
  (roadmapsState) => roadmapsState.currentDeliveryMan
);