import React, { createContext, useContext, useState, useEffect } from 'react'
import moment from 'moment'

import { useFilters } from 'app/deliveryTool/context/FiltersProvider'

import LeMoulinApi from 'app/universal/utils/LeMoulinApi'

const RoadmapsContext = createContext(null)
const RoadmapsProvider = (props) => {

  const filters = useFilters();

  const [roadmapsInit, setRoadmapsInit] = useState([]);
  const [roadmaps, setRoadmaps]         = useState([]);
  const [deliveries, setDeliveries]     = useState([]);
  const [recoveries, setRecoveries]     = useState([]);
  const [deliveryMen, setDeliveryMen]   = useState([]);
  const [dragDisabled, setDragDisabled] = useState(false)
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(filters.date);
  const [isInit, setIsInit] = useState(false);

  const fetchData = async () => {
    const roadmapResult = await LeMoulinApi.request.roadmap.gets({date :filters.date})
    setRoadmapsInit(roadmapResult)

    const deliveriesResult = []
    const recoveryResult   = []
    const dResult = await LeMoulinApi.request.delivery.gets({date:filters.date})

    dResult.forEach((d, i) => {
      if ((d.status === "100" || d.status === "101") && moment(d.delivery_date).format('YYYY-MM-DD') === moment(filters.date).format('YYYY-MM-DD')) {
        deliveriesResult.push(d)
      }
      if ((d.status === "100" || d.status === "110" || d.status === "2201") && d.recovery === true) {
        recoveryResult.push(d)
      }
    })

    setDeliveries(deliveriesResult)
    setRecoveries(recoveryResult)

    if (moment(filters.date).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD')) {
      setDragDisabled(true)
    }else{
      setDragDisabled(false)
    }
    setLoading(false);
  }

  useEffect(() => {
    const fetchDataDeliveryMen = async () => {
      if (deliveryMen.length < 1) {
        const deliveryMenResult = await LeMoulinApi.request.deliveryMan.gets()
        setDeliveryMen(deliveryMenResult)
      }
    }
    fetchDataDeliveryMen();
  }, [deliveryMen])

  useEffect(() => {
    if (!loading) {
      if (filters.date !== selectedDate) {
        setSelectedDate(filters.date);
        setLoading(true);
        fetchData();
      }
      else if (isInit === false) {
        setIsInit(true);
        setLoading(true);
        fetchData();
      }
    }
  }, [selectedDate, roadmapsInit, filters.date, isInit]);

  // Met à jour le state contenant les donnees filtrees
  useEffect(
    () => {
      if (filters.deliveryMan === 'all') {
        setRoadmaps(roadmapsInit)
      }
      else {
        if (roadmapsInit.length > 0) {
          setRoadmaps(roadmapsInit.filter(roadmap => parseInt(roadmap.delivery_man ? roadmap.delivery_man.id : 0) === parseInt(filters.deliveryMan) ))
        }
      }
    },
    [roadmapsInit, filters.deliveryMan]
  );

  const data = {
    roadmapsInit    : roadmapsInit,
    setRoadmapsInit : (roadmapsInit) => {setRoadmapsInit(roadmapsInit)},
    roadmaps        : roadmaps,
    setRoadmaps     : (roadmaps) => {setRoadmaps(roadmaps)},
    deliveries      : deliveries,
    setDeliveries   : (deliveries) => {setDeliveries(deliveries)},
    recoveries      : recoveries,
    setRecoveries   : (recoveries) => {setRecoveries(recoveries)},
    deliveryMen     : deliveryMen,
    setDeliveryMen  : (deliveryMen) => {setDeliveryMen(deliveryMen)},
    dragDisabled     : dragDisabled,
    setDragDisabled  : (dragDisabled) => {setDragDisabled(dragDisabled)},
    loading          : loading,
    setLoading  : (loading) => {setLoading(loading)},
  }

  return (
    <RoadmapsContext.Provider value={data}>
      {props.children}
    </RoadmapsContext.Provider>
  )
}

export default RoadmapsProvider
export const useRoadmaps = () => useContext(RoadmapsContext)