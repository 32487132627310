import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Form } from "react-bootstrap";
import { addOrderItem, removeOrderItem, updateOrderItem } from "state/orderEdition/actions";
import { getProductItemsByOrderIndex } from "state/orderEdition/selectors";
import Select from "react-select";
import ProductItem from './ProductItem';

const ProductsComponent = ({ title, orderIndex, products, classificationName, excludedClassifications, prestationType, tenderCatalog }) => {
  const dispatch = useDispatch();
  const productItems = useSelector((state) => getProductItemsByOrderIndex(state, orderIndex));
  const person = useSelector((state) => state.orderEdition.orders[orderIndex].deliveryInfo.person);
  const [isActive, setIsActive] = useState(true);
  const [showCatalogOnly, setShowCatalogOnly] = useState(false); // État pour l'option de filtre

  const filteredProductItems = classificationName
  ? productItems.filter(item => item.classification_name === classificationName)
  : productItems.filter(item => !excludedClassifications.includes(item.classification_name));

  const productsForSelect = products
  .filter((product) => (!prestationType || product?.types.includes(prestationType)) && product?.active === isActive)
  .filter((product) => !showCatalogOnly || (tenderCatalog?.items && tenderCatalog.items.some((item) => item.product_id === product.id))) // Filtrer par catalogue si l'option est cochée
  .map((product) => ({ value: product.id, label: product.name, ...product }));
  
  const productsWithCatalogName = productsForSelect.map((product) => {
    const tenderCatalogItem = tenderCatalog?.items && tenderCatalog.items.find((item) => item.product_id === product.id);
    if (tenderCatalogItem) {
      // Si renammed n'est pas vide ou null
      const label = tenderCatalogItem.renammed ? tenderCatalogItem.renammed : product.name;
      return { ...product, label: label };
    }
    return product;
  });
  
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [tenderItemPrice, setTenderItemPrice] = useState(null);

  const handleSelectChange = (selectedItem) => {
    setSelectedProduct(selectedItem);
    // Si le produit est dispo dans l'offre AO
    const tenderCatalogItem = tenderCatalog?.items && tenderCatalog.items.find((item) => item.product_id === selectedItem.id);

    if (tenderCatalogItem) {
      // Si un pricings correspond au nombre de personnes (entre min_persons & max_persons)
      let tenderCatalogItemPricing = tenderCatalogItem?.pricings?.find((pricing) => 
        pricing.min_persons <= person &&
        pricing.max_persons >= person &&
        pricing.price !== null && // Vérifie que le palier a un prix valide
        pricing.prestation_types.includes(prestationType)
      );
      
      // Si aucun palier direct ne correspond avec un prix valide
      if (!tenderCatalogItemPricing) {
        const pricingsWithPrice = tenderCatalogItem?.pricings?.filter((pricing) => 
          pricing.price !== null && // Prend seulement les paliers avec un prix valide
          pricing.prestation_types.includes(prestationType) &&
          pricing.min_persons <= person // Exclut les paliers où person est inférieur au minimum
        );
      
        // Trouve le palier le plus proche par distance absolue
        const closestPricing = pricingsWithPrice?.reduce((closest, pricing) => {
          const currentDistance = Math.abs(pricing.max_persons - person);
          const closestDistance = Math.abs(closest.max_persons - person);
          return currentDistance < closestDistance ? pricing : closest;
        }, pricingsWithPrice[0]);
      
        // Utilise le palier le plus proche si trouvé
        if (closestPricing) {
          tenderCatalogItemPricing = closestPricing;
        }
      }

      if (!tenderCatalogItemPricing) {
        setTenderItemPrice(tenderCatalogItem.price === null ? selectedItem.price : tenderCatalogItem.price);
      }
      else {
        setTenderItemPrice(tenderCatalogItemPricing.price === null ? selectedItem.price : tenderCatalogItemPricing.price);
      }
    } else {
      setTenderItemPrice(null);
    }
  };

  const handleAddProduct = (price) => {
    if (selectedProduct && selectedProduct.value) {
      const product = products.find((f) => f.id === Number(selectedProduct.value));
      const tenderCatalogItem = tenderCatalog?.items && tenderCatalog.items.find((item) => item.product_id === selectedProduct.id);
      const renammed = tenderCatalogItem ? tenderCatalogItem?.renammed : null;
      const updatedProduct = {
        name: product.name,
        quantity: 1,
        price: price ? price : product.price,
        product_id: product.id,
        classification_name: product.classificationName,
        type: "product",
        renammed: renammed,
      };
      dispatch(addOrderItem(orderIndex, updatedProduct));
      setSelectedProduct(null);
    }
  };

  const handleRemoveProduct = (index) => {
    dispatch(removeOrderItem(orderIndex, index));
  };

  const handleUpdateProduct = (updatedProduct, index) => {
    dispatch(updateOrderItem(orderIndex, index, updatedProduct));
  };

  return (
    <>
      <Row>
        <Col sm={12} md={6}>
          <h5>{title}</h5>
        </Col>
      </Row>
      {filteredProductItems.map((product, index) => (
        <ProductItem
          key={index}
          product={product}
          index={index}
          onRemove={() => handleRemoveProduct(product.originalIndex)}
          onUpdate={(updatedProduct) => handleUpdateProduct(updatedProduct, product.originalIndex)}
          prestationType={prestationType}
        />
      ))}
      <hr />
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col} md="8" controlId="productSelect">
            <Form.Label>Nom du produit</Form.Label>
            <Select
              placeholder="Chercher un produit"
              options={productsWithCatalogName}
              closeMenuOnSelect={true}
              onChange={handleSelectChange}
              value={selectedProduct}
              name={"productId"}
            />
            <div
              style={{
                color: 'hsl(0, 0%, 40%)',
                display: 'inline-block',
                fontSize: 12,
                fontStyle: 'italic',
                marginTop: '1em',
              }}
            >
              <label style={{ marginRight: '1em' }}>
                <input type="checkbox" checked={isActive}  onChange={() => setIsActive((state) => !state)}/> Produit actifs
              </label>
            </div>
            <div
              style={{
                color: 'hsl(0, 0%, 40%)',
                display: 'inline-block',
                fontSize: 12,
                fontStyle: 'italic',
                marginTop: '1em',
              }}
            >
              <label style={{ marginRight: '1em' }}>
                <input type="checkbox" checked={showCatalogOnly}  onChange={() => setShowCatalogOnly((state) => !state)}/> AO uniquement
              </label>
            </div>
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label style={{ color: 'white' }}>-</Form.Label><br/>
            <Button variant="primary" onClick={(e) => handleAddProduct(tenderItemPrice)} className="w-100">
              Ajouter au détail
            </Button>
          </Form.Group>
        </Row>
      </Form>
    </>
  );
};

export default ProductsComponent;
