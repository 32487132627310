import React, { useEffect, useState } from "react";
import moment from "moment";
import { css } from "emotion";
import styled from '@emotion/styled'
import { Container, Row, Col } from "react-bootstrap";
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';

const Shortcut = (props) => {
  
  const { roadmaps } = props;
  const [whichActive, setWhichActive] = useState(null);

  const handleSetActive = (to, roadmap) => {
    if(roadmap.name + roadmap.id === to){
      setWhichActive(to)
    }else{
      setWhichActive(to)
    }
  };

  return (
  <HorizontalScroll className="horizontal-scrollable">
    <Row noGutters className="mt-2">
      {roadmaps.map((roadmap, index) => (
        <Col index={index} className="p-1" key={index}>
          <Link 
            activeClass="active" 
            to={roadmap.name + roadmap.id} 
            spy={true} 
            smooth={true} 
            offset={-90} 
            duration={500} 
            onSetActive={(to) => handleSetActive(to,roadmap)}
          >
            <Content color={"#02B39E"} active={whichActive === (roadmap.name + roadmap.id) ? true : false}>
              <b>{roadmap.name}</b><br />
              <small>{moment(roadmap?.manual_start_hour ? roadmap.manual_start_hour : roadmap.start_hour).format("HH:mm")} - {Number(roadmap.distance / 1000).toFixed(1)}km</small>
          </Content>
          </Link>
        </Col>
      ))}
    </Row>
  </HorizontalScroll>
  );
};


const Content = styled.div`
  text-align: center;
  border-radius: 4px;
  background: ${props => (props.color && props.active ? props.color : 'white')};
  width: 100%;
  border: thin solid ${props => (props.color ? props.color : 'white')};
  color: black;
  padding: 0.5rem 0.5rem;
  white-space: nowrap;
  cursor: pointer;
  transition: 300ms;
  &:hover, &.active {
    transition: 300ms;
    background-color: ${props => (props.color ? props.color : 'white')};
    color: white;
  }
`
const HorizontalScroll = styled.div`
  position: sticky;
  top: 0;
  background-color: #ecf5f4;
  z-index: 1;
  .row {
    overflow-x: auto;
    white-space: nowrap;
    flex-wrap: nowrap;
  }
  .col {
      display: inline-block;
      float: none;
  }
`

const styles = {

};

export default Shortcut;
