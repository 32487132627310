import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchOrder, updateRoadSheet } from "state/prestationRoadSheet/actions";
import { useParams } from "react-router-dom";
import { Container, Form, Row, Col, Button, Card } from "react-bootstrap";
import Layout from "app/universal/layout/Layout";
import Loader from "app/deliveryTool/components/delivery/FakeDelivery";
import moment from "moment";
import DeliveryBlock from "./DeliveryBlock";
import InstallationBlock from "./InstallationBlock";
import AdditionalInfoBlock from "./AdditionalInfoBlock";
import HeaderBlock from "./HeaderBlock";
import ProductionBlock from "./ProductionBlock";
import AddMedia from "app/companion/pages/delivery/media/AddMedia";
import { deleteMedia } from "state/roadmapsCompanion/actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimesCircle,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import Helmet from "react-helmet";


const PrestationRoadSheet = () => {
  const dispatch = useDispatch();

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const { orderId } = useParams();

  const order = useSelector((state) => state.prestationRoadSheet.data.order);
  const isLoading = useSelector((state) => state.prestationRoadSheet.isLoading);

  const [isDeleting, setIsDeleting] = useState(false);
  const [showAddMediaModal, setShowAddMediaModal] = useState(false);
  const [medias, setMedias] = useState(order?.delivery?.medias_with_titles || []);

  const initializeState = (order) => ({
    init: false,
    brief: order?.road_sheet?.brief || "",
    hotDishes: order?.road_sheet?.hot_dishes || "",
    additionalContact: order?.road_sheet?.additional_contact || "",
    additionalSpotInfo: order?.road_sheet?.additional_spot_info || "",
    parkingAddress: order?.road_sheet?.parking_address || "",
    teamMembers: order?.road_sheet?.team_members || "",
    labDeparture: order?.road_sheet?.lab_departure || "",
    labArrival: order?.road_sheet?.lab_arrival || "",
    office: order?.road_sheet?.office || "",
    trash: order?.road_sheet?.trash || "",
    basin: order?.road_sheet?.basin || "",
    buffetDecoration: order?.road_sheet?.buffet_decoration || "",
    hasOtherMaterial: order?.road_sheet?.has_other_material || "",
    rentalEquipment: order?.road_sheet?.rental_equipment || "",
    onSiteEquipment: order?.road_sheet?.on_site_equipment || "",
    moulinEquipment: order?.road_sheet?.moulin_equipment || "",
    otherRemarks: order?.road_sheet?.other_remarks || "",
    executionRemarks: order?.road_sheet?.execution_remarks || "",
    additionalProdInfo: order?.road_sheet?.additional_prod_info || "",
    extraMeals: order?.road_sheet?.extra_meals || "",
    nbWaiters: order?.road_sheet?.nb_waiters || "",
  });
  const [formData, setFormData] = useState(initializeState(order));

  const handleAddMediaClose = () => setShowAddMediaModal(false);
  const handleAddMediaShow = () => setShowAddMediaModal(true);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleDeleteMedia = (mediaId) => {
    setIsDeleting(true);
    dispatch(deleteMedia(order.delivery.id, mediaId)).then(() => {
      setMedias(medias.filter(media => media.media.id !== mediaId));
      setIsDeleting(false);
    });
  };

  useEffect(() => {
    if (!isLoading && orderId && (!order || parseInt(order.id) !== parseInt(orderId))) {
      dispatch(fetchOrder(orderId, "v2"))
    }
  }, [dispatch, orderId, order, isLoading]);

  useEffect(() => {
    if (order?.id) {
      if (!formData.init) {
        setFormData({...initializeState(order), init: true});
        setMedias(order?.delivery?.medias_with_titles || []);
      }
    }
  }, [order, formData]);

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleSave = () => {
    dispatch(updateRoadSheet(orderId, formData));
  };

  const handleAddMedia = (newMedias) => {
    setMedias(newMedias);
  };

  return (
    <Layout reduxIsUsed={true}>
      {order && order.delivery &&
        <Helmet>
          <title>{moment(order?.delivery?.delivery_date).format("DD-MM-YY")} - {order?.company?.name} - Feuille de route</title>
        </Helmet>
      }
      <Container fluid style={{ padding: "20px 0px 80px 0" }}>
      {isLoading || !formData.init ? (
          <Loader />
        ) : (
          <>
            <div className="text-center pt-2">
              <Button variant="primary" onClick={() => window.print()} className="mb-3 only-web">
                Imprimer
              </Button>
            </div>
            <h2 className="text-center py-2">
              <small>{moment(order?.delivery?.delivery_date).format("DD-MM-YY")} - {order?.company?.name} - Feuille de route</small>
            </h2>
            <HeaderBlock
              date={
                order?.delivery?.delivery_date &&
                moment(order?.delivery?.delivery_date).format("YYYY-MM-DD")
              }
              company={order?.company?.name}
              customer={
                order?.customer?.firstname + " " + order?.customer?.lastname
              }
              prestationType={order?.prestation_type_fr}
            />
            <AdditionalInfoBlock
              brief={formData.brief}
              participants={order?.delivery?.person}
              nbWaiters={formData?.nbWaiters}
              onChange={handleChange}
            />
            <h3>Livraison</h3>
            <DeliveryBlock
              address={order?.delivery?.complete}
              addressComplement={order?.delivery?.address_complement}
              contact={
                order?.delivery?.firstname + " " + order?.delivery?.lastname
              }
              phone={order?.delivery?.phone}
              additionalContact={formData.additionalContact}
              spotInfo={order?.delivery?.delivery_information}
              additionalSpotInfo={formData.additionalSpotInfo}
              parkingAddress={formData.parkingAddress}
              teamMembers={formData.teamMembers}
              labDeparture={formData.labDeparture}
              labArrival={formData.labArrival}
              moulinArrival={
                order?.delivery?.arrived_at_start &&
                moment(order?.delivery?.arrived_at_start).format("HH:mm")
              }
              eventStart={
                order?.delivery?.start_at &&
                moment(order?.delivery?.start_at).format("HH:mm")
              }
              eventEnd={
                order?.delivery?.end_at &&
                moment(order?.delivery?.end_at).format("HH:mm")
              }
              onChange={handleChange}
            >
            </DeliveryBlock>
            <InstallationBlock
              office={formData.office}
              trash={formData.trash}
              nonWovenTablecloth={order?.delivery?.blanket}
              basin={formData.basin}
              buffetDecoration={formData.buffetDecoration}
              hasOtherMaterial={formData.hasOtherMaterial}
              rentalEquipment={formData?.rentalEquipment}
              moulinEquipment={formData?.moulinEquipment}
              onSiteEquipment={formData?.onSiteEquipment}
              onChange={handleChange}
            />
            <Row className="mb-3">
              <Col xs={6}>
                <Form.Group controlId="formExecutionRemarks">
                  <Form.Label>Déroulé</Form.Label>
                  <div className="only-web">
                    <Form.Control
                      as="textarea"
                      value={formData.executionRemarks}
                      onChange={(e) =>
                        handleChange("executionRemarks", e.target.value)
                      }
                    />
                  </div>
                  <div className="only-print">
                    <b>{formData.executionRemarks ? formData.executionRemarks : "NC"}</b>
                  </div>
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group controlId="formOtherRemarks">
                  <Form.Label>Autres</Form.Label>
                  <div className="only-web">
                    <Form.Control
                      as="textarea"
                      value={formData.otherRemarks}
                      onChange={(e) =>
                        handleChange("otherRemarks", e.target.value)
                      }
                    />
                  </div>
                  <div className="only-print">
                    <b>{formData.otherRemarks ? formData.otherRemarks : "NC"}</b>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <ProductionBlock
              hotDishes={formData.hotDishes}
              prodInfo={order?.commentary_for_prod}
              additionalProdInfo={formData.additionalProdInfo}
              extraMeals={formData.extraMeals}
              deliveryNumber={order?.numbering}
              onChange={handleChange}
            />
            <h6 className="text-center my-3" id="Photos">PHOTOS</h6>
            <Card className="mt-2">
              <Card.Body>
                <Row className="align-items-center" noGutters>
                  {medias.map((mediaWithTitle, index) => (
                    <Col xs={12} key={index} className="position-relative">
                      <img src={`${apiUrl}/${mediaWithTitle.media.media_uris.normal}`} alt={`Photo ${index + 1}`} style={{ maxWidth: "50%" }} />
                      <p>{mediaWithTitle.title}</p>
                      <Button
                        variant="link"
                        className="position-absolute only-web"
                        style={{ top: 0, right: 0 }}
                        onClick={() => handleDeleteMedia(mediaWithTitle.media.id)}
                        disabled={isDeleting}
                      >
                        {isDeleting ? (
                          <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                        ) : (
                          <FontAwesomeIcon icon={faTimesCircle} color="red" size="2x" />
                        )}
                      </Button>
                      <hr/>
                    </Col>
                  ))}
                  <Col xs={12} className="text-center only-web">
                    <Button variant="outline-primary" onClick={handleAddMediaShow}>
                      Ajouter une photo
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {order?.delivery && 
              <AddMedia
                deliveryId={order.delivery.id}
                isOpen={showAddMediaModal}
                handleClose={handleAddMediaClose}
                handleAddMedia={handleAddMedia}
              />
            }
            <Row className="mb-3 only-web sticky-button">
              <Col className="text-right">
                <Button variant="primary" size="lg" onClick={handleSave} block>
                  Sauvegarder
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </Layout>
  );
};

export default PrestationRoadSheet;
