import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { css } from "emotion";

import moment from "moment";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { useFilters } from "app/deliveryTool/context/FiltersProvider";
import DeliveryRecoveryContainer from "app/deliveryTool/containers/DeliveryRecoveryContainer";
import CreateRoadmapInput from "app/deliveryTool/pages/itineraire/CreateRoadmapInput";

const DetailRoadMap = (props) => {
  const { roadmap, dragDisabled, recoveryReallocation, handleChangeDeliveryMan, handleChangeRoadmapStartHour } = props;
  const filters = useFilters();

  const styles = {
    title: {
      color: "#484b4c",
      fontSize: "1.5rem",
      margin: "1.5rem 0 0rem 0",
      fontWeight: "normal",
    },
    subtitle: {
      fontSize: "1.2rem",
      margin: "0rem 0 0rem 0",
      fontWeight: "normal",
    },
    back: {
      color: "#429853",
      fontSize: "1.0rem",
      cursor: "pointer",
    },
    center: {
      textAlign: "center",
    },
    block: {
      backgroundColor: "white",
      margin: "1.0rem 0.5rem",
      padding: "0.5rem",
    },
    statut: {
      textTransform: "uppercase",
      fontSize: "0.6rem",
      marginBottom: "0rem",
    },
    para: {
      marginBottom: "0rem",
      fontSize: "0.8rem",
    },
    input: {},
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#429853" : "",
    border: isDraggingOver ? "1px solid #429853" : "1px solid #dedede",
    padding: "0px 5px",
    width: "100%",
    minHeight: "150px",
  });

  const [startHour, setStartHour] = useState(
    roadmap && roadmap.manual_start_hour
      ? moment(roadmap.manual_start_hour).format("HH:mm")
      : roadmap && roadmap.start_hour
      ? moment(roadmap.start_hour).format("HH:mm")
      : ""
  );

  const handleTimeChange = (event) => {
    setStartHour(event.target.value);
  };

  const handleTimeBlur = () => {
    handleChangeRoadmapStartHour(roadmap.id, startHour, true);
  };

  /**
   * 02/23 - Permet de changer le livreur d'une tournée
   */
  const updateDeliveryMan = async (event) => {
    const deliveryManId = event.target.value;
    if (deliveryManId !== "all") {
      handleChangeDeliveryMan(roadmap.id, deliveryManId);
    }
  };

  const sortDeliveries = (deliveries) => {
    return deliveries.sort((a, b) => {
      if (a.sort < b.sort) {
        return -1;
      }
      if (a.sort > b.sort) {
        return 1;
      }
      return 0;
    });
  };

  useEffect(() => {
    if (roadmap) {
      if (roadmap.manual_start_hour && roadmap.manual_start_hour !== "") {
        setStartHour(moment(roadmap.manual_start_hour).format("HH:mm"));
      } else if (roadmap.start_hour) {
        setStartHour(moment(roadmap.start_hour).format("HH:mm"));
      }
    }
  }, [roadmap]);

  return (
    <div>
      {roadmap &&
      moment(roadmap.date).format("YYYY-MM-DD") ===
        moment(filters.date).format("YYYY-MM-DD") ? (
        <div>
          <div className={css(styles.center)}>
            <h2 className={css(styles.title)}>
              Tournée{" "}
              <b>{roadmap.name ? roadmap.name : "Pas de nom attribué"}</b> -{" "}
              {roadmap.delivery_man ? roadmap.delivery_man.firstname : "Aucun livreur (livreur supprimé ?)"}
            </h2>
            <div className={css(styles.center)}>
              <CreateRoadmapInput
                handleChange={updateDeliveryMan}
                defaultDeliveryMenId={roadmap.delivery_man ? roadmap.delivery_man.id : null}
                hideAll={true}
              />
            </div>

            <Link
              to={{
                pathname: `/itineraires`,
                search: window.location.search,
              }}
              className={css(styles.back)}
            >
              Retour aux tournées
            </Link>

            <div className={css(styles.block)}>
              Départ à{" "}
              <b>
                <input 
                  type="time" 
                  value={startHour} 
                  onChange={handleTimeChange} 
                  onBlur={handleTimeBlur}
                />
              </b>{" "}
              | Trajet de <b>{roadmap.distance / 1000}</b> km
            </div>
          </div>
          <Droppable droppableId={roadmap.id}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {sortDeliveries(roadmap.deliveries).map((rmDelivery, rmDeliveryIndex) => (
                  <Draggable
                    isDragDisabled={dragDisabled}
                    key={
                      rmDelivery.recovery
                        ? rmDelivery.delivery.id + "001"
                        : rmDelivery.delivery.id
                    }
                    draggableId={
                      rmDelivery.recovery
                        ? rmDelivery.delivery.id + "001"
                        : rmDelivery.delivery.id
                    }
                    index={rmDeliveryIndex}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={css({ opacity: dragDisabled ? "0.5" : "1" })}
                      >
                        {rmDelivery.recovery ? (
                          <DeliveryRecoveryContainer
                            roadmapName={roadmap.name}
                            sort={rmDelivery.sort}
                            type="recovery"
                            delivery={rmDelivery.delivery}
                            colorDeliveryMan={roadmap.delivery_man ? roadmap.delivery_man.color : 'green'}
                            roadmapId={roadmap.id}
                            theme="light"
                            recoveryReallocation={() => {
                              return recoveryReallocation(rmDeliveryIndex);
                            }}
                          />
                        ) : (
                          <DeliveryRecoveryContainer
                            type="delivery"
                            delivery={rmDelivery.delivery}
                            sort={rmDelivery.sort}
                            roadmapName={roadmap.name}
                            colorDeliveryMan={roadmap.delivery_man ? roadmap.delivery_man.color : 'green'}
                            roadmapId={roadmap.id}
                            theme="light"
                            recoveryReallocation={() => {
                              return recoveryReallocation(rmDeliveryIndex);
                            }}
                          />
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      ) : (
        <div className={css(styles.center)}>
          <h2 className={css(styles.title)}>
            Cette tournée n'existe pas pour ces dates/livreurs.
          </h2>
          <Link
            to={{
              pathname: `/itineraires`,
              search: window.location.search,
            }}
            className={css(styles.back)}
          >
            Retour aux tournées
          </Link>
        </div>
      )}
    </div>
  );
};

DetailRoadMap.propTypes = {
  roadmap: PropTypes.object,
  dragDisabled: PropTypes.bool,
};

export default DetailRoadMap;