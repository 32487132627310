import React, {useEffect}  from 'react'
import {Col, Row} from 'react-bootstrap'
import { css } from 'emotion'
import moment from 'moment'

import { useRoadmaps } from 'app/deliveryTool/context/RoadmapsProvider'
import { useFilters } from 'app/deliveryTool/context/FiltersProvider'

import FakeDelivery from 'app/deliveryTool/components/delivery/FakeDelivery'
import RoadMapDeliveryMan from 'app/deliveryTool/pages/printPlanning/RoadMapDeliveryMan'
import DirectionsMap from "app/universal/map/DirectionsMap";

const RoadMaps = (props) => {

  const rmData  = useRoadmaps();
  const filters = useFilters();

  const {
    roadmaps,
    loading
  } = rmData

  // Quand la date change, on revient à la liste
  useEffect(() => {

    props.router.push({
      pathname: 'impression-planning',
      search: window.location.search,
    });

  }, [filters.date, props.router])

  return (
    <div>
      {loading ?
         <FakeDelivery />
      :
        <div>
          {roadmaps.map((roadmap, index) =>
            roadmap.deliveries.length > 0 &&
            <div key={'r'+roadmap.id}>
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <h2 className={css(styles.title)}>-</h2>
                  {roadmap.deliveries.length > 0 &&
                    <div>
                      <h2 className={css(styles.title)}>
                        Tournée {roadmap.name} - {roadmap.delivery_man ? roadmap.delivery_man.firstname : ''} -
                        {moment(roadmap.date).format('DD/MM/YYYY')}
                      </h2>
                      <h3 className={css(styles.info)}>Distance à parcourir : {roadmap.distance ? roadmap.distance / 1000 : 'NC'} km</h3>
                      {roadmap && <DirectionsMap roadmap={roadmap} isLoading={loading} forPrint={true}/>}
                    </div>
                  }
                  <div>
                    <RoadMapDeliveryMan roadmap={roadmap} key={index} />
                  </div>
                </Col>
              </Row>
              {roadmaps.length != index +1 && <div className="break-page" ></div>}
            </div>
          )}
        </div>
      }
    </div>
  )

}

const styles = {
  title: {
    color: '#484b4c',
    fontSize: '1.2rem',
    margin: '1rem 0 0.5rem 0',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
    '@media print': {
      fontSize: '2.0rem',
    }
  },
  info: {
    color: '#484b4c',
    fontSize: '0.8rem',
    '@media print': {
      fontSize: '1.8rem',
    },
    margin: '0rem 0 0.5rem 0',
    textAlign: 'center',
  },
  sub: {
    marginBottom: '0px',
    textAlign: 'center',
  }
}

export default RoadMaps
