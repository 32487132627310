import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Modal, Button, Alert } from "react-bootstrap";
import { addMedia } from "state/roadmapsCompanion/actions";
import useCompanion from "app/companion/useCompanion";

const AddMedia = (props) => {
  const dispatch = useDispatch();

  const { deliveryId, isOpen, handleClose, handleAddMedia } = props;
  const { isLoading: isCompanionLoading } = useCompanion();
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    // FormData pour envoyer des fichiers
    const formData = new FormData();
    formData.append("title", title);
    files.forEach((file, index) => {
      formData.append(`medias[${index}]`, file);
    });
    
    await dispatch(addMedia(deliveryId, formData)).then((resp) => {
      if (
        (resp?.response?.data?.success !== undefined && resp.response.data.success === false)
        ||
        (resp.success !== undefined && resp.success === false)
      ) {
        let errorMessage = "Une erreur s'est produite lors de l'ajout du média.";
        if (resp?.response?.data?.message) {
          errorMessage = resp.response.data.message;
        }
        else if (resp?.message) {
          errorMessage = resp.message;
        }
        setError(errorMessage);
      } else {
        setError(null);
        handleClose();
        setFiles([]);
        handleAddMedia(resp?.medias_with_titles || []);
      }
    });
  };

  const handleFileChange = (event) => {
    const maxSizeMb = 20;
    const maxTotalMb = 21;
    const maxSizePerFile = maxSizeMb * 1024 * 1024; // 20MB
    const maxTotalSize = maxTotalMb * 1024 * 1024; // 21MB
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const selectedFiles = Array.from(event.target.files);
    const validFiles = [];
    let totalSize = 0;

    selectedFiles.forEach((file) => {
      if (file.size > maxSizePerFile) {
        setError(`Le fichier ${file.name} dépasse la taille maximale autorisée de ${maxSizeMb}MB.`);
      } else if (!allowedTypes.includes(file.type)) {
        setError(`Le type de fichier ${file.type} n'est pas autorisé.`);
      } else {
        totalSize += file.size;
        if (totalSize > maxTotalSize) {
          setError(`La taille totale des fichiers dépasse la limite autorisée de ${maxTotalMb}MB.`);
        } else {
          validFiles.push(file);
        }
      }
    });

    setFiles(validFiles);
  };

  return (
    <Modal show={isOpen} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Ajout de média</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isCompanionLoading ? 
          <p>Chargement...</p>
        :
          <Form onSubmit={handleSubmit}>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form.Group controlId="formTitle">
              <Form.Label>Titre</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formFile">
              <Form.Label>Fichiers</Form.Label>
              <Form.Control
                type="file"
                multiple
                onChange={handleFileChange}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Soumettre
            </Button>
          </Form>
      }
      </Modal.Body>
    </Modal>
  );
};

export default AddMedia;