import React from 'react'
import { css } from 'emotion'
import {Button} from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import {
  initialize
} from "state/adminData/actions";
import {
  resetOrderEdition
} from "state/orderEdition/actions";
import {
  resetTenderCatalog
} from "state/tender/actions";
import { useHistory } from 'react-router-dom';

import FiltersContainer from 'app/orderTool/components/sidebar/filters/FiltersContainer'

const OrderSidebar = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const handleNewMultiOrders = () => {
    dispatch(resetOrderEdition());
    dispatch(resetTenderCatalog());
    dispatch(initialize());
  
    // Supprimer les paramètres order_id et order_type de l'URL
    const location = history.location;
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('order_id');
    queryParams.delete('order_type');
  
    history.push({
      pathname: "/commande",
      search: queryParams.toString()
    });
  };

  return (
    <div>
      <div className="p-2">
        <Button variant="dark" onClick={handleNewMultiOrders} block className={css(style.center)}>Créer un devis</Button>
      </div>
      <FiltersContainer />
    </div>
  )

}

const style = {
  center:{
    textAlign:'center'
  },
  buttonQuotation:{
    margin: '1.0rem',
    padding: '0.5rem 3rem',
    borderRadius: '0',
    fontSize: '0.8rem',
    backgroundColor: '#439b5c !important',
    border: 'none',
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  buttonCard:{
    margin: '0.5rem 1rem',
    padding: '0.5rem 3rem',
    borderRadius: '0',
    fontSize: '0.8rem',
    backgroundColor: '#323232 !important',
    border: 'none',
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  para:{
    color: '#c3c3c3',
    textAlign: 'center',
    fontSize: '0.8rem',
    marginBottom: '0'
  }
}

export default OrderSidebar
