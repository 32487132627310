import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Layout from "app/companion/components/layout/Layout";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import FakeDelivery from "app/deliveryTool/components/delivery/FakeDelivery";
import { useHistory, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { css } from "emotion";
import Roadmap from "./Roadmap";
import Shortcut from "./Shortcut";
import useConnectedUser from "app/universal/hook/useConnectedUser";
import useCompanion from "app/companion/useCompanion";
import {
  setCurrentDeliveryMan
} from "state/roadmapsCompanion/actions";
import {Helmet} from "react-helmet";

const Roadmaps = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  registerLocale("fr", fr);
  setDefaultLocale("fr");

  const { connectedUser } = useConnectedUser();
  const { roadmaps, deliveryMen, currentDeliveryMan, isCompanionLoading } = useCompanion();

  const [date, setDate] = useState(() => {
    const params = new URLSearchParams(location.search);
    const dateParam = params.get("date");
    return dateParam ? new Date(dateParam) : new Date();
  });

  // Initialisation de l'id du livreur
  const [deliveryManId, setDeliveryManId] = useState(() => {
    const params = new URLSearchParams(location.search);
    const deliveryManIdParam = params.get("deliveryManId");
    return deliveryManIdParam ? parseInt(deliveryManIdParam) : null;
  });

  // Gestion du changement de livreur
  const handleChangeDeliveryMen = (id) => {
    setDeliveryManId(id);
  };

  const handleDateChange = (date) => {
    setDate(date);
  };

  useEffect(() => {
    if (date) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      const params = new URLSearchParams(location.search);
      params.set("date", formattedDate);
      history.push(`?${params.toString()}`);
    }
  }, [date]);

  useEffect(() => {
    if (deliveryManId && deliveryMen.length > 0) {
      const deliveryMan = deliveryMen.find((dm) => parseInt(dm.id) === parseInt(deliveryManId));
      // Met à jour l'URL
      if (deliveryMan) {
        dispatch(setCurrentDeliveryMan(deliveryMan ? deliveryMan : null));
        const params = new URLSearchParams(location.search);
        params.set("deliveryManId", deliveryMan.id);
        history.push(`?${params.toString()}`);
      }
    }
  }
  , [deliveryManId]);

  return (
    <Layout isCompanionLoading={isCompanionLoading}>
      <Helmet>
        <title>Companion livreur</title>
      </Helmet>
      <Container fluid={true}>
        <Row noGutters>
          <Col xs={12} sm={12}>
            {connectedUser.firstname ? 
              <h5 className="text-center mt-4">
                <b>TOURNÉES DE {connectedUser.firstname.toUpperCase()}</b>
              </h5>
              :
              currentDeliveryMan ?
                <h5 className="text-center mt-4">
                  <b>TOURNÉES DE { currentDeliveryMan.firstname.toUpperCase()}</b>
                </h5>
              :
              <h5 className="text-center mt-4">
                <b>CHOISIR UNE TOURNÉE</b>
              </h5>
            }
            {(connectedUser.roles.includes("ROLE_DEV") || 
            connectedUser.roles.includes("ROLE_ADMIN_PRO")) && (
              <Form.Group controlId="selectDeliverer">
                <Form.Control
                  as="select"
                  value={deliveryManId || ""}
                  onChange={(e) => handleChangeDeliveryMen(e.target.value)}
                >
                  <option value="">Sélectionner un livreur</option>
                  {deliveryMen.map((dm, index) =>
                    <option value={dm.id} key={dm.id}>{dm.firstname}</option>
                  )}
                </Form.Control>
              </Form.Group>
            )}
            <div className="customDatePickerWidth">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                onChange={handleDateChange}
                selected={date}
                className={css(styles.date)}
                disabledKeyboardNavigation
                onFocus={(e) => (e.target.readOnly = true)}
                locale="fr"
                calendarStartDay={1}
              />
            </div>
            {roadmaps.isLoading ? (
              <FakeDelivery />
            ) : roadmaps.data.length === 0 ? (
              <Card className="mt-2">
                <Card.Body>Aucune tournée de disponible.</Card.Body>
              </Card>
            ) : (
              <>
                {roadmaps.data.length > 1 &&
                  <Shortcut roadmaps={roadmaps.data} />
                }
                <div id="listingRoadmaps">
                  {roadmaps.data.map((roadmap, index) => (
                    <Roadmap key={index} roadmap={roadmap} />
                  ))}
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

const styles = {
  date: {
    background: "white",
    width: "100%",
    border: "thin solid #ddd",
    color: "#555",
    padding: "0.5rem 0.5rem",
  },
};

export default Roadmaps;