import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { getConnectedUserInfo } from "state/common/actions";
import Unauthorized from "./Unauthorized";

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const dispatch = useDispatch();

  const [accessToken, setAccessToken] = useState(
    () => window.localStorage.getItem("token") || ""
  );

  const connectedUser = useSelector((state) => state.common.user.data);
  const userRoles = connectedUser?.roles || [];
  const isLoading = useSelector((state) => state.common.user.isLoading);
  const isInitialized = useSelector((state) => state.common.user.isInitialized);

  useEffect(() => {
    if (accessToken && !connectedUser?.id && !isLoading && !isInitialized) {
      dispatch(getConnectedUserInfo());
    }
  }, [accessToken, isLoading, connectedUser, isInitialized, dispatch]);

  const canAccess = () => {
    const hasCompanionAccess =
      roles.includes("companion") &&
      (userRoles.includes("ROLE_PRO_COMPANION_USER") ||
      userRoles.includes("ROLE_ADMIN_PRO"));
    const hasAdminAccess =
      roles.includes("admin") &&
      (userRoles.includes("ROLE_ADMIN") ||
        userRoles.includes("ROLE_SUPER_ADMIN") ||
        userRoles.includes("ROLE_DEV") ||
        userRoles.includes("ROLE_ADMIN_PRO")
      );
    const hasDevAccess = userRoles.includes("ROLE_DEV");

    if (hasCompanionAccess || hasAdminAccess || hasDevAccess) {
      return { access: true, path: null };
    } else {
      return { access: false, path: null };
    }
  };

  const accessInfo = canAccess();

  return (
    <Route
      {...rest}
      render={(props) =>
        accessToken ? (
          accessInfo.access ? (
            <Component {...props} />
          ) : (
            <Unauthorized />
          )
        ) : (
          <Unauthorized />
        )
      }
    />
  );
};

export default PrivateRoute;