import * as Yup from 'yup';
import moment from 'moment';

Yup.setLocale({
  mixed: {
    required: '${path} est obligatoire',
    notType: '${path} est manquant ou invalide dans son format'
  },
  string: {
    email: '${path} doit être un email valide',
  },
  date: {
    required: '${path} doit être une date',
  },
});

Yup.addMethod(Yup.string, 'dateString', function () {
  return this.test('is-date', 'Format de date invalide', value => {
    const isValid = moment(value, 'DD/MM/YYYY', true).isValid();
    return isValid ? true : new Error('Format de date invalide');
  });
});

const deliveryInfoSchema = Yup.object().shape({
  company: Yup.string().nullable(),
  firstname: Yup.string().required().label('Prénom (livraison)'),
  lastname: Yup.string().required().label('Nom (livraison)'),
  phone: Yup.string().required().label('Téléphone (livraison)'),
  phoneSecondary: Yup.string().nullable(),
  deliveryDate: Yup.string()
  .required()
  .label('Date de livraison')
  .dateString(),
  arrivedAtStart: Yup.string().nullable(),
  arrivedAtEnd: Yup.string().nullable(),
  startAt: Yup.string().nullable(),
  endAt: Yup.string().nullable(),
  deliveryInformation: Yup.string().nullable(),
  recoverInformation: Yup.string().nullable(),
  addressComplement: Yup.string().nullable(),
  streetNumber: Yup.string().required().label('Numéro de rue'),
  street: Yup.string().required().label('Rue'),
  postalCode: Yup.string().required().label('Code postal'),
  city: Yup.string().required().label('Ville'),
  country: Yup.string().nullable(),
  cost: Yup.number().nullable(),
  person: Yup.string().required().label('Nombre de personnes'),
});

const billingInfoSchema = Yup.object().shape({
  lastname: Yup.string().required().label('Nom (facturation)'),
  firstname: Yup.string().required().label('Prénom (facturation)'),
  phone: Yup.string().required().label('Téléphone (facturation)'),
  moreInformation: Yup.string().nullable(),
  address: Yup.string().required().label('Adresse (facturation)'),
  company: Yup.string().required().label('Entreprise (facturation)'),
});

const priceInfoSchema = Yup.object().shape({
  totalPriceHt: Yup.number().required(),
  totalPriceHtWithoutPromotion: Yup.number().required(),
});

let articleSchema = Yup.object().shape({
  quantity: Yup.number().required().label('Quantité'),
  price: Yup.number().nullable(),
  group: Yup.string(),
  package: Yup.string(),
  product: Yup.string(),
  productPart: Yup.string(),
  id: Yup.number().nullable(),
  name: Yup.string().nullable()
}).test('group-or-product-or-package-or-productPart', 'Un groupe, forfait ou un produit doit être indiqué', function (value) {
  const { group, product, productPart, name } = value;
  const productPackage = value?.package;
  if (!group && !product && !productPackage && !productPart) {
    return this.createError({
      path: 'group',
      message: `L'élément ${name} est mal configuré. Merci de le supprimer ou de le modifier. (Soucis probablement du à une suppression de l'élément sur l'admin)`,
    });
  }
  return true;
})

const PromotionApiSchema = Yup.object().shape({
  name: Yup.string().required().label('Libellé de la réduction'),
  amount: Yup.number().nullable(),
  percent: Yup.number().nullable()
}).test('amount-or-percent', 'Merci de renseigner un montant ou un pourcentage', function (value) {
  return value.amount !== null || value.percent !== null;
});

const OrderApiSchema = Yup.object().shape({
  cart: Yup.object().shape({
    customerInfo: Yup.object().shape({}).required(),
    deliveryInfo: deliveryInfoSchema.required(),
    billingInfo: billingInfoSchema.required(),
    priceInfo: priceInfoSchema.required(''),
    articles: Yup.array().of(articleSchema).required('Merci de renseigner les produits'),
    customer: Yup.number().required(),
    promotions: Yup.array().of(PromotionApiSchema).nullable(),
  }),
  format: Yup.string().required('Merci de renseigner le format de la prestation'),
  status: Yup.string().required('Merci de renseigner le statut de la commande'),
  packagingType: Yup.string().nullable(),
});

export default OrderApiSchema;
