import React from "react";
import { Form, Row, Col } from "react-bootstrap";

const DeliveryBlock = ({
    address,
    contact,
    additionalContact,
    spotInfo,
    additionalSpotInfo,
    parkingAddress,
    teamMembers,
    labDeparture,
    labArrival,
    moulinArrival,
    eventStart,
    eventEnd,
    onChange,
    addressComplement,
    phone
}) => (
  <div>
    <Row
      style={{
        backgroundColor: "rgba(255, 255, 255, 1)",
        border: "1px solid lightgray",
        borderRadius: "10px",
        margin: "10px 0",
      }}
    >
      <Col md="12">
        <Form.Group controlId="formDeliveryAddress">
          <Form.Label>Adresse livraison</Form.Label>
          <br/><b>{address}</b>
          <br/><b>{addressComplement}</b>
        </Form.Group>
      </Col>
      <Col xs={6} md="6">
        <Form.Group controlId="formContact">
          <Form.Label>Contact sur place</Form.Label>
          <br/><b>{contact ? contact : "NC"}</b>
          <br/><b>{phone ? phone : "NC"}</b>
        </Form.Group>
      </Col>
      <Col xs={6} md="6">
        <Form.Group controlId="formAdditionalContact">
          <Form.Label>Contact sur place (+d'information)</Form.Label>
          <div className="only-web">
            <Form.Control
              type="text"
              value={additionalContact}
              onChange={(e) => onChange("additionalContact", e.target.value)}
            />
          </div>
          <div className="only-print">
            <b>{additionalContact ? additionalContact : "NC"}</b>
          </div>
        </Form.Group>
      </Col>
      <Col xs={6} md="6">
        <Form.Group controlId="formSpotInfo">
          <Form.Label>Informations sur le lieu</Form.Label>
          <br/><b>{spotInfo ? spotInfo : "NC"}</b>
        </Form.Group>
      </Col>
      <Col xs={6} md="6">
        <Form.Group controlId="formAdditionalSpotInfo">
          <Form.Label>Informations sur le lieu (+d'information)</Form.Label>
          <div className="only-web">
            <Form.Control
              as="textarea"
              value={additionalSpotInfo}
              onChange={(e) => onChange("additionalSpotInfo", e.target.value)}
            />
          </div>
          <div className="only-print">
            <b>{additionalSpotInfo ? additionalSpotInfo : "NC"}</b>
          </div>
        </Form.Group>
      </Col>
      <Col md="12">
        <Form.Group controlId="formParkingAddress">
          <Form.Label>Adresse de stationnement</Form.Label>
          <div className="only-web">
            <Form.Control
              as="textarea"
              value={parkingAddress}
              onChange={(e) => onChange("parkingAddress", e.target.value)}
            />
          </div>
          <div className="only-print">
            <b>{parkingAddress}</b>
          </div>
        </Form.Group>
      </Col>
      <Col xs={3} md="6">
        <Form.Group controlId="formLabArrival">
          <Form.Label>Arrivée au labo</Form.Label>
          <div className="only-web">
            <Form.Control
              type="time"
              value={labArrival}
              onChange={(e) => onChange("labArrival", e.target.value)}
            />
          </div>
          <div className="only-print">
            <b>{labArrival}</b>
          </div>
        </Form.Group>
      </Col>
      <Col xs={3} md="6">
        <Form.Group controlId="formLabDeparture">
          <Form.Label>Départ du labo</Form.Label>
          <div className="only-web">
            <Form.Control
              type="time"
              value={labDeparture}
              onChange={(e) => onChange("labDeparture", e.target.value)}
            />
          </div>
          <div className="only-print">
            <b>{labDeparture}</b>
          </div>
        </Form.Group>
      </Col>
      <Col xs={3}  md="6">
        <Form.Group controlId="formMoulinArrival">
          <Form.Label>Arrivée des équipes</Form.Label>
          <br/><b>{moulinArrival}</b>
        </Form.Group>
      </Col>
      <Col xs={3} md="6">
        <Form.Group controlId="formEventStart">
          <Form.Label>Début de l'événement</Form.Label>
          <br/><b>{eventStart}</b>
        </Form.Group>
      </Col>
      <Col xs={12} md="12">
        <Form.Group controlId="formEventEnd">
          <Form.Label>Fin de l'événement / Horaire récup</Form.Label>
          <br/><b>{eventEnd}</b>
        </Form.Group>
      </Col>
    </Row>
  </div>
);

export default DeliveryBlock;