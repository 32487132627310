export const FETCH_ORDER = "FETCH_ORDER";
export const FETCH_ORDER_COMPLETED = "FETCH_ORDER_COMPLETED";
export const FETCH_ORDER_FAILED = "FETCH_ORDER_FAILED";
export const UPDATE_ROAD_SHEET = "UPDATE_ROAD_SHEET";
export const UPDATE_ROAD_SHEET_COMPLETED = "UPDATE_ROAD_SHEET_COMPLETED";
export const UPDATE_ROAD_SHEET_FAILED = "UPDATE_ROAD_SHEET_FAILED";

export const fetchOrder = (orderId, orderType) => ({
  type: FETCH_ORDER,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/orders/byordertype/${orderType}/${orderId}.json`,
    method: "GET"
  },
});

export const updateRoadSheet = (orderId, data) => ({
  type: "UPDATE_ROAD_SHEET",
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/orders/${orderId}/update_road_sheet.json`,
    method: "PUT",
    body: data,
  },
});