import React from 'react'
import ReactDOM from 'react-dom'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import PrivateRoute from 'app/universal/route/PrivateRoute'

import MissingInformations from 'app/orderTool/pages/missingInformations/MissingInformations';
import ManageOrders from 'app/orderTool/pages/manageOrders/ManageOrders';
import Agenda from 'app/deliveryTool/pages/agenda/Agenda.js';
import Planning from 'app/deliveryTool/pages/planning/Planning';
import Itineraires from 'app/deliveryTool/pages/itineraire/Itineraires';
import PrintPlanning from 'app/deliveryTool/pages/printPlanning/PrintPlanning';
import PublicPlanning from 'app/deliveryTool/pages/publicPlanning/PublicPlanning';
import CardProdMultipole from 'app/orderTool/pages/cardProdMultipole/CardProdMultipole';
import CardProdMultipoleV2 from 'app/orderTool/pages/cardProdMultipoleV2/CardProdMultipoleV2';
import Anticipation from 'app/orderTool/pages/anticipation/Anticipation';
import MultiOrders from 'app/orderTool/pages/multiOrders/MultiOrders';
import MultiFormats from 'app/orderTool/pages/multiOrders/MultiFormats';
import TenderCatalogItemsManager from 'app/orderTool/pages/tenderCatalog/TenderCatalogItemsManager';
import TenderCatalogList from 'app/orderTool/pages/tenderCatalog/TenderCatalogList';
import TenderCatalogRangeManager from 'app/orderTool/pages/tenderCatalog/TenderCatalogRangeManager';
import PrestationRoadSheet from 'app/orderTool/pages/prestationRoadSheet/PrestationRoadSheet';
import NotFound404 from 'app/universal/404/NotFound404';

// Companion livreur
import Roadmaps from 'app/companion/pages/roadmaps/Roadmaps.js';
import DeliverySheet from 'app/companion/pages/delivery/detail/DeliverySheet.js';
import DeliveryMaterial from 'app/companion/pages/delivery/materials/DeliveryMaterial.js';
import DeliveryComment from 'app/companion/pages/delivery/comment/DeliveryComment.js';

// Fin companion livreur

 // eslint-disable-next-line
import Global from 'app/universal/styles/Global';
import * as serviceWorker from './serviceWorker';
import WindowDimensionsProvider from 'app/universal/utils/WindowDimensionsProvider'
import ApiAuthProvider from 'app/universal/context/ApiAuthProvider'
import ErrorBoundary from 'app/universal/errors/ErrorBoundary'
import { Provider } from 'react-redux';
import { store, persistor } from 'state/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { register as registerServiceWorker } from './serviceWorker';
import swal from 'sweetalert';

// Reinitialisation du store au déploiement
const deployVer = process.env.REACT_APP_VERSION;
const curVersion = localStorage.getItem('version');
if(curVersion === 'undefined' || curVersion === null || curVersion !== deployVer){
  localStorage.setItem('version', deployVer);
  persistStore(store).purge();
  document.location.reload();
}

console.log('curVersion', curVersion, 'deployVer', deployVer);

const routing = (
  <WindowDimensionsProvider>
    <ErrorBoundary>
      <Provider store={store}>
        <ApiAuthProvider>
          <Router>
            <Switch>
              <PrivateRoute exact path="/informations-devis-manquantes" component={MissingInformations} roles={['admin']} />
              <PrivateRoute path="/planning" component={Planning} roles={['admin']} />
              <PrivateRoute exact path="/itineraires/" component={Itineraires} roles={['admin']} />
              <PrivateRoute path="/itineraires/:id" component={Itineraires} roles={['admin']} />
              <PrivateRoute path="/impression-planning" component={PrintPlanning} roles={['admin']} />
              <Route path="/impression-planning-public" component={PublicPlanning} roles={['admin']}/>
              <PrivateRoute path="/agenda" component={Agenda} roles={['admin']} />
              <PrivateRoute path="/fiche-production-multipole" component={CardProdMultipole} roles={['admin']} />
              <PrivateRoute path="/fiche-production-multipole-v2" component={CardProdMultipoleV2} roles={['admin']} />
              <PrivateRoute path="/anticipation" component={Anticipation} roles={['admin']} />
              <PrivateRoute exact path="/" component={ManageOrders} roles={['admin']}/>
              <PersistGate loading={null} persistor={persistor}>
                <PrivateRoute exact path="/informations-devis-manquantes" component={MissingInformations} roles={['admin']} />
                <PrivateRoute path="/commande" component={MultiOrders} roles={['admin']} />
                <PrivateRoute path="/dej_multi_format" component={MultiFormats} roles={['admin']} />
                <PrivateRoute path="/appel_offre" component={TenderCatalogList} roles={['admin']} />
                <PrivateRoute path="/appel_offre_prix/:tenderId/prix" component={TenderCatalogItemsManager} roles={['admin']} />
                <PrivateRoute path="/appel_offre_prix/:tenderId/palier" component={TenderCatalogRangeManager} roles={['admin']} />
                <PrivateRoute path="/feuille-de-route/:orderId" component={PrestationRoadSheet} roles={['admin']} />
                {/* Companion livreur */}
                <PrivateRoute exact path="/companion" component={Roadmaps} roles={['companion']} />
                <PrivateRoute path="/companion/tournee/:roadmapId/:deliveryId/detail" component={DeliverySheet} roles={['companion']} />
                <PrivateRoute path="/companion/tournee/:roadmapId/:deliveryId/materiel" component={DeliveryMaterial} roles={['companion']} />
                <PrivateRoute path="/companion/tournee/:roadmapId/:deliveryId/commentaire" component={DeliveryComment} roles={['companion']} />
              </PersistGate>
              <Route component={NotFound404} />
            </Switch>
          </Router>
        </ApiAuthProvider>
      </Provider>
    </ErrorBoundary>
  </WindowDimensionsProvider>
)

ReactDOM.render(routing, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module.hot) {
  module.hot.accept();
}