import {
  RESET_ORDER_EDITION,
  INITIALIZE_ORDER_EDITION,
  SET_ORDER_EDITION_INFO,
  SET_DELIVERY_INFO,
  SET_BILLING_INFO,
  SET_CUSTOMER,
  SET_COMPANY,
  SET_TENDER,
  SET_CONFIRM_TENDER,
  SET_FORMAT,
  UPDATE_ORDER_ITEMS,
  ADD_ORDER_ITEM,
  REMOVE_ORDER_ITEM,
  UPDATE_ORDER_ITEM,
  UPDATE_REDUCTION,
  UPDATE_ORDER_DELIVERY_COST,
  ADD_ORDER,
  SET_CURRENT_ORDER_INDEX,
  REMOVE_ORDER,
  SET_ORDER_STATUS,
  SET_GROUPED_ORDER_STATUS,
  SET_ORDER_DELIVERY_INFO,
  FETCH_BREAKFAST_ORDER,
  FETCH_BREAKFAST_ORDER_COMPLETED,
  FETCH_BREAKFAST_ORDER_FAILED,
  FETCH_GROUPED_ORDER,
  FETCH_GROUPED_ORDER_COMPLETED,
  FETCH_GROUPED_ORDER_FAILED,
  SAVE_ORDER,
  SAVE_ORDER_COMPLETED,
  SAVE_ORDER_FAILED,
  SAVE_BREAKFAST_ORDER,
  SAVE_BREAKFAST_ORDER_COMPLETED,
  SAVE_BREAKFAST_ORDER_FAILED,
  SET_ORDER_INFO,
  UPDATE_ORDER_PRICE_INFO,
  GET_CLONE_GROUPED_ORDER,
  GET_CLONE_GROUPED_ORDER_COMPLETED,
  GET_CLONE_GROUPED_ORDER_FAILED,
  DUPLICATE_ORDER
} from "./actions";
import { breakfastOrderApiToStore, orderStateToTotal, groupedOrderApiToStore } from "helper/transformDataHelper";

const initialState = {
  deliveryInfo: {
    firstname: "",
    lastname: "",
    streetNumber: "",
    street: "",
    postalCode: "",
    city: "",
    phone: "",
    phoneSecondary: "",
    startAt: "",
    endAt: "",
    arrivedAtStart: "",
    arrivedAtEnd: "",
    person: 0,
    addressComplement: "",
  },
  billingInfo: {
    firstname: "",
    lastname: "",
    phone: "",
    addressComplement: "",
    fullAddress: "",
  },
  customer: {},
  company: {},
  tender: {},
  confirmTender: false,
  status: null,
  currentOrder: {},
  currentOrderIndex: null,
  customerSpecialCommentary: null, // Commentaire du client
  customerSpecialReference: null, // Référence client
  customerQuotationCommentary: null, // Commentaire visible devis factures
  codeServiceChorus: null, // Code Chorus
  orderFormDate: null, // Date de réception du bon de commande
  disableApproachingMail: false, // Relance en approche
  groupedOrderId: null,
  groupedOrderUpdatedAt: null,
  groupedOrderStatus: null,
  orders: [
    // {
    //   id: null,
    //   prestationType: null,
    //   format: null,
    //   blanket: false,
    //   packagingType: null,
    //   service: false,
    //   commentaryForProd: "",
    //   status: "101",
    //   deliveryInfo: {
    //     startAt: "",
    //     endAt: "",
    //     deliveryDate: "",
    //     person: "",
    //     arrivedAtStart: "",
    //     arrivedAtEnd: "",
    //     specific: false,
    //   },
    //   reduction: {
    //     label: null,
    //     direct: null,
    //     proportional: null,
    //   },
    //   priceInfo : {
    //     total_ht: null,
    //     pricingTable: {},
    //   },
    //   deliveryCost: 0,
    //   items: [],
    //   priceInfo: {
    //      totalHT: 0
    //      totalHTWithoutReduction: 0,
    //      catererPackagePiecesPriceHtPerParticipant: null,
    //      catererPackageSoftsPriceHtPerParticipant: null,
    //      catererPackageAlcoholsPriceHtPerParticipant: null,
    //   },
    //   info: {},
    //   version: 'v2',
    // articles: [
    //   {
    //     quantity: 1,
    //     price: 0,
    //     group: "",
    //     product: "",
    //     article_id: null,
    //   },
    //   {
    //     quantity: 1,
    //     price: 0,
    //     group: "",
    //     article_id: null;
    //     child: [
    //       {
    //         quantity: 1,
    //         price: 0,
    //         group: "",
    //         product: "",
    //         id: null,
    //       },
    //     ],
    //   }
    // ],
    // },
  ],
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case RESET_ORDER_EDITION:
      return initialState;
    case INITIALIZE_ORDER_EDITION:
      return {
        ...state,
        ...initialState,
      };
    case SET_ORDER_EDITION_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case SET_DELIVERY_INFO:
      return {
        ...state,
        deliveryInfo: {
          ...action.payload,
          deliveryAddressBook: action.payload.deliveryAddressBook || state.deliveryInfo.deliveryAddressBook,
        },
      };
    case SET_BILLING_INFO:
      return {
        ...state,
        billingInfo: action.payload,
      };
    case SET_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };
    case SET_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case SET_TENDER:
      return {
        ...state,
        tender: action.payload,
      };
    case SET_CONFIRM_TENDER:
      return {
        ...state,
        confirmTender: action.payload,
      };
    case SET_FORMAT:
      return {
        ...state,
        orders: state.orders.map((order, index) => {
          if (index === action.payload.orderIndex) {
            return {
              ...order,
              format: action.payload.formatTxt,
            };
          }
          return order;
        }),
      };
    case SAVE_ORDER:
      return {
        ...state,
        isLoading: true,
      };
    case SAVE_ORDER_COMPLETED:
      return {
        ...state,
        isLoading: false,
        ...action?.payload?.result?.groupedOrder?.orders.length > 0 ? groupedOrderApiToStore(action.payload.result.groupedOrder) : initialState,
        groupedOrderId: action.payload?.result?.groupedOrder?.id || null,
        groupedOrderStatus: action.payload?.result?.groupedOrder?.status || null,
        groupedOrderUpdatedAt: action.payload?.result?.groupedOrder?.last_save_from_api || null,
      }
    case SAVE_ORDER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SAVE_BREAKFAST_ORDER:
      return {
        ...state,
        isLoading: true,
      };
    case SAVE_BREAKFAST_ORDER_COMPLETED:
      return {
        ...state,
        isLoading: false
      };
    case SAVE_BREAKFAST_ORDER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_ORDER_ITEMS:
      return {
        ...state,
        orders: state.orders.map((order, index) => {

          // Ajoute items si non défini
          if (order.items === undefined) {
            order.items = [];
          }

          if (index === action.payload.orderIndex) {
            const newItems = action.payload.items;
            const itemType = action.payload.itemType;
    
            let updatedItems;
            if (itemType === 'formula') {
              // Filtrer les éléments existants pour exclure les formules
              updatedItems = order.items.filter(item => item.type !== 'formula');
              // Ajouter les nouvelles formules
              updatedItems = [...updatedItems, ...newItems.map(item => ({...item, type: itemType}))];
            } else if (itemType === 'product') {
              // Filtrer les éléments existants pour exclure les produits simples
              updatedItems = order.items.filter(item => item.type !== 'product');
              // Ajouter les nouveaux produits simples
              updatedItems = [...updatedItems, ...newItems.map(item => ({...item, type: itemType}))];
            } else if (itemType === 'productGroup') {
              // Filtrer les éléments existants pour exclure les groupes
              updatedItems = order.items.filter(item => item.type !== 'productGroup');
              // Ajouter les nouveaux groupes
              updatedItems = [...updatedItems, ...newItems.map(item => ({...item, type: itemType}))];
            } else if (itemType === 'productPackage') {
              // Filtrer les éléments existants pour exclure les groupes
              updatedItems = order.items.filter(item => item.type !== 'productPackage');
              // Ajouter les nouveaux groupes
              updatedItems = [...updatedItems, ...newItems.map(item => ({...item, type: itemType}))];
            }
            else if (!itemType) {
              updatedItems = newItems;
            }

            const {
              totalHT,
              totalHTWithoutReduction
            } = orderStateToTotal({ ...order, items: updatedItems });
    
            return {
              ...order,
              items: updatedItems,
              priceInfo: {
                ...order.priceInfo,
                totalHT: totalHT,
                totalHTWithoutReduction: totalHTWithoutReduction
              }
            };
          }
          return order;
        }),
    };
    case ADD_ORDER_ITEM: {
      const { orderIndex, item } = action.payload;
      const updatedOrders = [...state.orders];
      const updatedOrder = {
        ...updatedOrders[orderIndex],
        items: [...updatedOrders[orderIndex].items, item],
      };

      const {
        totalHT,
        totalHTWithoutReduction: totalHTWithoutReduction
      } = orderStateToTotal({ ...updatedOrder, items: updatedOrder.items });

      updatedOrders[orderIndex] = {
        ...updatedOrder,
        priceInfo: {
          ...updatedOrder.priceInfo,
          totalHT: totalHT,
          totalHTWithoutReduction: totalHTWithoutReduction
        }
      };

      return {
        ...state,
        orders: updatedOrders,
      };
    }
    case REMOVE_ORDER_ITEM: {
      const { orderIndex, itemIndex } = action.payload;
      const updatedOrders = [...state.orders];
      const updatedOrder = {
        ...updatedOrders[orderIndex],
        items: updatedOrders[orderIndex].items.filter((_, i) => i !== itemIndex),
      };

      const {
        totalHT,
        totalHTWithoutReduction
      } = orderStateToTotal({ ...updatedOrder, items: updatedOrder.items });

      updatedOrders[orderIndex] = {
        ...updatedOrder,
        priceInfo: {
          ...updatedOrder.priceInfo,
          totalHT: totalHT,
          totalHTWithoutReduction: totalHTWithoutReduction
        }
      };
      return {
        ...state,
        orders: updatedOrders,
      };
    }
    case UPDATE_ORDER_ITEM: {
      const { orderIndex, itemIndex, item } = action.payload;
      const updatedOrders = [...state.orders];
      const updatedOrder = {
        ...updatedOrders[orderIndex],
        items: updatedOrders[orderIndex].items.map((el, i) => (i === itemIndex ? item : el)),
      };

      const {
        totalHT,
        totalHTWithoutReduction
      } = orderStateToTotal({ ...updatedOrder, items: updatedOrder.items });

      updatedOrders[orderIndex] = {
        ...updatedOrder,
        priceInfo: {
          ...updatedOrder.priceInfo,
          totalHT: totalHT,
          totalHTWithoutReduction: totalHTWithoutReduction
        }
      };

      return {
        ...state,
        orders: updatedOrders,
      };
    }
    case UPDATE_REDUCTION:
      return {
        ...state,
        orders: state.orders.map((order, index) => {
          if (index === action.payload.orderIndex) {
            const newReduction = action.payload.reduction;
            const {
              totalHT,
              totalHTWithoutReduction
            } = orderStateToTotal({ ...order, reduction: newReduction });
            return {
              ...order,
              reduction: newReduction,
              priceInfo: {
                ...order.priceInfo,
                totalHT: totalHT,
                totalHTWithoutReduction: totalHTWithoutReduction
              }
            };
          }
          return order;
        }),
      };
    case UPDATE_ORDER_DELIVERY_COST:
      return {
        ...state,
        orders: state.orders.map((order, index) => {
          if (index === action.payload.orderIndex) {
            const newCost = action.payload.cost;
            const {
              totalHT,
              totalHTWithoutReduction
            } = orderStateToTotal({ ...order, deliveryCost: newCost });
            return {
              ...order,
              deliveryCost: newCost,
              priceInfo: {
                ...order.priceInfo,
                totalHT: totalHT,
                totalHTWithoutReduction: totalHTWithoutReduction
              }
            };
          }
          return order;
        }),
      };
    case ADD_ORDER:
      return {
        ...state,
        currentOrderIndex: state.orders.length - 1 < 0 ? 0 : state.orders.length,
        orders: [
          ...state.orders,
          {
            prestationType: action.payload.prestationType,
            version: 'v2',
            reduction: {
              label: null,
              direct: null,
              proportional: null,
            },
            deliveryCost: 0,
            items: [],
            priceInfo: {
              totalHT: 0,
              totalHTWithoutReduction: 0,
              catererPackagePiecesPriceHtPerParticipant: null,
              catererPackageSoftsPriceHtPerParticipant: null,
              catererPackageAlcoholsPriceHtPerParticipant: null,
            },
            deliveryInfo: {
              startAt: "",
              endAt: "",
              deliveryDate: "",
              person: "",
              arrivedAtStart: "",
              arrivedAtEnd: "",
              specific: false,
              deliveryInformation: state?.deliveryInfo?.deliveryAddressBook?.delivery_information || "",
              recoverInformation: state?.deliveryInfo?.deliveryAddressBook?.recover_information || "",
            },
          },
        ],
      };
    case SET_CURRENT_ORDER_INDEX:
      return {
        ...state,
        currentOrderIndex: action.payload,
      };
    case REMOVE_ORDER:
      const orders = state.orders.filter((order, index) => index !== action.payload);
      const newCurrentOrderIndex = action.payload - 1 >= 0 ? action.payload - 1 : orders.length > 0 ?  orders.length - 1 : null;

      return {
        ...state,
        currentOrderIndex: newCurrentOrderIndex,
        orders: orders,
      };
    case SET_ORDER_STATUS:
      return {
        ...state,
        orders: state.orders.map((order, index) => {
          if (index === action.payload.orderIndex) {
            return {
              ...order,
              status: action.payload.status,
            };
          }
          return order;
        }),
      };
    case SET_GROUPED_ORDER_STATUS:
      return {
        ...state,
        status: action.payload.status,
      };
    case SET_ORDER_DELIVERY_INFO:
      return {
        ...state,
        orders: state.orders.map((order, index) => {
          if (index === action.payload.orderIndex) {
            return {
              ...order,
              deliveryInfo: {
                ...action.payload.deliveryInfo
              },
            };
          }
          return order;
        }),
      };
    case FETCH_BREAKFAST_ORDER:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_BREAKFAST_ORDER_COMPLETED:
      return {
        ...state,
        isLoading: false,
        ...breakfastOrderApiToStore(action.payload.result),
        currentOrderIndex: 0,
      };
    case FETCH_BREAKFAST_ORDER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
      case FETCH_GROUPED_ORDER:
        return {
          ...state,
          isLoading: true,
        };
      case FETCH_GROUPED_ORDER_COMPLETED:
        let status = '101';
        if (action.payload?.result?.status) {
          status = action.payload?.result?.status;
        }
        if (status >= 400) {
          status = '401';
        }
        return {
          ...state,
          isLoading: false,
          ...action?.payload?.result?.orders.length > 0 ? groupedOrderApiToStore(action.payload.result) : initialState,
          groupedOrderId: action.payload?.result?.id || null,
          groupedOrderStatus: status,
          groupedOrderUpdatedAt: action.payload?.result?.last_save_from_api || null,
          status: status,
        };
      case FETCH_GROUPED_ORDER_FAILED:
        return {
          ...state,
          isLoading: false,
        };
    case SET_ORDER_INFO:
      return {
        ...state,
        orders: state.orders.map((order, index) => {
          if (index === action.payload.orderIndex) {
            return {
              ...order,
              ...action.payload.order,
            };
          }
          return order;
        }),
      };
    case UPDATE_ORDER_PRICE_INFO:
      return {
        ...state,
        orders: state.orders.map((order, index) => {
          if (index === action.payload.orderIndex) {
            const newPriceInfo = action.payload.priceInfo;
            const {
              totalHT,
              totalHTWithoutReduction
            } = orderStateToTotal({
              ...order,
              priceInfo: newPriceInfo,
            });
            return {
              ...order,
              priceInfo: {
                ...newPriceInfo,
                totalHT: totalHT,
                totalHTWithoutReduction: totalHTWithoutReduction
              },
            };
          }
          return order;
        }),
      };
    case GET_CLONE_GROUPED_ORDER:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CLONE_GROUPED_ORDER_COMPLETED:
      return {
        ...state,
        isLoading: false,
        ...groupedOrderApiToStore(action.payload.result.groupedOrder),
        groupedOrderId: action.payload?.result?.groupedOrder?.id || null,
        groupedOrderStatus: action.payload?.result?.groupedOrder?.status || null,
        groupedOrderUpdatedAt: action.payload?.result?.groupedOrder?.last_save_from_api || null,
      };
    case GET_CLONE_GROUPED_ORDER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case DUPLICATE_ORDER: {
      const { orderIndex } = action.payload;
      const order = state.orders[orderIndex];
      let newOrder = {
        ...order,
        id: null,
        status: "101",
      };
      // Supprime les références aux ids des articles
      newOrder.items = newOrder.items.map(item => {
        return {
          ...item,
          article_id: null,
          products: item?.products && item.products.map(product => {
            return {
              ...product,
              id: null,
            };
          }),
          product_parts: item?.product_parts && item.product_parts.map(productPart => {
            return {
              ...productPart,
              id: null,
            };
          })
        };
      });
      return {
        ...state,
        orders: [
          ...state.orders,
          newOrder,
        ],
      };
    }
    default:
      return state;
  }
};

export default reducers;
