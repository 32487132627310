import React, { useState } from "react";
import { Row, Col, Card, Button, Modal } from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import {
  faUser,
  faMapPin,
  faComment,
  faPhone,
  faCheck,
  faTimes,
  faParking,
} from "@fortawesome/free-solid-svg-icons";
import Waze from "app/universal/assets/images/waze-picto.png";
import FakeDelivery from "app/deliveryTool/components/delivery/FakeDelivery";
import { APIProvider, Map, AdvancedMarker } from "@vis.gl/react-google-maps";

// La feuille de route est spécifique pour la personne dédié aux service lors d'évenements avec service = info de livraison + info commande + info de prod & co
const RoadSheetModal = (props) => {

  const { roadmap, rmDelivery, order, isLoading, show, handleClose } = props;
  const [currentPosition, setCurrentPosition] = useState(null);
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  let wazeUrl = "";
  if (
    rmDelivery &&
    rmDelivery?.delivery?.lat &&
    rmDelivery?.delivery?.lng &&
    currentPosition
  ) {
    wazeUrl = `https://waze.com/ul?ll=${rmDelivery.delivery.lat},${rmDelivery.delivery.lng}&navigate=yes&from=${currentPosition.lat},${currentPosition.lng}`;
  } else if (
    rmDelivery &&
    rmDelivery?.delivery?.lat &&
    rmDelivery?.delivery?.lng
  ) {
    wazeUrl = `https://waze.com/ul?ll=${rmDelivery.delivery.lat},${rmDelivery.delivery.lng}&navigate=yes`;
  }

  const apiUrl = process.env.REACT_APP_API_URL;

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShowModal}
        block
        className="mb-3"
        size="lg"
      >
        Feuille de route
      </Button>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
            <Modal.Title>FEUILLE DE ROUTE</Modal.Title>
          </Modal.Header>
      <h6 className="text-center mb-2 mt-3" id="Commanditaire">
        COMMANDITAIRE
      </h6>
      {isLoading ? (
        <FakeDelivery />
      ) : (
        <div>
          <Card className="mt-2">
            <Card.Body>
              <Row className="text-center align-items-center" noGutters>
                <Col xs={3} style={{ borderRight: "1px solid #ddd" }}>
                  <small>
                    {moment(roadmap.date).format("DD/MM/YYYY")}
                    <br />
                  </small>
                </Col>
                <Col xs={6} style={{ borderRight: "1px solid #ddd" }}>
                  <small>
                    {order?.company?.name}
                    <br />
                    {order?.firstname} {order?.lastname}
                  </small>
                </Col>
                <Col xs={3}>
                  <small>{order?.prestation_type_fr}</small>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mt-2">
            <Card.Body>
              <Row className="align-items-center" noGutters>
                <Col xs={12}>
                  <b>LE BRIEF :</b>
                  <br />
                  <small>
                    {order?.road_sheet?.brief ? order?.road_sheet?.brief : "NC"}
                    <br />
                  </small>
                  <hr />
                </Col>
                <Col
                  xs={6}
                  style={{
                    textAlign: "center",
                    borderRight: "1px solid #ddd",
                  }}
                >
                  <b>{order?.delivery?.person}</b> personnes
                </Col>
                <Col xs={6} style={{ textAlign: "center" }}>
                  <b>
                    {order?.road_sheet?.nb_waiters
                      ? order?.road_sheet?.nb_waiters
                      : "?"}
                  </b>{" "}
                  serveurs
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <h6 className="text-center my-3" id="Livraison">
            LIVRAISON
          </h6>
          <APIProvider apiKey={googleMapsApiKey}>
            <Map
              mapId="8f0b8aa9cd5906b"
              style={{ width: "100%", height: "200px" }}
              defaultCenter={{
                lat: rmDelivery?.delivery?.lat || 0,
                lng: rmDelivery?.delivery?.lng || 0,
              }}
              defaultZoom={15}
              scrollwheel={false}
            >
              <AdvancedMarker
                position={{
                  lat: rmDelivery?.delivery?.lat || 0,
                  lng: rmDelivery?.delivery?.lng || 0,
                }}
              />
            </Map>
          </APIProvider>
          <Card>
            <Card.Body>
              <Row noGutters>
                <Col xs={1}>
                  <FontAwesomeIcon icon={faMapPin} />
                </Col>
                <Col xs={9}>
                  {rmDelivery.delivery.street_number}{" "}
                  {rmDelivery.delivery.street}
                  <br />
                  {rmDelivery.delivery.postal_code}{" "}
                  {rmDelivery.delivery.city}
                  <br />
                  {rmDelivery.delivery.address_complement}
                </Col>
                <Col xs={2} className="text-right">
                  <Button
                    href={wazeUrl}
                    className="waze-button"
                    variant="outline-info"
                    target="_blank"
                  >
                    <img src={Waze} />
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mt-2">
            <Card.Body>
              <Row noGutters>
                <Col xs={1}>
                  <FontAwesomeIcon icon={faUser} />
                </Col>
                <Col xs={9}>
                  {rmDelivery.delivery.firstname}{" "}
                  {rmDelivery.delivery.lastname} /{" "}
                  <a href={`tel:${rmDelivery.delivery.phone}`}>
                    {rmDelivery.delivery.phone}
                  </a>
                  <br />
                  {order?.road_sheet?.additional_contact}
                </Col>
                <Col xs={2} className="text-right">
                  <Button
                    href={`tel:${rmDelivery.delivery.phone}`}
                    variant="outline-info"
                  >
                    <FontAwesomeIcon icon={faPhone} />
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {order?.delivery?.delivery_information && (
            <Card className="mt-2">
              <Card.Body>
                <Row noGutters>
                  <Col xs={1}>
                    <FontAwesomeIcon icon={faComment} />
                  </Col>
                  <Col xs={11}>
                    {order?.delivery?.delivery_information}
                    <br /> {order?.road_sheet?.additional_spot_info}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
          {order?.road_sheet?.parking_address && (
            <Card className="mt-2">
              <Card.Body>
                <Row noGutters>
                  <Col xs={1}>
                    <FontAwesomeIcon icon={faParking} />
                  </Col>
                  <Col xs={11}>{order?.road_sheet?.parking_address}</Col>
                </Row>
              </Card.Body>
            </Card>
          )}
          <Card className="mt-2">
            <Card.Body>
              <Row className="align-items-center" noGutters>
                <Col
                  xs={6}
                  style={{
                    textAlign: "center",
                    borderRight: "1px solid #ddd",
                  }}
                >
                  <small>
                    Arrivée du labo: <br />{" "}
                    <b>
                      {order?.road_sheet?.lab_arrival
                        ? order?.road_sheet?.lab_arrival
                        : "NC"}
                    </b>
                  </small>
                  <hr />
                </Col>
                <Col xs={6} style={{ textAlign: "center" }}>
                  <small>
                    Départ du labo: <br />{" "}
                    <b>
                      {order?.road_sheet?.lab_departure
                        ? order?.road_sheet?.lab_departure
                        : "NC"}
                    </b>
                  </small>
                  <hr />
                </Col>
                <Col
                  xs={6}
                  style={{
                    textAlign: "center",
                    borderRight: "1px solid #ddd",
                  }}
                >
                  <small>
                    Arrivée du Moulin entre :<br />
                    <b>
                      {order?.delivery?.arrived_at_start
                        ? moment(order?.delivery?.arrived_at_start).format(
                            "HH:mm"
                          )
                        : "nc"}{" "}
                      et{" "}
                      {order?.delivery?.arrived_at_end
                        ? moment(order?.delivery?.arrived_at_end).format(
                            "HH:mm"
                          )
                        : "NC"}
                    </b>
                  </small>
                </Col>
                <Col xs={6} style={{ textAlign: "center" }}>
                  <small>
                    Début événement :<br />{" "}
                    <b>
                      {order?.delivery?.start_at
                        ? moment(order?.delivery?.start_at).format("HH:mm")
                        : "NC"}
                    </b>
                  </small>
                </Col>
                <Col xs={12} style={{ textAlign: "center" }}>
                  <hr />
                  <small>
                    Fin de l'évènement / Récupération à partir de :<br />{" "}
                    <b>
                      {order?.delivery?.end_at
                        ? moment(order?.delivery?.end_at).format("HH:mm")
                        : "NC"}
                    </b>
                  </small>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <h6 className="text-center my-3" id="Installation">
            INSTALLATION
          </h6>
          <Card className="mt-2">
            <Card.Body>
              <Row className="align-items-center" noGutters>
                <Col xs={12}>
                  <b>OFFICE & MATERIEL</b>
                  <br />
                  <small>{order?.road_sheet?.equipment}</small>
                  <hr />
                </Col>
                <Col xs={12}>
                  {order?.road_sheet?.office ? (
                    <FontAwesomeIcon icon={faCheck} color="green" />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} color="red" />
                  )}{" "}
                  Office
                  <br />
                  {order?.road_sheet?.trash ? (
                    <FontAwesomeIcon icon={faCheck} color="green" />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} color="red" />
                  )}{" "}
                  Supports de poubelle
                  <br />
                  {order?.road_sheet?.basin ? (
                    <FontAwesomeIcon icon={faCheck} color="green" />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} color="red" />
                  )}{" "}
                  Vasque
                  <br />
                  {order?.road_sheet?.buffet_decoration ? (
                    <FontAwesomeIcon icon={faCheck} color="green" />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} color="red" />
                  )}{" "}
                  Déco de buffet
                  <br />
                  {order?.delivery?.blanket ? (
                    <FontAwesomeIcon icon={faCheck} color="green" />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} color="red" />
                  )}{" "}
                  Nappage intissé
                  <br />
                  {order?.road_sheet?.has_other_material ? (
                    <FontAwesomeIcon icon={faCheck} color="green" />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} color="red" />
                  )}{" "}
                  Autre...
                  <hr />
                </Col>
                <Col xs={12}>
                  <b style={{ textTransform: "uppercase" }}>
                    Matériel de location
                  </b>
                  <br />
                  <small>
                    {order?.road_sheet?.rental_equipment
                      ? order?.road_sheet?.rental_equipment
                      : "NC"}
                  </small>
                  <hr />
                </Col>
                <Col xs={12}>
                  <b style={{ textTransform: "uppercase" }}>
                    Matériel du moulin
                  </b>
                  <br />
                  <small>
                    {order?.road_sheet?.moulin_equipment
                      ? order?.road_sheet?.moulin_equipment
                      : "NC"}
                  </small>
                  <hr />
                </Col>
                <Col xs={12}>
                  <b style={{ textTransform: "uppercase" }}>
                    Disponible sur place
                  </b>
                  <br />
                  <small>
                    {order?.road_sheet?.on_site_equipment
                      ? order?.road_sheet?.on_site_equipment
                      : "NC"}
                  </small>
                  <hr />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mt-2">
            <Card.Body>
              <Row className="align-items-center" noGutters>
                <Col xs={12}>
                  <b style={{ textTransform: "uppercase" }}>Déroulé</b>
                  <br />
                  <small>
                    {order?.road_sheet?.execution_remarks
                      ? order?.road_sheet?.execution_remarks
                      : "NC"}
                  </small>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {order?.road_sheet?.other_remarks && (
            <Card className="mt-2">
              <Card.Body>
                <Row className="align-items-center" noGutters>
                  <Col xs={12}>
                    <b style={{ textTransform: "uppercase" }}>Autres</b>
                    <br />
                    <small>{order?.road_sheet?.other_remarks}</small>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
          <h6 className="text-center my-3" id="Production">
            PRODUCTION
          </h6>
          <Card className="mt-2">
            <Card.Body>
              <Row className="align-items-center" noGutters>
                <Col xs={12}>
                  <b>INFO PROD</b>
                  <br />
                  <small>
                    {order?.commentary_for_prod
                      ? order.commentary_for_prod
                      : "NC"}
                    <br />
                    {order?.road_sheet?.additional_prod_info}
                  </small>
                  <hr />
                </Col>
                <Col
                  xs={6}
                  style={{
                    textAlign: "center",
                    borderRight: "1px solid #ddd",
                  }}
                >
                  <small>
                    {order?.road_sheet?.hot_dishes ? (
                      <FontAwesomeIcon icon={faCheck} color="green" />
                    ) : (
                      <FontAwesomeIcon icon={faTimes} color="red" />
                    )}{" "}
                    Pièces chaudes
                  </small>
                </Col>
                <Col xs={6} style={{ textAlign: "center" }}>
                  <small>
                    Repas extra
                    <br />
                    <b>
                      {order?.road_sheet?.extra_meals
                        ? order.road_sheet.extra_meals
                        : "0"}
                    </b>
                  </small>
                </Col>
                <Col xs={12} style={{ textAlign: "center" }}>
                  <hr />
                  <big>
                    <b>{order?.delivery?.info?.numbering}</b>
                  </big>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <h6 className="text-center my-3" id="Photos">
            PHOTOS
          </h6>
          <Card className="mt-2">
            <Card.Body>
              <Row className="align-items-center" noGutters>
                {order?.delivery?.medias_with_titles &&
                  order.delivery.medias_with_titles.map(
                    (mediaWithTitle, index) => (
                      <Col xs={12} key={index} className="position-relative">
                        <p>
                          <b>{mediaWithTitle.title}</b>
                        </p>
                        <img
                          src={`${apiUrl}/${mediaWithTitle.media.media_uris.normal}`}
                          alt={mediaWithTitle.title}
                          style={{ maxWidth: "100%" }}
                        />
                        <hr />
                      </Col>
                    )
                  )}
              </Row>
            </Card.Body>
          </Card>
        </div>
      )}
    </Modal>
  </>
  );
};

export default RoadSheetModal;
