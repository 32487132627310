import React from 'react'
import { css } from 'emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import {Dropdown} from 'react-bootstrap'
import moment from 'moment'
const UrlQuotation = (props) => {

  const {
    url, 
    sheetRoad,
    old,
    info,
    prestation,
    date
  } = props

  const styles = {
    blockColor: {
      top: '0',
      right: '0',
      color: 'white',
      position: 'absolute',
      textAlign: 'center',
      backgroundColor: old ? 'black ': '#429853',
      '&:hover': {
        color: 'black'
      }
    },


  }

  const translatePrestation = (prestation) => {
    const translationMap = {
      mealtray: `C2/${info.order_id}`,
      caterer: `T/${info.order_id}`,
      breakfast: `P/${info.order_id}`
    };
    return translationMap[prestation] || prestation;
  };

  return (
    <div className={css(styles.blockColor)}>
      <Dropdown>
        <Dropdown.Toggle>
          <FontAwesomeIcon icon={faFileAlt} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item eventKey="0" href={url} target="_blank">Fiche production</Dropdown.Item>
          <Dropdown.Item eventKey="1" href={sheetRoad} target="_blank">Feuille de route</Dropdown.Item>
          <Dropdown.Divider />
          {info.order_type === 'v2' && 
            <Dropdown.Item target="_blank" href={`/?search=${translatePrestation(prestation)}&startDate=${moment(date).format("YYYY-MM-DD")}&endDate=${moment(date).format("YYYY-MM-DD")}`} eventKey="2">M/{info?.grouped_order_id} - {translatePrestation(prestation)}</Dropdown.Item>
          }
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )

}

export default UrlQuotation
