import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { setTender, setConfirmTender } from "state/orderEdition/actions";
import { resetTenderCatalog } from "state/tender/actions";

const TenderSelectComponent = () => {
  const dispatch = useDispatch();
  const tenders = useSelector((state) =>
    state.adminData.tenders ? state.adminData.tenders : []
  );
  const selectedTender = useSelector((state) => state.orderEdition.tender);
  const confirmTender = useSelector((state) => state.orderEdition.confirmTender);

  const [value, setValue] = useState(selectedTender || null);

  const handleTenderSelect = (selectedOption) => {
    setValue(selectedOption?.value || null);
    const tender = selectedOption ? selectedOption.value : null;
    dispatch(setTender(tender));
  };

  const handleConfirmTender = (event) => {
    dispatch(setConfirmTender(event.target.checked));
  };

  const handleClearSelection = () => {
    dispatch(setTender(null));
    dispatch(setConfirmTender(false));
    dispatch(resetTenderCatalog());
    setValue(null);
  };

  const options = tenders && tenders.length > 0 ? tenders.map((tender) => ({
    value: tender,
    label: tender.name,
  })) : [];

  return (
    <>
      <label>Sélection de l'AO {value && (
       <a href="#" onClick={(e) => { e.preventDefault(); handleClearSelection(); }}>- Retirer</a>
      )}</label>
      <Select
        value={value ? { value, label: value.name } : null}
        onChange={handleTenderSelect}
        options={options}
        placeholder="Recherche de l'AO"
        isClearable
      />
      <br />
      <label htmlFor="confirmTender">
      <input type="checkbox" id="confirmTender" name="confirmTender" required onChange={handleConfirmTender} checked={confirmTender} /> Je confirme mon AO
      </label>
    </>
  );
};

export default TenderSelectComponent;
