import React from 'react'
import { Link} from 'react-router-dom'
import { elastic as Menu } from 'react-burger-menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListAlt, faArrowCircleLeft, faCalendar, faExclamationTriangle, faTruck } from '@fortawesome/free-solid-svg-icons'

const url = `${process.env.REACT_APP_API_URL}/e28XmZh3/`;

const MobileMenu = (props) => {

    return (
      <Menu id={"elastic"} {...props} disableAutoFocus={true} right>
        <Link className="menu-item" to={{
            pathname: `/companion`,
          }}><FontAwesomeIcon icon={faTruck} /> <span>Companion livreur</span></Link>
        <Link id="order-manage" className="menu-item" to={{
            pathname: `/`,
          }}><FontAwesomeIcon icon={faListAlt} /> <span>Gestion commandes</span></Link>
        <Link id="missing-quotation-information" className="menu-item" to={{
            pathname: `/informations-devis-manquantes`,
          }}><FontAwesomeIcon icon={faExclamationTriangle} /> <span>Infos devis manquantes</span></Link>
        <Link id="planning" className="menu-item" to={{
            pathname: `/planning`,
            search: window.location.search
          }}><FontAwesomeIcon icon={faListAlt} /> <span>Planning</span></Link>
        <Link id="calendar" className="menu-item" to={{
            pathname: `/agenda`
          }}><FontAwesomeIcon icon={faCalendar} /> <span>Agenda</span></Link>
        <a id="backBo" className="menu-item" href={url}><FontAwesomeIcon icon={faArrowCircleLeft} /> <span>Retour au BO</span></a>
      </Menu>
    )
}

export default MobileMenu
