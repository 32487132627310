import { injectGlobal } from 'emotion'

const Global = (props) => {
}

injectGlobal`
  body{
    background-color: #ecf5f4;
  }
  a{
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 26px;
    height: 20px;
    right: 25px;
    top: 15px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: white;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: black;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: white;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #429853;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #429853;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  .bm-item-list a {
    padding: 0.8em 0em;
  }

  #elastic span {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.75em;
  }

  .bm-item-list a span {
    margin-left: 10px;
    font-weight: 700;
  }
  .bm-menu a {
    color: white;
    &:hover{
      text-decoration:none;
      opacity: 0.7;
    }
  }

  .fancy label {
    display: inline-flex;
    align-items: baseline;
    font-size:0.8rem;
    line-height: 1;
    margin-bottom: 0;
    margin-left: 0.5rem;
  }
  .fancy p{
    margin-bottom: 0;
  }
  .fancy label input[type=checkbox],
  .fancy label input[type=radio] {
    position: relative;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    font-size: inherit;
    width: 1em;
    margin: 0;
    color: inherit;
    outline: none;
    font-family: 'Font Awesome 5 Pro';
    transition: 300ms ease-out;
  }
  .fancy label input[type=checkbox]::after,
  .fancy label input[type=radio]::after {
    content: '';
    border: 1px solid #ecebeb;
    background-color: #f8f8f6;
    display: inline-block;
    text-align: center;
    width: 1rem;
    height: 1rem;
  }
  .fancy label input[type=checkbox]:checked::after,
  .fancy label input[type=radio]:checked::after {
    font-weight: 900;
  }
  .fancy label input[type=checkbox]:active,
  .fancy label input[type=radio]:active {
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }
  .fancy label input[type=checkbox] + span,
  .fancy label input[type=radio] + span {
    margin-left: 0.8rem;
  }
  .fancy label input[type=checkbox]:checked::after {
    background-color: green;
    border: 1px solid #ecebeb;
    -webkit-print-color-adjust: exact;

  }
  .fancy label input[type=radio]:checked::after {
    background-color: green;
    border: 1px solid #ecebeb;
    -webkit-print-color-adjust: exact;

  }

  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  .fade-leave {
    opacity: 1;
  }

  .fade-leave.fade-leave-active {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
  }

  #react-autowhatever-1 {
    ul{
      background-color: red;
      position: relative;
      border-radius: 4px;
      display: block;
      position: absolute;
      margin-top: 5px;
      width: 280px;
      background-color: #fff;
      font-family: Helvetica, sans-serif;
      font-weight: 300;
      font-size: 16px;
      z-index: 2;
      border: #ccc 1px solid;
      list-style-type: none;
      cursor: pointer;
      padding: 10px 20px;
      li{
        padding: 5px 10px;
      }
    }
  }

  //Filter & input time
  @media only screen and (max-width: 767px){
    .react-datepicker__input-container{
      width: 100%;
      margin-bottom: 10px;
    }
    .rc-time-picker{
      display: block;
      margin-bottom: 5px;
    }
  }

  @media print {
    .no-print{
      display:none;
    }
    .exact-print{
      -webkit-print-color-adjust: exact;
    }
    tr {
      -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
      color-adjust: exact !important; /* Firefox */
    }
    .print-fw{
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .break-page{
      page-break-after: always
    }
  }
  .rbc-month-view{
    height: 500px !important;
  }
  .rbc-month-row {
    overflow: inherit !important;
    height: auto !important;
  }
  .rbc-event{
    height: auto !important;
  }
  .rbc-row {
    display: flex;
    flex-direction: row;
  }
  .rc-time-picker-input{
    background-color: #f8f8f6;
  }
  .form-control{
    background-color: #f8f8f6;
  }
  .page-item.active .page-link{
    background-color: #439b5c;
    border-color: #439b5c;
  }
  .page-link{
    color: #439b5c;
  }
  .tippy-box {
    position: relative;
    background-color: #333;
    padding: 0.8rem;
    color: #fff;
    font-size: 0.8rem;
    border-radius: 4px;
    line-height: 1.4;
    outline: 0;
    transition-property: transform,visibility,opacity;
  }
  .dropdown-item{
    font-size: 0.8rem;
  }

  #multiorder{
    position: absolute;
    inset: 52px 0px 0px;
    overflow: hidden;
    @media only screen and (max-width: 800px){
      position: relative;
      inset: 0px 0px 0px;
    }
    #customer{
      padding: 15px;
      z-index: 2;
      position: absolute;
      top: 0px;
      bottom: 0px;
      transition: -webkit-transform 0.3s ease-out 0s;
      will-change: transform;
      overflow-y: auto;
      width: 300px;
      background-color: white;
      left: 0px;
      transform: translateX(0%);
      @media only screen and (max-width: 1200px){
        width: 250px;
      }
      @media only screen and (max-width: 800px){
        position: relative;
        width: 100%;
        border: 1px solid lightgray;
        border-radius: 10px;
      }
    }
    #main{
      padding: 15px;
      position: absolute;
      inset: 0px 350px 0px 300px;
      overflow-y: auto;
      transition: left 0.3s ease-out 0s, right 0.3s ease-out 0s;
      text-ylign: center;
      @media only screen and (max-width: 1200px){
        inset: 0px 300px 0px 250px;
      }
      @media only screen and (max-width: 800px){
        position: relative;
        width: 100%;
        inset: 0px 0px 0px 0px;
        padding: 0px;
      }
    }
    #cart{
      padding: 15px;
      z-index: 2;
      position: absolute;
      top: 0px;
      bottom: 0px;
      transition: -webkit-transform 0.3s ease-out 0s;
      will-change: transform;
      overflow-y: auto;
      width: 350px;
      background-color: white;
      right: 0px;
      transform: translateX(0%);
      @media only screen and (max-width: 1200px){
        width: 300px;
      }
      @media only screen and (max-width: 800px){
        position: relative;
        width: 100%;
        border: 1px solid lightgray;
        border-radius: 10px;
      }
    }
  }
  .horizontal-scroll-container {
    display: flex;
    overflow-x: auto;
  }

  .horizontal-scroll-item {
    flex: 0 0 auto;
    margin-right: 10px; /* Espacement entre les éléments */
  }

  .customDatePickerWidth, .customDatePickerWidth .react-datepicker-wrapper, .customDatePickerWidth .react-datepicker__input-container, .customDatePickerWidth .react-datepicker__input-container input {
    width: 100%;
    display: block !important;
  }
  .react-datepicker-popper {
    z-index: 2;
  }
  #connection-tabs-tab-admin{
    color: black;
  }
  .waze-button{
    background-color: #5bc5f2;
    border-color: #5bc5f2;
    img{
      max-width: 30px;
    }
  }
  .only-print{
    display: none;
  }
  @media print {
    .only-print{
      display: block;
    }
    .only-web{
      display: none;
    }
  }
  .sticky-button{
    position: fixed;
    bottom: 0;
    right: 10px;
    left: 10px;
  }
  .card-body{
    @media only screen and (max-width: 720px){
     padding: 0.8rem;
    }
  }
`

export default Global
