import React, { useEffect, useState } from "react";
import Layout from "app/companion/components/layout/Layout";
import { Container, Badge, Card, Button, Col, Row, Alert } from "react-bootstrap";
import Menu from "app/companion/pages/delivery/Menu";
import DeliveryTitle from "app/companion/pages/delivery/DeliveryTitle";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faComment, faTrash } from "@fortawesome/free-solid-svg-icons";
import AddComment from "app/companion/pages/delivery/comment/AddComment";
import moment from "moment";
import useCompanion from "app/companion/useCompanion";

const DeliveryComment = () => {
  const {
    isLoading,
    filteredRoadmap,
    filteredDelivery,
    addComment,
    deleteComment,
  } = useCompanion();
  const [comments, setComments] = useState([]);
  const [deliveryInformation, setDeliveryInformation] = useState("NC");
  const [recoverInformation, setRecoverInformation] = useState("NC");
  const [error, setError] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (filteredDelivery) {
      setComments(filteredDelivery?.delivery?.comments || []);
      setDeliveryInformation(
        filteredDelivery?.delivery?.delivery_information || "NC"
      );
      setRecoverInformation(
        filteredDelivery?.delivery?.recover_information || "NC"
      );
    }
  }, [filteredDelivery]);

  const [isOpenModalComment, setIsOpenModalComment] = useState(false);
  const handleClose = () => {
    setIsOpenModalComment(false);
  };

  const handleDeleteComment = async (deliveryId, commentId) => {
    await deleteComment(deliveryId, commentId).then((resp) => {
      if (
        (resp?.response?.data?.success !== undefined &&
          resp.response.data.success === false) ||
        (resp.success !== undefined && resp.success === false)
      ) {
        let errorMessage =
          "Une erreur s'est produite lors de l'ajout du commentaire.";
        if (resp?.response?.data?.message) {
          errorMessage = resp.response.data.message;
        } else if (resp?.message) {
          errorMessage = resp.message;
        }
        setError(errorMessage);
      } else {
        setError(null);
      }
    });
  };

  return (
    filteredDelivery &&
    filteredRoadmap && (
      <Layout backButton>
        <>
          <Container fluid={true}>
            <>
              <DeliveryTitle />
              <h6 className="text-center my-3">COMMENTAIRE INTERNE</h6>
              <Card className="mt-2">
                <Card.Body>
                  <Row noGutters>
                    <Col xs={1}>
                      <FontAwesomeIcon icon={faComment} />
                    </Col>
                    <Col xs={11}>LIVRAISON : {deliveryInformation}</Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card className="mt-2">
                <Card.Body>
                  <Row noGutters>
                    <Col xs={1}>
                      <FontAwesomeIcon icon={faComment} />
                    </Col>
                    <Col xs={11}>RÉCUPÉRATION :{recoverInformation}</Col>
                  </Row>
                </Card.Body>
              </Card>
              {error && <Alert variant="danger">{error}</Alert>}
              {isLoading ? (
                <p>Chargement...</p>
              ) : (
                <>
                  <h6 className="text-center my-3">COMMENTAIRE LIVREUR</h6>
                  {comments.length === 0 && (
                    <Card>
                      <Card.Body>
                        Aucun commentaire livreur, vous pouvez en écrire en
                        appuyant sur "+" en bas à droite
                      </Card.Body>
                    </Card>
                  )}
                  {comments.map((comment, commentIndex) => (
                    <div key={commentIndex}>
                      <p className="my-2">
                        <i>
                          <small>
                            {comment?.author ? comment.author : "Anonyme"} le{" "}
                            {comment?.created_at
                              ? moment(comment.created_at).format(
                                  "DD/MM/YYYY à HH:mm"
                                )
                              : "date inconnue"}{" "}
                            :
                          </small>
                        </i>
                      </p>
                      <Card>
                        <Card.Body>
                          {comment?.tags?.map((tag, tagIndex) => (
                            <Badge pill variant="primary" key={tagIndex}>
                              {tag}
                            </Badge>
                          ))}
                          <span
                            onClick={() =>
                              handleDeleteComment(
                                filteredDelivery.delivery.id,
                                comment.id
                              )
                            }
                            style={{
                              cursor: "pointer",
                              backgroundColor: "red",
                              position: "absolute",
                              top: "0px",
                              right: "0px",
                              color: "white",
                              padding: "5px",
                              marginLeft: "10px",
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </span>
                          <p style={{ marginBottom: "0px" }}>
                            <small>{comment.comment}</small>
                          </p>
                          {comment.medias.map(
                            (media, fileIndex) =>
                              media?.media_uris?.normal && (
                                <img
                                  src={`${apiUrl}/${media.media_uris.normal}`}
                                  alt=""
                                  key={fileIndex}
                                  style={{
                                    maxWidth: "100%",
                                    marginBottom: "10px",
                                    marginRight: "10px",
                                    width: "50%",
                                  }}
                                />
                              )
                          )}
                        </Card.Body>
                      </Card>
                    </div>
                  ))}

                  {/* Ajout de commentaire */}
                  <FixButton>
                    <Button
                      variant="dark"
                      className="mt-3"
                      size="lg"
                      onClick={() => setIsOpenModalComment(true)}
                    >
                      <FontAwesomeIcon icon={faPlus} />{" "}
                    </Button>
                  </FixButton>
                  <AddComment
                    isOpen={isOpenModalComment}
                    handleClose={handleClose}
                    deliveryId={filteredDelivery.delivery.id}
                    isCompanionLoading={isLoading}
                    addComment={addComment}
                  />
                </>
              )}
            </>
          </Container>
          <Menu
            roadmap={filteredRoadmap && filteredRoadmap}
            delivery={filteredDelivery && filteredDelivery}
            comments={comments.length}
          />
        </>
      </Layout>
    )
  );
};

const Title = styled.div`
  text-align: center;
  border-radius: 4px;
  background-color: ${(props) => (props.color ? props.color : "white")};
  width: 100%;
  border: thin solid ${(props) => (props.color ? props.color : "white")};
  color: white;
  padding: 0.5rem 0.5rem;
  white-space: nowrap;
  margin: 1rem 0;
`;

const FixButton = styled.div`
  position: fixed;
  bottom: 85px;
  right: 20px;
  margin: auto;
  text-align: center;
`;

export default DeliveryComment;
