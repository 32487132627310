import React, { createContext, useContext, useState, useEffect } from 'react';
import LeMoulinApi from 'app/universal/utils/LeMoulinApi';
import { useDispatch, useSelector } from 'react-redux';
import { getConnectedUserInfo } from 'state/common/actions';
import Login from 'app/login/pages/Index';
import queryString from 'query-string';
import FakeDelivery from "app/deliveryTool/components/delivery/FakeDelivery";
import swal from 'sweetalert';

const ApiAuthContext = createContext(null);

const ApiAuthProvider = (props) => {
  const dispatch = useDispatch();
  const [accessToken, setAccessToken] = useState(() => window.localStorage.getItem('token') || '');
  const [refreshToken, setRefreshToken] = useState(() => window.localStorage.getItem('refresh_token') || '');
  const [isInitialized, setIsInitialized] = useState(false);

  const connectedUser = useSelector((state) => state.common.user.data);
  const [isLoading, setIsLoading] = useState(false);

  // On regarde si on detecte le param public dans l'url pour le token et le refresh
  useEffect(() => {
    // Vérifier si un token est passé dans l'URL et mettre à jour localStorage
    const paramUrl = queryString.parse(window.location.search);
    let token = paramUrl.token;
    let refreshToken = paramUrl.refresh_token;
    if (token) {
      const tokenStorage = window.localStorage.getItem('token');
      const refreshTokenStorage = window.localStorage.getItem('refresh_token');
      if (tokenStorage !== token) {
        window.localStorage.setItem('token', token);
      }
      if (refreshTokenStorage !== refreshToken) {
        window.localStorage.setItem('refresh_token', refreshToken);
      }
      // Supprime le token dans l'url et recharge la page
      window.history.pushState({}, document.title, window.location.pathname);
      window.location.reload();
    }
  }, [window.location.search]);

  useEffect(() => {
    // Si on a un token et que l'utilisateur n'est pas encore chargé, on le récupère
    if (accessToken && !connectedUser?.id && !isLoading) {
      dispatch(getConnectedUserInfo()).finally(() => {
        setIsInitialized(true);
      });
    } else if (!accessToken) {
      setIsInitialized(true);
    }
  }, [accessToken, connectedUser, isLoading, dispatch]);

  // Si c'est un utilisateur ROLE_PRO_COMPANION_USER, regarde si dans l'url /companion est présent, si non, redirige vers /companion sinon, on reste sur la page
  useEffect(() => {
    if (connectedUser?.roles && connectedUser?.roles.includes('ROLE_PRO_COMPANION_USER') && !window.location.pathname.includes('/companion')) {
      window.location.href = '/companion';
    }
  }, [connectedUser]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const { token, refresh_token } = await LeMoulinApi.getToken(event.target.username.value, event.target.password.value);
      window.localStorage.setItem('token', token);
      window.localStorage.setItem('refresh_token', refresh_token);
      setAccessToken(token);
      setRefreshToken(refresh_token);
    } catch (error) {
      const errorMsg = error?.response?.data?.message || 'Une erreur est survenue';
      swal({
        title: "Oups",
        text: errorMsg,
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
    
  };

  const isPublicUrl = window.location.pathname === "/impression-planning-public";

  if (isPublicUrl) {
    return <>{props.children}</>;
  }

  if (!isInitialized) {
    return <div className="p-4"><FakeDelivery /></div>;  // Afficher un loader pendant l'initialisation
  }

  return (
    accessToken === ''
      ? <Login handleSubmit={handleSubmit} isLoading={isLoading} />
      : <ApiAuthContext.Provider value={{ accessToken, connectedUser }}>
          {props.children}
        </ApiAuthContext.Provider>
  );
};

export default ApiAuthProvider;
export const useToken = () => useContext(ApiAuthContext);
