import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import moment from 'moment';

const HeaderBlock = ({ date, company, customer, prestationType }) => (
  <Row
    style={{
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      border: "1px solid lightgray",
      borderRadius: "10px",
      margin: "10px 0",
    }}
  >
    <Col>
     
      <Form.Group controlId="formDate">
        <Form.Label>Date</Form.Label>
        <br/><b>{moment(date).format("DD/MM/YYYY")}</b>
      </Form.Group>
    </Col>
    <Col>
      <Form.Group controlId="formCompany">
        <Form.Label>Entreprise</Form.Label>
        <br/><b>{company}</b>
      </Form.Group>
    </Col>
    <Col>
      <Form.Group controlId="formCustomer">
        <Form.Label>Client</Form.Label>
        <br/><b>{customer}</b>
      </Form.Group>
    </Col>
    <Col>
      <Form.Group controlId="prestationType">
        <Form.Label>Type de prestation</Form.Label>
        <br/><b>{prestationType}</b>
      </Form.Group>
    </Col>
  </Row>
);

export default HeaderBlock;
