import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { animateScroll as scroller } from "react-scroll";
import Direction from "app/universal/map/Direction";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
  Pin,
} from "@vis.gl/react-google-maps";

const DirectionsMap = (props) => {
  const {
    forPrint,
    roadmaps,
    roadmap,
    deliveries,
    recoveries,
    filters,
    isLoading,
    defaultZoom,
    onMarkerClick,
    handleRouteData,
  } = props;

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const options = {
    streetViewControl: false, // Désactiver le contrôle Street View
    mapTypeControl: false,
    fullscreenControl: false,
    gestureHandling: "cooperative",
  };

  return (
    <APIProvider apiKey={googleMapsApiKey}>
      <Map
        mapId="8f0b8aa9cd5906b"
        defaultCenter={{ lat: 45.7613911, lng: 4.9167728 }}
        defaultZoom={defaultZoom}
        scrollwheel={false}
        gestureHandling={"cooperative"}
        style={forPrint ? { width: "100%", height: "200px" } : {}}
        options={options}
      >
        {recoveries.map((e, i) => (
          <MarkerWithInfoWindow
            delivery={e}
            onMarkerClick={onMarkerClick}
            key={e.id}
          />
        ))}

        {deliveries.map((e, i) => (
          <MarkerWithInfoWindow
            delivery={e}
            onMarkerClick={onMarkerClick}
            key={e.id}
          />
        ))}
        {roadmaps.length > 0 ||
          (roadmap &&
            roadmaps.map((roadmap, index) =>
              parseInt(filters.showRoadmap) === parseInt(roadmap.id) ? (
                <Direction
                  deliveries={roadmap.deliveries}
                  color={
                    roadmap.delivery_man ? roadmap.delivery_man.color : "green"
                  }
                  handleResult={handleRouteData}
                  key={roadmap.id}
                  isLoading={isLoading}
                />
              ) : null
            ))}
        {roadmap && (
          <Direction
            deliveries={roadmap.deliveries}
            color={roadmap.delivery_man ? roadmap.delivery_man.color : "green"}
            handleResult={handleRouteData}
            key={roadmap.id}
            isLoading={isLoading}
          />
        )}
      </Map>
    </APIProvider>
  );
};

DirectionsMap.propTypes = {
  forPrint: PropTypes.bool,
  roadmaps: PropTypes.array,
  roadmap: PropTypes.object,
  recoveries: PropTypes.array,
  deliveries: PropTypes.array,
  isLoading: PropTypes.bool,
  defaultZoom: PropTypes.number,
  onMarkerClick: PropTypes.func,
  handleRouteData: PropTypes.func,
};

DirectionsMap.defaultProps = {
  forPrint: false,
  roadmaps: [],
  recoveries: [],
  roadmap: null,
  deliveries: [],
  isLoading: false,
  defaultZoom: 11,
  onMarkerClick: () => {},
  handleRouteData: () => {},
};

const MarkerWithInfoWindow = ({ delivery, onMarkerClick }) => {
  // `markerRef` and `marker` are needed to establish the connection between
  // the marker and infowindow (if you're using the Marker component, you
  // can use the `useMarkerRef` hook instead).
  const [markerRef, marker] = useAdvancedMarkerRef();

  const [infoWindowShown, setInfoWindowShown] = useState(false);

  // clicking the marker will toggle the infowindow
  const handleMarkerClick = useCallback(
    () => setInfoWindowShown((isShown) => !isShown),
    []
  );

  // // if the maps api closes the infowindow, we have to synchronize our state
  const handleClose = useCallback(() => setInfoWindowShown(false), []);

  const deliveryOptions = {
    draggable: false,
    icon: {
      path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
      fillColor: "black",
      fillOpacity: 0.8,
      scale: 0.2,
      strokeColor: "black",
      strokeWeight: 1,
    },
  };

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        position={{ lat: delivery.lat, lng: delivery.lng }}
        onClick={(e) => {
          onMarkerClick(delivery.id);
          handleMarkerClick(e);
          scroller.scrollTo("delivery-" + delivery.id, {
            duration: 800,
            containerId: "listing",
            delay: 0,
            smooth: "easeInOutQuart",
          });
        }}
        options={deliveryOptions}
      >
        <Pin
          background={"#0f9d58"}
          borderColor={"#006425"}
          glyphColor={"#60d98f"}
        />

        {infoWindowShown && (
          <InfoWindow anchor={marker} onClose={handleClose}>
            <b>{delivery.company}</b>
            <br />
            <p>
              {delivery.street_number} {delivery.street}, {delivery.postal_code}{" "}
              {delivery.city}
            </p>
          </InfoWindow>
        )}
      </AdvancedMarker>
    </>
  );
};

export default DirectionsMap;
