import React from "react";
import { useTable } from "react-table";
import './styles.css';

const AnticipationGalette = (props) => {
  // props
  const { ordersInfo, tags } = props;

  // Les colonnes qui doivent avoir leur ligne droite en gras
  const boldVerticalSeparatorColumns = ["Presta", "Galette"];

  // Trie les tags par sort sachant qu'un sort inexistant ou null est dernier
  tags.sort((a, b) => {
    if (a.sort === null) return 1;
    if (b.sort === null) return -1;
    return a.sort - b.sort;
  });

  let orders = Object.values(ordersInfo)
  .flatMap((info) =>
    Object.values(info.orders)
      .filter((order) => order.orderType === "breakfast")
      .map((order) => ({
        ...order,
      }))
  )
  .flat();

  orders.forEach((order) => {
    order.products.forEach((product) => {
      if (tags.some((tag) => tag.name === product.tagName)) {
        if (!order[product.tagName]) order[product.tagName] = [];
        if (order.isZeroWaste) { // Pas de html pour le zero waste
          order[product.tagName].push(
            `${product.productName} - ${product.quantity} ${
              product.unit ? ` ${product.unit}` : ""
            }`
          );
        }
        else {
          order[product.tagName].push(
            `<span>${product.productName}</span> - ${product.quantity} ${
              product.unit ? ` ${product.unit}` : ""
            }`
          );
        }
      }
    });

    tags.forEach((tag) => {
      if (order[tag.name]) order[tag.name] = order[tag.name].join(" || ");
    });
  });

  // Commandes total
  let totals = ["Total"].map((total) => {
    let products = [];
    orders.forEach((order) => {
      products = products.concat(order.products);
    });
  
    return {
      roadmapStartHour: total,
      orderNumber: "",
      ...tags.reduce((acc, tag) => {
        const groupedProducts = products
          .filter((product) => product.tagName === tag.name)
          .reduce((grouped, product) => {
            const key = `${product.productName} - ${
              product.unit ? ` ${product.unit}` : ""
            }`;
            if (!grouped[key]) {
              grouped[key] = {
                quantity: 0,
                unit: product.unit,
                productName: product.productName,
              };
            }
            grouped[key].quantity += product.quantity;
            return grouped;
          }, {});
  
        acc[tag.name] = Object.values(groupedProducts)
          .map(
            (product) =>
              `${product.productName} - ${product.quantity} ${
                product.unit ? ` ${product.unit}` : ""
              }`
          )
          .join(" || ");
        return acc;
      }, {})
    };
  });

  // Ajoutez les totaux aux commandes
  orders = orders.concat(totals);

  const columns = React.useMemo(
    () => [
      {
        Header: "H départ",
        accessor: "roadmapStartHour", // Assurez-vous que cette valeur correspond à une clé dans vos données
      },
      {
        Header: "Presta",
        accessor: "orderNumber",
        Cell: ({ cell: { value }, column: { id } }) => {
          var cellValue = `<span style="font-weight:bold; font-size:1.1em; color: blue;">${value}</span>`;
          return <div dangerouslySetInnerHTML={{ __html: cellValue }} />;
        },
      },
      ...tags.map((tag) => ({
        Header: tag.name,
        accessor: (row) => {
          // Vérifiez si le total pour ce tagName contient "||"
          const totalForTagName = totals.find(total => total.roadmapStartHour === 'Total')[tag.name] || '';
          const hasMultipleProducts = totalForTagName.includes(' || ');

          if (row.tagName === tag.name) {
            const productString = `${row.productName} - ${row.quantity} ${row.unit}`;
            const productCount = (row[tag.name] || '').split(' || ').length;
            return productCount === 1 ? `${row.quantity} ${row.unit}` : productString;
          } else if (row[tag.name]) {
            const productCount = row[tag.name].split(' || ').length;
            if (productCount === 1) {
              const lastDashIndex = row[tag.name].lastIndexOf(' - ');
              const result = lastDashIndex !== -1 ? row[tag.name].substring(lastDashIndex + 3) : row[tag.name];
              return hasMultipleProducts ? row[tag.name] : result;
            } else {
              return row[tag.name];
            }
          }
          return "";
        },
        Cell: ({ cell: { value }, column: { id } }) => {
          return <div dangerouslySetInnerHTML={{ __html: value }} />;
        },
      })),
    ],
    [tags]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: orders });

  return (
    <table
      {...getTableProps()}
      style={{ border: "solid 1px black", width: "100%" }}
    >
      <thead>
        <tr>
          <th colSpan={headerGroups[0].headers.length} style={{ border: "solid 1px black", padding: "10px", textAlign: "center" }}>
            Tableau des Galettes
            {/* Légende des couleurs */}
            <div className="text-right"></div>
              <div style={{ display: "flex", justifyContent: "right", fontSize: "12px" }}>
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <div
                    style={{
                      backgroundColor: "green",
                      width: "10px",
                      height: "10px",
                      margin: "5px",
                      padding: "5px",
                    }}
                  ></div>
                  <div>0 déchet + service</div>
                  <div
                    style={{
                      backgroundColor: "orange",
                      width: "10px",
                      height: "10px",
                      margin: "5px",
                      padding: "5px",
                    }}
                  ></div>
                  <div>0 déchet</div>
                  <div
                    style={{
                      backgroundColor: "violet",
                      width: "10px",
                      height: "10px",
                      margin: "5px",
                      padding: "5px",
                    }}
                  ></div>
                  <div>Avec service</div>
                </div>
              </div>
          </th>
        </tr>
        {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  {...column.getHeaderProps()}
                  className={boldVerticalSeparatorColumns.includes(column.Header) ? 'border-right-bold' : ''}
                  style={{ border: "solid 1px black", padding: "10px", width: i === 0 ? '10%' : undefined }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              style={
                row.original.roadmapStartHour === "Total"
                  ? { fontWeight: "bold", backgroundColor: "#f2f2f2"}
                  : row.original.isZeroWaste && row.original.withService
                  ? { backgroundColor: "green" }
                  : row.original.isZeroWaste
                  ? { backgroundColor: "orange" }
                  : row.original.withService
                  ? { backgroundColor: "violet" }
                  : {}
              }
            >
              {row.cells.map((cell, i) => (
                <td
                  {...cell.getCellProps()}
                  className={boldVerticalSeparatorColumns.includes(cell.column.Header) ? 'border-right-bold' : ''}
                  style={{ border: "solid 1px black", padding: "10px", width: i === 0 ? '10%' : undefined }}
                >
                  {cell.render("Cell")}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default AnticipationGalette;
