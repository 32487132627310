const styles = {
    login: {
      minHeight: 'calc(100vh)',
      overflow: 'hidden',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      background: '#689D71',
      width: '100%',
      height: '100%',
      padding: '0 5% 0 5%',
      position: 'relative',
      zIndex: '1000',
      display: 'flex',
      alignItems: 'center',
      WebkitAlignItems: 'center',
      MsAlignItems: 'center',
    },
    block: {
      padding: '30px',
      maxWidth: '400px',
      margin: 'auto',
    },
    sub: {
      fontSize: '11px',
      fontWeight: '400',
      color: '#767676',
    },
    logoImg: {
      textAlign: 'center',
      marginBottom: '20px',
    },
    img: {
      width: '120px',
    },
    label: {
      marginBottom: '5px',
      fontWeight: '700',
      fontSize: '14px',
    },
    button: {
      width: '100%',
      backgroundColor: '#000000',
      color: 'white',
      textAlign: 'center',
      border: 'none',
      fontSize: '18px',
      padding: '10px 5px',
      borderRadius: '4px',
      '&:disabled': {
        opacity: '0.5',
        cursor: 'not-allowed !important',
      },
    },
    input: {
      borderRadius: '4px',
      border: '1px solid rgba(34,36,38,0.15)',
      boxShadow: '0 0 0 0 transparent inset',
      transition: 'color .1s ease,border-color .1s ease',
      outline: '0',
      width:'100%',
      height: '34px',
      padding: '6px 12px',
      fontSize: '14px',
      lineHeight: '1.42857143',
    },
    resend: {
      color: 'black',
      textDecoration: 'underline',
      textAlign: 'center',
      marginTop: '20px'
    }
  }

export default styles;
