import {
  FETCH_ORDER,
  FETCH_ORDER_COMPLETED,
  FETCH_ORDER_FAILED,
  UPDATE_ROAD_SHEET,
  UPDATE_ROAD_SHEET_COMPLETED,
  UPDATE_ROAD_SHEET_FAILED,
} from "./actions";

const initialState = {
  data: {
    order: {},
  },
  isLoading: false,
  isInitialized: false,
  hasFailed: false,
  filters: {},
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDER:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_ORDER_COMPLETED:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          order: action.payload.result,
        },
      };
    case FETCH_ORDER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_ROAD_SHEET:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_ROAD_SHEET_COMPLETED:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          order: action.payload.result,
        },
      };
    case UPDATE_ROAD_SHEET_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducers;
