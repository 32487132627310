import React, { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";

const AddMaterial = (props) => {
  const { isOpen, handleClose, materials, handleAddMaterial } = props;
  const [quantity, setQuantity] = useState(0);
  const [selectedMaterial, setSelectedMaterial] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    handleAddMaterial(selectedMaterial, quantity);
    handleClose();
  };
  const uniqueMaterials = materials.filter((material, index, self) =>
    index === self.findIndex((m) => m.name === material.name)
  );
  return (
    <Modal show={isOpen} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Ajout de matériel</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formQuantity">
            <Form.Label>Quantité</Form.Label>
            <Form.Control
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formMaterial">
            <Form.Label>Matériel</Form.Label>
            <Form.Control
              as="select"
              value={selectedMaterial}
              onChange={(e) => setSelectedMaterial(e.target.value)}
            >
              <option value="">Sélectionnez un matériel</option>
              {uniqueMaterials.sort((a, b) => a.name.localeCompare(b.name)).filter((orderMaterial) => orderMaterial.is_to_collect).map((material) => (
                <option key={material.id} value={material.id}>
                  {material.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Button variant="primary" type="submit">
            Ajouter ce matériel
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddMaterial;